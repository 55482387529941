import { useEffect, useRef } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useHook = <F extends (...args: any[]) => any>(fn: F, ...params: Parameters<F>) => {
  const value = fn?.(...params) as ReturnType<F>

  const resolvedValueRef = useRef<ReturnType<F>>(value)

  useEffect(() => {
    resolvedValueRef.current = value
  }, [value])

  return [resolvedValueRef, value] as const
}

export default useHook
