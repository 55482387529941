import { useInfiniteQuery, useQuery } from 'react-query'

import { isMainnet } from '@/constants/index'
import { useNemoProgram } from '@/providers/nemo-program'
import { APITokenInfo, fetchAllTokens, fetchCoreTokens, fetchPoolRelatedTokens, fetchTopCoins } from '@/utils/apis/helper'
import {
  fetchLiquidityPoolByTokenPair,
  fetchLiquidityPools,
  fetchMyLiquidityPositions,
  searchLiquidityPools,
} from '@/utils/apis/liquidity'

import { RENEC_MINT_ADDRESS, RENGN_MINT_ADDRESS, REUSD_MINT_ADDRESS, REVND_MINT_ADDRESS } from './use-tokens'

export const useLiquidityPools = (page?: number) =>
  useQuery({
    queryKey: ['fetchLiquidityPools', page],
    queryFn: async () => fetchLiquidityPools(page),
    refetchOnWindowFocus: false,
  })

export const useLiquidityPoolsInfinite = (
  keyword: string,
  status?: 'all' | 'approved' | 'pending',
) =>
  useInfiniteQuery(
    ['fetchLiquidityPoolsInfinite', keyword, keyword.length > 0 ? 'all' : status],
    async ({ pageParam = 1 }) => {
      if (keyword.length > 0) {
        return searchLiquidityPools(keyword, status, pageParam)
      } else {
        return fetchLiquidityPools(pageParam)
      }
    },
    {
      getNextPageParam: (lastPage, pages) => lastPage.has_next_page ? pages.length + 1 : pages.length,
      refetchOnWindowFocus: false,
    },
  )

export const useLiquidityPoolByTokenPair = (
  token_a_mint_address: string,
  token_b_mint_address: string,
) => useQuery({
  queryKey: ['fetchLiquidityPoolByTokenPair', token_a_mint_address, token_b_mint_address],
  queryFn: () => fetchLiquidityPoolByTokenPair(token_a_mint_address, token_b_mint_address),
  refetchOnWindowFocus: false,
  enabled: !!token_a_mint_address && !!token_b_mint_address,
})

export const useMyLiquidityPositions = (wallet_address?: string) => useQuery({
  queryKey: ['fetchMyLiquidityPosition'],
  queryFn: () => fetchMyLiquidityPositions(wallet_address),
  refetchOnWindowFocus: false,
  enabled: wallet_address !== undefined,
})

export const useFetchAllTokenList = (enabled: boolean) =>
  useQuery(['fetchAllTokenList'], async () => await fetchAllTokens(), {
    refetchOnWindowFocus: false,
    enabled: enabled,
  })

export const defaultPoolRelatedTokens = (): APITokenInfo[] => [
  {
    id: 740,
    chain_name: 'renec',
    chain_id: isMainnet ? 'mainnet' : 'testnet',
    owner_address: '3RF3k6xEUQzEjtLSiK7maQxrDaQs9CqZ3pVYVaGyXmo6',
    coin_address: RENEC_MINT_ADDRESS,
    token_name: 'RENEC',
    token_symbol: 'RENEC',
    display_symbol: 'RENEC',
    token_type: 'core',
    logo_url: 'https://easytokenbucket.s3.amazonaws.com/nsbr7a24f6ox3xh40obw8jaaxjzt',
    status: 'success',
    description:
      'RENEC is the utility token of RENEC Blockchain. You need RENEC to pay for gas and rent when using dApps. RENEC can be bought directly from NemoSwap with gasless transaction to get a seamless start with RENEC ecosystem.',
    website: null,
    volume_24h: '0',
    decimals: 9,
    pools: [],
  },
  {
    id: 741,
    chain_name: 'renec',
    chain_id: isMainnet ? 'mainnet' : 'testnet',
    owner_address: '3RF3k6xEUQzEjtLSiK7maQxrDaQs9CqZ3pVYVaGyXmo6',
    coin_address: REVND_MINT_ADDRESS,
    token_name: 'reVND',
    token_symbol: 'reVND',
    display_symbol: 'reVND',
    token_type: 'core',
    logo_url: 'https://easytokenbucket.s3.amazonaws.com/y5ugt406h4x6nrat5u4s1cbyyaw9',
    status: 'success',
    description:
      'reVND is a token backed by reUSD. You can trade between reVND and VND with a rate fixed at 1:1 via Buy and Sell.',
    website: '',
    volume_24h: '0',
    decimals: 0,
    pools: [],
  },
  {
    id: 744,
    chain_name: 'renec',
    chain_id: isMainnet ? 'mainnet' : 'testnet',
    owner_address: '3RF3k6xEUQzEjtLSiK7maQxrDaQs9CqZ3pVYVaGyXmo6',
    coin_address: REUSD_MINT_ADDRESS,
    token_name: 'USDT',
    token_symbol: 'reUSD',
    display_symbol: 'USDT',
    token_type: 'core',
    logo_url: 'https://easytokenbucket.s3.amazonaws.com/v81a196jv7lppbf5745v8e9ax1i3',
    status: 'success',
    description:
      'reUSD is a wrapped token of stable coin USDT. It is fully backed by RENEC foundation. You can wrap and unwrap token via wrap.renec.org',
    website: '',
    volume_24h: '0',
    decimals: 9,
    pools: [],
  },
  {
    id: 1739,
    chain_name: 'renec',
    chain_id: isMainnet ? 'mainnet' : 'testnet',
    owner_address: 'HaxxjjE7tneYL82oZH8b7bs5WmTyM2z1iDYpbrGX5nJj',
    coin_address: RENGN_MINT_ADDRESS,
    token_name: 'NGN',
    token_symbol: 'reNGN',
    display_symbol: 'reNGN',
    token_type: 'core',
    logo_url: 'https://easytokenbucket.s3.amazonaws.com/l51g6wi82relpkmjnnu2bbmhv9lp',
    status: 'success',
    description:
      'reNGN is a token backed by reUSD, You can trade between reNGN and NGN with a rate fixed at 1:1.',
    website: '',
    volume_24h: '0',
    decimals: 0,
    pools: [],
  },
]
export const useFetchPoolRelatedTokenList = (noFilter?: boolean) =>
  useQuery(
    ['fetchPoolRelatedTokenList', noFilter],
    async () => {
      return await fetchPoolRelatedTokens(noFilter)
    },
    {
      initialData: defaultPoolRelatedTokens(),
      refetchOnWindowFocus: false,
      retry: true,
    },
  )

export const useFetchCoreTokenList = () =>
  useQuery(['fetchCoreTokens'], fetchCoreTokens, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

export const useFetchTopCoinList = (tags?: string[]) =>
  useQuery(['fetchTopCoins', tags], () => fetchTopCoins(tags), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

export const useFetchAllOnchainPoolAddresses = () => {
  const { client, programId } = useNemoProgram()

  return useQuery(
    ['fetchAllOnchainPoolAddresses'],
    async () => {
      const poolAccounts = await client.getContext().connection.getProgramAccounts(
        programId,
        {
          filters: [{ dataSize: 654 }],
        },
      )
      const allPoolAddresses = poolAccounts.map((account) => account.pubkey.toBase58())
      return allPoolAddresses
    },
    {
      refetchOnWindowFocus: false,
      // enabled: shouldFetch,
    },
  )
}
