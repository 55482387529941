/**
 * Returns a proxy object that waits for all of its key existence when querying.
 *
 * ```ts
 * const waiter = waitKeys({})
 * const response = await waiter['Google'] // access the property before it exists!
 *
 * fetch('https://www.google.com/')
 *   .then((res) => res.text())
 *   .then((text) => (waiter['Google'] = text))
 * ```
 */
const waitKeys = <T extends object>(object: T, timeoutInMs = 0) => {
  return new Proxy(object, {
    get(target, p, receiver) {
      const startTime = new Date()

      return new Promise((resolve, reject) => {
        const tryResolve = () => {
          if (Reflect.has(target, p)) {
            resolve(Reflect.get(target, p, receiver))
            return
          }

          if (timeoutInMs > 0 && new Date().getTime() - startTime.getTime() > timeoutInMs) {
            reject(new Error(`Timed out after waiting ${timeoutInMs}ms for key: ${p.toString()}`))
            return
          }

          setTimeout(tryResolve)
        }

        tryResolve()
      })
    },
  }) as { -readonly [K in keyof T]: Promise<T[K]> }
}

export default waitKeys
