import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  type TransactionSignature,
} from '@solana/web3.js'
import capitalize from 'lodash/capitalize'
import {
  closeSnackbar,
  type OptionsObject,
  SnackbarKey,
  useSnackbar,
} from 'notistack'
import {
  type ReactNode,
  useCallback,
} from 'react'

import TxConfirmationsAwaiting from '@/components/base/TxConfirmationsAwaiting'
import useTrans from '@/hooks/useTrans'
import { getProgramErrorCode, getTransactionDetailUrl } from '@/root/src/utils/helpers'

type ToastMessageProps = {
  type?: 'success' | 'error' | 'warning'
  txid?: string
  title?: ReactNode
  titleAppendix?: ReactNode
  message?: ReactNode
  error?: unknown
  primaryButton?: ReactNode
}

const SnackbarCloseIcon = ({ snackbarKey }: { snackbarKey: SnackbarKey }) => {
  return (
    <Box className="p-1">
      <IconButton
        className="p-1"
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => closeSnackbar(snackbarKey)}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  )
}

const useToastMessage = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const trans = useTrans()

  const showMessage = useCallback((props: ToastMessageProps, options?: OptionsObject) => {
    const { message, primaryButton, title, titleAppendix, txid, type = 'success' } = props
    const errorCode = getProgramErrorCode(props.error) ?? String(props.error ?? '')

    enqueueSnackbar(
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          spacing={1}
        >
          {type === 'success' && <CheckCircleIcon className="text-[#21D969]" />}
          {type === 'warning' && <ErrorIcon className="text-[#F2C94C]" />}
          {type === 'error' && <CancelIcon className="text-[#EB5757]" />}

          <Typography
            fontWeight="600"
            variant="h6"
            data-testid="notification-title"
          >
            {title || `${capitalize(type)}!`}
          </Typography>

          {titleAppendix}
        </Stack>

        {message && <Box data-testid="notification-message" className="text-sm">{message}</Box>}

        {errorCode && (
          <Typography className="text-xs">
            {trans.common.error_code}: <code>{errorCode}</code>
          </Typography>
        )}

        {(txid || primaryButton) && (
          <Stack
            rowGap={1}
            columnGap={2}
            justifyContent="start"
            alignItems="center"
            direction="row"
            flexWrap="wrap"
          >
            {primaryButton}
            {txid && (
              <Link
                variant="caption"
                color="inherit"
                href={getTransactionDetailUrl(txid)}
                target="_blank"
                rel="noreferrer"
              >
                <Stack direction="row" justifyContent="start" alignItems="center">
                  <Typography variant="caption">{trans.home.view_on_renec_explorer}</Typography>
                  <OpenInNewIcon className="ml-1" />
                </Stack>
              </Link>
            )}
          </Stack>
        )}
      </Stack>,
      {
        action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        className: `snackbar-${type}`,
        ...(options || {}),
      },
    )
  }, [
    enqueueSnackbar,
    trans.home.view_on_renec_explorer,
    trans.common.error_code,
  ])

  const showMaintenanceNotice = useCallback(() => {
    showMessage({
      txid: '',
      type: 'error',
      title: trans.common.under_maintenance_title,
      message: trans.common.under_maintenance_description,
    })
  }, [
    showMessage,
    trans.common.under_maintenance_title,
    trans.common.under_maintenance_description,
  ])

  const showLoadingNotice = useCallback(({
    lastValidBlockHeight,
    signature,
  }: {
    lastValidBlockHeight: number,
    signature: TransactionSignature,
  }) => {
    const key = enqueueSnackbar(
      <TxConfirmationsAwaiting lastValidBlockHeight={lastValidBlockHeight} signature={signature} />,
      {
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        className: 'snackbar-loading',
        persist: true,
      },
    )

    return () => {
      closeSnackbar(key)
    }
  }, [closeSnackbar, enqueueSnackbar])

  const showSecondaryMessage = useCallback((message: ReactNode, type?: ToastMessageProps['type']) => {
    enqueueSnackbar(
      <Stack direction="row" spacing={1} alignItems="center">
        {type === 'success' && <CheckCircleIcon className="text-[#21D969]" />}
        {type === 'warning' && <ErrorIcon className="text-[#F2C94C]" />}
        {type === 'error' && <CancelIcon className="text-[#EB5757]" />}

        <Typography className="text-sm">{message}</Typography>
      </Stack>,
      {
        action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
        className: 'snackbar-secondary',
        key: typeof message === 'string' ? message : undefined,
        preventDuplicate: true,
      },
    )
  }, [enqueueSnackbar])

  return {
    showMessage,
    showSecondaryMessage,
    showMaintenanceNotice,
    showLoadingNotice,
  }
}

export default useToastMessage
