import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import { useRollbar, useRollbarContext } from '@rollbar/react'
import {
  type ClientSubscriptionId,
  type TransactionSignature,
} from '@solana/web3.js'
import { useCallback, useEffect, useRef, useState } from 'react'

import useTrans from '@/hooks/useTrans'
import {
  toResult,
  toResultAsync,
} from '@/utils/helpers'
import { useConnection } from '@/wallet/adapter'

const VALID_BLOCK_CHECK_INTERVAL = 500

type TxStatus = 'confirming' | 'expired' | 'success' | 'failed' | 'subscription_error'

const TxConfirmationsAwaiting = ({
  lastValidBlockHeight,
  signature,
}: {
  lastValidBlockHeight: number,
  signature: TransactionSignature,
}) => {
  const rollbar = useRollbar()
  useRollbarContext('p2p#TxConfirmationsAwaiting')
  const { connection } = useConnection()
  const trans = useTrans()

  const [blocksRemaining, setBlocksRemaining] = useState<number>()
  const [status, setStatus] = useState<TxStatus>('confirming')

  const intervalIdRef = useRef<NodeJS.Timeout | null>(null)
  const signatureSubscriptionIdRef = useRef<ClientSubscriptionId | null>(null)

  const monitorTransaction = useCallback(async () => {
    const checkIfTxStillValid = async () => {
      const [getBlockHeightErr, currentBlockHeight] = await toResultAsync(connection.getBlockHeight())
      if (getBlockHeightErr) {
        rollbar.error('Error getting block height:', { getBlockHeightErr })
        setStatus('subscription_error')
      }
      if (currentBlockHeight) {
        const remaining = lastValidBlockHeight - currentBlockHeight
        const remainingBlocks = Math.max(0, remaining)
        setBlocksRemaining(remainingBlocks)

        if (remainingBlocks <= 0) {
          setStatus('expired')
          if (intervalIdRef.current) clearInterval(intervalIdRef.current)
          return false
        }
        return true
      }

      return false
    }

    const [subscribeSigErr, subId] = toResult(() => connection.onSignature(
      signature,
      (result, context) => {
        setStatus(result.err ? 'failed' : 'success')
        if (intervalIdRef.current) clearInterval(intervalIdRef.current)
      },
      'finalized',
    ))
    if (subscribeSigErr) {
      rollbar.error('Error setting up WebSocket connections:', { subscribeSigErr })
      setStatus('subscription_error')
    } else {
      signatureSubscriptionIdRef.current = subId
    }

    const isTxStillValid = await checkIfTxStillValid()
    if (isTxStillValid) {
      intervalIdRef.current = setInterval(async () => {
        const stillValid = await checkIfTxStillValid()
        if (!stillValid && intervalIdRef.current) {
          clearInterval(intervalIdRef.current)
        }
      }, VALID_BLOCK_CHECK_INTERVAL)
    }
  }, [
    lastValidBlockHeight,
    signature,
    connection,
    rollbar,
  ])

  useEffect(() => {
    monitorTransaction()

    return () => {
      if (intervalIdRef.current) clearInterval(intervalIdRef.current)
      if (signatureSubscriptionIdRef.current) connection.removeSignatureListener(signatureSubscriptionIdRef.current)
    }
  }, [monitorTransaction, connection])

  return (
    <Stack spacing={2} className="text-xs" direction="row" alignItems="center">
      <span className="text-[#DDE0E5] iw:text-[#1F5F8D]">
        <CircularProgress data-testid="circular-progress" color="inherit" size="1.25em" />
      </span>
      <span className="text-[#DDE0E5] iw:text-[#666]">
        {(status === 'confirming' || status === 'subscription_error') && (
          <p>
            {trans.common.tx_notice.confirming}
            {status === 'confirming' && blocksRemaining ? ` (${blocksRemaining})` : ''}
          </p>
        )}
        {status === 'expired' && (
          <p>{trans.common.tx_notice.expired}</p>
        )}
        {status === 'failed' && (
          <p>{trans.common.tx_notice.unconfirmed_message}</p>
        )}
        {status === 'success' && (
          <p>{trans.common.tx_notice.success_message}</p>
        )}
      </span>
    </Stack>
  )
}

export default TxConfirmationsAwaiting
