import { createContext, ReactElement, useCallback, useEffect } from 'react'
import { useQuery } from 'react-query'

import useLang from '@/hooks/useLang'
import { fetchGeoInfo } from '@/utils/apis/helper'

interface LanguageContextValues {
  handleChangeLanguage: (lang: string) => void;
  lang: string
  isoCode?: string,
  geoData?: {
    info: object,
    lang: string,
    country: {
      geonameId: string,
      isoCode: string,
      names: object,
    }
  }
}

export const LanguageContext = createContext({} as LanguageContextValues)

function Provider({ children }: HocProps): ReactElement {
  const [lang, setLang] = useLang()
  const { data } = useQuery('fetchGeoData', fetchGeoInfo, { refetchOnWindowFocus: false })

  const handleChangeLanguage = useCallback(
    (value: string) => {
      localStorage.setItem('lang', value)
      setLang(value)
    },
    [setLang],
  )

  useEffect(() => {
    if (!localStorage.getItem('lang') && data?.lang) {
      handleChangeLanguage(data?.lang)
    }
  }, [handleChangeLanguage, data?.lang])

  const contextValues = {
    handleChangeLanguage,
    lang,
    geoData: data,
    isoCode: data?.country?.isoCode,
  }

  return (
    <LanguageContext.Provider value={contextValues}>
      {children}
    </LanguageContext.Provider>
  )
}

export default Provider
