/* eslint-disable no-console */
import { useTheme } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import Drawer from '@mui/material/Drawer'
import Modal from '@mui/material/Modal'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useRouter } from 'next/router'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'

import PendingTransactionsRemind from '@/components/history/pending-transactions-remind'
import type { HistoryTransactions } from '@/components/history/types'
import { isEntityActive } from '@/components/offer/helpers'
import { BEST_SELLING_OFFER_COOKIE_PREFIX } from '@/constants/index'
import {
  DELAY_TO_FETCH_IN_MILLISECONDS,
  REFETCH_INTERVAL_IN_MILLISECONDS,
  REMINDER_COUNT,
} from '@/contexts/pending-transactions/constants'
import useFetchTradeOrders from '@/hooks/use-fetch-trade-orders'
import useNemoWallet from '@/hooks/use-nemo-wallet'
import { deleteCookiesByPrefix } from '@/utils/cookies'
import { toResultAsync } from '@/utils/helpers'

type PendingTransactionsContext = {
  isLoading: boolean
  isReloading: boolean
  pendingTransactions: HistoryTransactions
  historyTransactions: HistoryTransactions
  isDisplayPendingBadge: boolean
  handleReload: () => void
  handleFetchMyTransactions: () => void
  pendingTxCountByTrades: number
}

const dialogClasses = { root: 'max-w-[488px] sm:min-w-[488px] p-0 mx-auto outline-none absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' }

export const PendingTransactionsCtx = createContext<PendingTransactionsContext>({
  isLoading: false,
  isReloading: false,
  pendingTransactions: [],
  historyTransactions: [],
  isDisplayPendingBadge: false,
  handleReload: () => null,
  handleFetchMyTransactions: () => null,
  pendingTxCountByTrades: 0,
})

const waitRoutingSpinnerIsFinished = () => {
  return new Promise((resolve) => {
    const TIMEOUT_TO_WAIT = 1000 * 60 // 60 seconds
    const startTime = new Date().getTime()

    const intervalId = setInterval(() => {
      const endTime = new Date().getTime()
      const isTimeout = endTime - startTime >= TIMEOUT_TO_WAIT

      if (!document.querySelector('.routing-spinner-wrap') || isTimeout) {
        clearInterval(intervalId)
        resolve(null)
      }
    }, 300)
  })
}

export const PendingTransactionsProvider = ({ children }: HocProps) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const { publicKey } = useNemoWallet()
  const { fetchMyTrades, fetchMyOffers } = useFetchTradeOrders()
  const router = useRouter()
  const [displayReminderCount, setDisplayReminderCount] = useState(REMINDER_COUNT.init)
  const [readyToFetch, setReadyToFetch] = useState(false)
  const [isReloading, setIsReloading] = useState(true)

  const { isLoading, data, refetch: handleFetchMyTransactions } = useQuery({
    queryKey: 'p2p-history',
    queryFn: async () => {
      const [resultOffers, resultDeposit, resultWithdraw] = await Promise.all([
        toResultAsync(fetchMyOffers()),
        toResultAsync(fetchMyTrades('Buy')),
        toResultAsync(fetchMyTrades('Sell')),
      ])

      const [errOffers, txOffers = []] = resultOffers
      const [errDeposit, txDeposit = []] = resultDeposit
      const [errWithdraw, txWithdraw = []] = resultWithdraw

      if (errOffers || errDeposit || errWithdraw) {
        console.log(errOffers)
        console.log(errDeposit)
        console.log(errWithdraw)
      }

      setIsReloading(false)
      return [...txOffers, ...txDeposit, ...txWithdraw].sort(
        (a, b) => b.createdAt.toNumber() - a.createdAt.toNumber(),
      )
    },
    enabled: Boolean(publicKey && readyToFetch),
    refetchInterval: REFETCH_INTERVAL_IN_MILLISECONDS,
  })
  const historyTransactions = useMemo(() => data || [], [data])

  useEffect(() => {
    if (!router.pathname.includes('offer')) {
      deleteCookiesByPrefix(BEST_SELLING_OFFER_COOKIE_PREFIX)
    }

    ['/p2p/offer/[id]', '/p2p/trade/[id]'].forEach((path) => {
      if (router.pathname !== path) deleteCookiesByPrefix(`del-when-leave-${path}`)
    })
  }, [router])

  useEffect(() => {
    setTimeout(() => {
      setReadyToFetch(true)
    }, DELAY_TO_FETCH_IN_MILLISECONDS)
  }, [])

  const pendingTransactions: HistoryTransactions = useMemo(() => {
    return historyTransactions.filter(isEntityActive)
  }, [historyTransactions])

  const pendingTxCountByTrades = useMemo(() => {
    return pendingTransactions.filter(txItem => 'tradeId' in txItem).length
  }, [pendingTransactions])

  const isDisplayPendingBadge = useMemo(() => {
    return pendingTxCountByTrades > 0
      && !router.pathname.includes('_error')
      && !router.pathname.includes('history')
      && !router.pathname.includes('trade')
      && !router.pathname.includes('offer')
  }, [pendingTxCountByTrades, router.pathname])

  const handleReload = useCallback(() => {
    setIsReloading(true)
    handleFetchMyTransactions()
  }, [setIsReloading, handleFetchMyTransactions])

  const value = useMemo(
    (): PendingTransactionsContext => ({
      isLoading: isLoading || isReloading,
      isReloading,
      pendingTransactions,
      historyTransactions,
      isDisplayPendingBadge,
      handleFetchMyTransactions,
      handleReload,
      pendingTxCountByTrades,
    }),
    [
      isLoading,
      isReloading,
      historyTransactions,
      pendingTransactions,
      isDisplayPendingBadge,
      handleReload,
      handleFetchMyTransactions,
      pendingTxCountByTrades,
    ],
  )

  const onClose = useCallback(() => {
    setDisplayReminderCount(REMINDER_COUNT.close)
  }, [])

  const handleGoToPendingTransactions = useCallback(() => {
    router.push('/history')
    setDisplayReminderCount(REMINDER_COUNT.close)
  }, [router])

  useEffect(() => {
    if (isDisplayPendingBadge && displayReminderCount === REMINDER_COUNT.init) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const handler = (evt: any) => {
        evt.preventDefault()
        evt.stopPropagation()

        /**
         * Delay a bit to show reminder after any actions of users to avoid annoying
         *  and make sure the "z-index" of the last added DOM element has the highest value
         *  compared to other drawers/modals such as TokenPicker, ...
         * */
        setTimeout(async () => {
          await waitRoutingSpinnerIsFinished()
          setDisplayReminderCount(REMINDER_COUNT.show)
        }, 500)

        document.removeEventListener('click', handler)
      }

      document.addEventListener('click', handler)

      return () => {
        document.removeEventListener('click', handler)
      }
    }
  }, [isDisplayPendingBadge, displayReminderCount])

  return (
    <PendingTransactionsCtx.Provider value={value}>
      {children}
      {isDisplayPendingBadge &&
        (isDesktop ? (
          <Modal open={displayReminderCount === REMINDER_COUNT.show}>
            <DialogContent classes={dialogClasses}>
              <PendingTransactionsRemind
                onClose={onClose}
                onContinue={handleGoToPendingTransactions}
                count={pendingTxCountByTrades}
              />
            </DialogContent>
          </Modal>
        ) : (
          <Drawer
            anchor="bottom"
            open={displayReminderCount === REMINDER_COUNT.show}
            sx={{
              '&': { zIndex: 1300 },
              '.MuiPaper-elevation': {
                backgroundColor: 'transparent',
                backgroundImage: 'none',
              },
            }}
          >
            <PendingTransactionsRemind
              onClose={onClose}
              onContinue={handleGoToPendingTransactions}
              count={pendingTxCountByTrades}
            />
          </Drawer>
        ))}
    </PendingTransactionsCtx.Provider>
  )
}
