// import useMediaQuery from '@mui/material/useMediaQuery'
import { createTheme,CssBaseline, ThemeProvider } from '@mui/material'
import { createContext, PropsWithChildren,ReactElement, useEffect } from 'react'

import { isInstantWireMode } from '@/constants/index'

import getThemeOptions from './options'
import type { CustomPaletteMode } from './types'

export const preferredThemeMode: CustomPaletteMode = isInstantWireMode() ? 'iw' : 'dark'
const themeOptions = createTheme(getThemeOptions(preferredThemeMode))

const contextValues = {
  themeMode: preferredThemeMode,
  themeOptions,
}

export const ThemeContext = createContext(contextValues)

const Provider = ({ children }: PropsWithChildren): ReactElement => {
  // const preferredThemeMode = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light'

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', preferredThemeMode)

    const themeClasses = ['dark', 'iw']
    document.documentElement.classList.remove(...themeClasses)
    document.body.classList.remove(...themeClasses)

    document.documentElement.classList.add(preferredThemeMode)
    document.body.classList.add(preferredThemeMode)
  }, [])

  return (
    <ThemeContext.Provider value={contextValues}>
      <ThemeProvider theme={contextValues.themeOptions}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export default Provider
