import Image from 'next/image'
import React, { ReactNode } from 'react'

import useToastMessage from '@/hooks/use-toast-message'
import useTrans from '@/hooks/useTrans'
import { minifyAddress } from '@/utils/helpers'
import {
  IconName,
  staticImportIcon,
} from '@/utils/static-import-icon'

type Props = {
  address: string,
  className?: string,
  iconName?: string,
  endAdornment?: ReactNode
  testid?: string
}

const AddressSpanWithCopyButton = ({
  address,
  endAdornment,
  iconName = IconName.COPY,
  testid = 'minify-token-address',
  className = 'flex items-center mt-2 text-xs token-address gap-x-2',
}: Props) => {
  const { showSecondaryMessage } = useToastMessage()
  const addressFormatted = minifyAddress(address)
  const trans = useTrans()

  return (
    <span className={className} data-testid={testid}>
      {addressFormatted}
      <Image
        src={staticImportIcon(iconName)}
        alt="copy" width={18} className="cursor-pointer shrink-0"
        onClick={(evt) => {
          evt.stopPropagation()
          navigator.clipboard.writeText(address)
          showSecondaryMessage(trans.common.copied_address_snackbar)
        }}
      />
      {endAdornment}
    </span>
  )
}

export default AddressSpanWithCopyButton
