import isString from 'lodash/isString'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { BANKS } from '@/constants/banks'
import CURRENCY_TO_COUNTRIES_MAPPER from '@/constants/currency_to_countries'
import { type FiatTokenInfo } from '@/hooks/use-tokens'
import { getPaymentMethods } from '@/utils/apis/payment-methods'

export type FiatBank = {
  id: string
  displayName: string
  fullName?: string
  shortName: string
  supportedFiats?: string[]
}

export const toFiatBank = (value: string | FiatBank = ''): FiatBank =>
  isString(value)
    ? {
        id: value,
        displayName: value,
        shortName: value,
      }
    : value

export const useFiatBanks = (fiatToken?: FiatTokenInfo) => {
  const { locale } = useRouter()

  const fiatSupportedBankNames = useMemo<FiatBank[]>(() => {
    const displayNameKey = locale === 'vi' ? 'display_name_vi' : 'display_name_en'
    const allBanks = Object.entries(BANKS).map(([id, bank]) => ({
      id,
      displayName: bank.transacting_name
        ? `${bank.transacting_name} - ${bank[displayNameKey]}`
        : bank[displayNameKey],
      fullName: bank[displayNameKey],
      shortName: bank.transacting_name,
      supportedFiats: bank.supported_fiats,
    }))

    const fiatCurrency = fiatToken?.fiatCurrency?.toLowerCase()
    if (!fiatCurrency) { // list all banks if no fiat currency is specified
      return allBanks
    }

    return allBanks.filter(({ supportedFiats }) => supportedFiats.includes(fiatCurrency))
  }, [fiatToken, locale])

  const { data: remiFiatSupportedBankNames = [] } = useQuery<FiatBank[]>(
    ['country_banks', fiatToken?.fiatCurrency],
    async () => {
      if (!fiatToken?.fiatCurrency) {
        return []
      }

      const { fiatCurrency } = fiatToken

      const countryCodes = CURRENCY_TO_COUNTRIES_MAPPER[
        fiatCurrency.toUpperCase() as keyof typeof CURRENCY_TO_COUNTRIES_MAPPER
      ]
      if (!countryCodes) {
        return []
      }

      const banks = await getPaymentMethods(countryCodes).then((data) => {
        return data.map((item) => item.name)
      })
      return banks.map((name) => ({
        id: name,
        displayName: name,
        shortName: name,
        supportedFiats: [fiatCurrency.toLowerCase()],
      }))
    },
    {
      enabled: fiatSupportedBankNames && fiatSupportedBankNames.length === 0,
      refetchOnWindowFocus: false,
    },
  )

  const banks = useMemo(
    () =>
      [
        ...(fiatSupportedBankNames.length ? fiatSupportedBankNames : remiFiatSupportedBankNames),
      ].sort((bankA, bankB) => bankA.displayName.localeCompare(bankB.displayName)),
    [fiatSupportedBankNames, remiFiatSupportedBankNames],
  )

  return { banks }
}

export default useFiatBanks
