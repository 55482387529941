import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import type { ResponsiveStyleValue } from '@mui/system'
import cls from 'classnames'
import { type FC, type ReactNode, useMemo } from 'react'

import Button from '@/components/base/Button'
import useTrans from '@/hooks/useTrans'
import { isRunningInCypress } from '@/utils/helpers'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BaseDialogParams = Record<`data-${string}`, any> & {
  title?: string | ReactNode
  body: ReactNode
  isLoading?: boolean
  isOpened?: boolean
  handleClose?: () => void
  disabled?: boolean
  useDrawerOnMobile?: boolean
  showCloseButton?: boolean
  isSmallPopup?: boolean
  className?: string
  classNameFooter?: string
} & (
  | {
      button: ReactNode
      buttonDirection?: never
      buttons?: never
      buttonSpacing?: never
      confirmButtonText?: never
      hideConfirmButton?: never
      onConfirm?: never
    }
  | {
      button?: never
      buttonDirection?: 'column' | 'row'
      buttons: ReactNode[]
      buttonSpacing?: ResponsiveStyleValue<string | number>
      confirmButtonText?: never
      hideConfirmButton?: never
      onConfirm?: never
    }
  | {
      button?: never
      buttonDirection?: never
      buttons?: never
      buttonSpacing?: never
      confirmButtonText?: string
      hideConfirmButton?: boolean
      onConfirm?: () => void
    }
)

const BaseDialog: FC<BaseDialogParams> = ({
  title,
  body,
  isLoading,
  isOpened,
  isSmallPopup,
  onConfirm,
  handleClose,
  confirmButtonText,
  disabled,
  showCloseButton = true,
  button,
  buttonDirection,
  buttons,
  buttonSpacing,
  hideConfirmButton,
  useDrawerOnMobile,
  className,
  classNameFooter,
  ...props
}) => {
  const trans = useTrans()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const Component = useMemo(
    () => (useDrawerOnMobile && !isDesktop ? Drawer : Dialog),
    [isDesktop, useDrawerOnMobile],
  )

  const titleBg = useMemo(
    () => title
      ? 'bg-[#1A2330] iw:bg-[#ffffff] iw:border-0 iw:border-b-[1px] iw:border-solid iw:border-[#EAEAEA]'
      : 'bg-[#1E2735] iw:bg-[#ffffff] iw:border-0 iw:border-b-[1px] iw:border-solid iw:border-[#EAEAEA]',
  [title])
  const showTitle = useMemo(() => title || showCloseButton, [showCloseButton, title])

  const isUnmountWithoutAnimation = isRunningInCypress && !isOpened
  if (isUnmountWithoutAnimation) {
    return null
  }

  return (
    <Component
      {...props}
      open={!!isOpened}
      onClose={handleClose}
      PaperProps={{
        className: cls(
          className,
          Component === Dialog && 'rounded-b-[24px]',
          isDesktop ? (isSmallPopup ? '!w-[375px]' : '!w-[480px]') : 'min-w-full-width-dialog',
          'rounded-t-[24px]',
        ),
      }}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      anchor="bottom"
    >
      {showTitle && (
        <DialogTitle
          id="scroll-dialog-title"
          data-testid="dialog-title"
          className={`${titleBg} py-3 px-6`}
        >
          {typeof title === 'string' ? (
            <Typography className="text-base sm:text-lg font-bold text-white iw:text-[#000E0F]">{title}</Typography>
          ) : (
            title
          )}
          {showCloseButton && (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 4,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent className={cls(Component === Dialog && 'w-full py-4 px-6', 'bg-[#1E2735] iw:bg-white')}>
        <Stack className={showTitle ? 'mt-4' : ''} spacing={2}>
          {body}
        </Stack>
      </DialogContent>
      {(buttons?.length || button || !hideConfirmButton) && (
        <DialogActions className={classNameFooter || 'bg-[#1E2735] iw:bg-white pt-0 pb-6 px-6'}>
          <Stack spacing={buttonSpacing || 1} width="100%" direction={buttonDirection}>
            {buttons?.length ? (
              buttons
            ) : button ? (
              button
            ) : !hideConfirmButton ? (
              <Button
                data-testid="btn-confirm-info-dialog"
                variant="primary"
                size="xl"
                fullWidth
                disabled={disabled || isLoading}
                onClick={onConfirm}
              >
                {confirmButtonText || trans.sell.save}
              </Button>
            ) : null}
          </Stack>
        </DialogActions>
      )}
    </Component>
  )
}

export default BaseDialog
