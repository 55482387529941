import '@renec-foundation/wallet-adapter-react/src/style.css'

import { Provider } from '@renec-foundation/wallet-adapter-react'
import { PropsWithChildren, useMemo } from 'react'

import {
  CHAIN_CONSTANTS,
  isChainSolana,
  isMainnet,
} from '@/constants/index'
import { isRunningInCypress } from '@/utils/helpers'

if (!isChainSolana()) {
  // @ts-ignore
  import('@renec-foundation/wallet-adapter-react/src/style.css')
}

const WalletProvider = ({ children }: PropsWithChildren) => {
  const e2eWalletPrivKey = useMemo(() => {
    return isRunningInCypress && window.Cypress.env('E2E_WALLET_PRIVATE_KEY')
  }, [])

  return (
    <Provider
      isMainnet={isMainnet}
      e2eWalletPrivKey={e2eWalletPrivKey}
      endpoint={CHAIN_CONSTANTS}
    >
      {children}
    </Provider>
  )
}

export default WalletProvider
