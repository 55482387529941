import {
  useEffect,
  useState,
} from 'react'

const usePreviousValue = <T,>(value: T): T | undefined => {
  const [previousValue, setPreviousValue] = useState<T>()

  useEffect(() => {
    setPreviousValue(value)
  }, [value])

  return previousValue
}

export default usePreviousValue
