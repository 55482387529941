import {
  CHAIN_CONSTANTS,
  isChainSolana,
  isMainnet,
} from '@/constants/index'
import sampleSettings from '@/root/cypress/fixtures/settings.json'
import { nextIsRunningCypress } from '@/utils/helpers'

import { api } from './'
import { chainParams } from './constants'

export interface IRewardSwappingToken {
  mint_address: string
  min_amount: number
}

export type FeatureFlagOption = 'disabled' | 'closed_beta' | 'enabled'

export interface ISettings {
  amount_limit: Record<string, Record<'min' | 'max', number>>
  off_ramp_amount_limit: Record<string, {
    min: number
    max?: number
  }>
  off_ramp_min_trade_size_auto_setup: Record<string, Record<'amount' | 'min_trade_size', number>>
  notice_messages: Record<string, string>[]
  highlight_messages: Record<string, Record<string, string>>
  support_link: string
  config_key: string
  pool_program_id: string
  submit_timeout_in_seconds: number
  is_gasless_mode_enabled: boolean
  referral_feature_enabled: boolean
  liquidity_pool_creation_fixed_token_symbols: string[]
  off_ramp_feature_enabled: boolean
  off_ramp_offers_matching_feature_enabled: boolean
  legacy_off_ramp_program_id: string
  legacy_off_ramp_config_id: string
  solana_off_ramp_program_id: string
  solana_off_ramp_config_id: string
  off_ramp_program_id: string
  off_ramp_config_id: string
  off_ramp_modifying_rate: number
  off_ramp_modifying_express_rate: number
  off_ramp_modifying_rate_selection_enabled: boolean
  off_ramp_fiat_tokens: string[]
  off_ramp_auto_create_buy_offer_enabled: boolean
  off_ramp_buying_offer_limit_amount: Record<string, number>
  quota_feature_enabled: boolean
  orderbook_feature_enabled: boolean
  p2p_chat_feature_enabled: boolean
  p2p_matching_engine_feature_enabled: boolean
  p2p_bondless_feature_enabled: boolean
  p2p_bondless_buying_limit_amount: Record<string, number>
  p2p_bondless_time_delay_to_released_in_sec: number
  p2p_buyer_rewards: Record<string, {
    trading_amount_step: number
    rewarding_amount_unit_scale: number
    rewarding_token_symbol: string
  }>
  dispute_awaiting_duration_in_min: number
  payment_evidence_allowed_file_types: string[]
  payment_evidence_allowed_max_file_size_in_bytes: number
  nemo_swap_24h_line_chart_enabled: boolean
  nemo_swap_two_hop_enabled: boolean
  one_pool_swapping_mode: boolean
  swap_reward_swapping_tokens: IRewardSwappingToken[]
  backend_secured_public_key: string
  orderbook_program_id: string
  orderbook_seat_manager_program_id: string
  swap_and_orderbook_router_feature_enabled: boolean
  off_ramp_feature_under_maintenance_mode: boolean
  off_ramp_auto_split_method: string
  off_ramp_auto_split_count: { [key: string]: number }
  off_ramp_auto_split_amount: { [key: string]: number }
  p2p_market_feature_enabled: boolean
  p2p_market_create_buying_offer_feature_enabled: boolean
  p2p_price_suggestion_rate: number
  usdt_price_deviation: { [key: string]: number }
  nemo_swap_prioritized_pool_quantity: number
  off_ramp_fixed_fee: { [key: string]: number }
  nemo_swap_assets_euro2024_token_list_enabled: boolean
  nemo_swap_dev_whitelisted_addresses: string[]
  nemo_swap_p2p_market_full_fiats_and_banks: FeatureFlagOption
  leader_board_feature_enabled: boolean
  p2p_crowed_markets: string[]
  p2p_multiple_dispute_feature_enabled: boolean
}

const getSettingByKey = (key: keyof typeof sampleSettings, data: any) => {
  return data[key] ?? sampleSettings[key]
}

const mergeDefaultSettingByKey = (key: keyof typeof sampleSettings, data: any) => {
  return {
    ...(sampleSettings[key] as object),
    ...(data[key] || {}),
  }
}

export const fetchSettings = async (): Promise<ISettings> => {
  if (nextIsRunningCypress && typeof window === 'undefined') {
    return sampleSettings as ISettings
  }

  const { data } = await api.get<ISettings>('/settings/nemo_swap', { params: chainParams })

  if (isMainnet) {
    data.legacy_off_ramp_program_id = '7TVqQ1hQhQc8BLLKVYbc6jYPX4FejEDa9SDQzfkDsJLJ'
    data.legacy_off_ramp_config_id = 'escrow05'
    data.off_ramp_program_id = CHAIN_CONSTANTS.defaultProgramId
    data.off_ramp_config_id = CHAIN_CONSTANTS.defaultConfigId
    data.off_ramp_fiat_tokens = isChainSolana() ? CHAIN_CONSTANTS.whitelistedTokenMints : (data.off_ramp_fiat_tokens || CHAIN_CONSTANTS.whitelistedTokenMints)
    data.orderbook_program_id = 'Hy4AB2vSaamgYAZuQQ5a2AJBac2KtXxtcbxPBZdRxvBh'
    data.orderbook_seat_manager_program_id = '5TAT7uKykpNzHTzh7DYfknR5xE3H1hjQb3E7uC83MV5P'
    data.off_ramp_feature_under_maintenance_mode = false
  } else {
    data.off_ramp_fiat_tokens = isChainSolana()
      ? getSettingByKey('solana_off_ramp_fiat_tokens', data)
      : getSettingByKey('off_ramp_fiat_tokens', data)
    data.off_ramp_program_id = isChainSolana()
      ? getSettingByKey('solana_off_ramp_program_id', data)
      : getSettingByKey('off_ramp_program_id', data)
    data.off_ramp_config_id = isChainSolana()
      ? getSettingByKey('solana_off_ramp_config_id', data)
      : getSettingByKey('off_ramp_config_id', data)
  }

  return {
    ...data,
    nemo_swap_24h_line_chart_enabled: getSettingByKey('nemo_swap_24h_line_chart_enabled', data),
    nemo_swap_two_hop_enabled: getSettingByKey('nemo_swap_two_hop_enabled', data),
    p2p_matching_engine_feature_enabled: getSettingByKey('p2p_matching_engine_feature_enabled', data),
    p2p_bondless_buying_limit_amount: getSettingByKey('p2p_bondless_buying_limit_amount', data),
    p2p_bondless_time_delay_to_released_in_sec: getSettingByKey(
      'p2p_bondless_time_delay_to_released_in_sec',
      data,
    ),
    dispute_awaiting_duration_in_min: getSettingByKey('dispute_awaiting_duration_in_min', data),
    payment_evidence_allowed_file_types: getSettingByKey(
      'payment_evidence_allowed_file_types',
      data,
    ),
    liquidity_pool_creation_fixed_token_symbols: getSettingByKey(
      'liquidity_pool_creation_fixed_token_symbols',
      data,
    ),
    payment_evidence_allowed_max_file_size_in_bytes: getSettingByKey(
      'payment_evidence_allowed_max_file_size_in_bytes',
      data,
    ),
    off_ramp_amount_limit: mergeDefaultSettingByKey('off_ramp_amount_limit', data),
    off_ramp_buying_offer_limit_amount: mergeDefaultSettingByKey('off_ramp_buying_offer_limit_amount', data),
    off_ramp_offers_matching_feature_enabled: getSettingByKey('off_ramp_offers_matching_feature_enabled', data),
    off_ramp_min_trade_size_auto_setup: mergeDefaultSettingByKey('off_ramp_min_trade_size_auto_setup', data),
    backend_secured_public_key: getSettingByKey('backend_secured_public_key', data),
    off_ramp_modifying_rate: getSettingByKey('off_ramp_modifying_rate', data),
    off_ramp_modifying_rate_selection_enabled: getSettingByKey('off_ramp_modifying_rate_selection_enabled', data),
    off_ramp_auto_split_method: getSettingByKey('off_ramp_auto_split_method', data),
    off_ramp_auto_split_count: getSettingByKey('off_ramp_auto_split_count', data),
    off_ramp_auto_split_amount: getSettingByKey('off_ramp_auto_split_amount', data),
    orderbook_feature_enabled:
      getSettingByKey('orderbook_feature_enabled', data)
      // @ts-ignore TODO: @trathailoi remove this line after BE correct the feature flag name
      || getSettingByKey('p2p_limit_order_feature_enabled', data),
    p2p_market_feature_enabled: getSettingByKey('p2p_market_feature_enabled', data),
    p2p_price_suggestion_rate: getSettingByKey('p2p_price_suggestion_rate', data),
    usdt_price_deviation: getSettingByKey('usdt_price_deviation', data),
    nemo_swap_prioritized_pool_quantity: getSettingByKey('nemo_swap_prioritized_pool_quantity', data),
    nemo_swap_assets_euro2024_token_list_enabled: getSettingByKey('nemo_swap_assets_euro2024_token_list_enabled', data),
    nemo_swap_dev_whitelisted_addresses: getSettingByKey('nemo_swap_dev_whitelisted_addresses', data),
    nemo_swap_p2p_market_full_fiats_and_banks: getSettingByKey('nemo_swap_p2p_market_full_fiats_and_banks', data),
    leader_board_feature_enabled: getSettingByKey('leader_board_feature_enabled', data),
    p2p_crowed_markets: getSettingByKey('p2p_crowed_markets', data),
    p2p_multiple_dispute_feature_enabled: getSettingByKey('p2p_multiple_dispute_feature_enabled', data),
  }
}
