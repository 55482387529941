import { CircularProgress, Stack } from '@mui/material'
import Decimal from 'decimal.js'
import { forwardRef, useCallback, useContext, useMemo } from 'react'
import { FixedSizeList as List, type ListOnItemsRenderedProps } from 'react-window'

import TokenListContext from '../context'
import TokenListItem from '../TokenListItem'
import RecentTokenItem from './RecentTokenItem'

// eslint-disable-next-line react/display-name
const AutocompleteListboxComponent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  (props, ref) => {
    const { role, ...other } = props
    const { tokens, hasMoreTokens, loadMoreTokens, isDisplayAddress, availableRecentTokens } = useContext(TokenListContext)

    const styling = useMemo(
      () => ({
        itemSize: isDisplayAddress ? 58 : 48,
        itemGap: 4,
        itemPerView: 4,
      }),
      [isDisplayAddress],
    )

    const listHeight = useMemo(
      () =>
        new Decimal(styling.itemSize)
          .plus(styling.itemGap)
          .times(styling.itemPerView)
          .minus(styling.itemGap),
      [styling],
    )

    const calculateItemPositionTop = useCallback(
      (index: number) =>
        new Decimal(index)
          .times(styling.itemSize + styling.itemGap)
          .minus(index ? styling.itemGap : 0)
          .toNumber(),
      [styling],
    )

    const onItemsRendered = useCallback(
      async ({ visibleStopIndex }: ListOnItemsRenderedProps) => {
        if (hasMoreTokens && visibleStopIndex === tokens.length) {
          await loadMoreTokens()
        }
      },
      [hasMoreTokens, loadMoreTokens, tokens],
    )

    const [height, itemSize] = useMemo(() => [
      listHeight.plus(new Decimal(styling.itemSize).div(2)).toNumber(),
      listHeight.div(styling.itemPerView).toNumber(),
    ], [listHeight, styling])

    return (
      <div ref={ref}>
        <div {...other}>
          {availableRecentTokens.length > 0 && (
            <Stack
              direction="row"
              justifyContent="start"
              alignItems="center"
              gap={1}
              flexWrap="wrap"
              className="pb-4"
              data-testid="recent-token-list"
            >
              {availableRecentTokens.map((token, index) => (
                <RecentTokenItem key={`${token.address}-${index}`} token={token} />
              ))}
            </Stack>
          )}

          <List
            className="token-list"
            height={height}
            width="100%"
            itemCount={tokens.length + +hasMoreTokens}
            itemSize={itemSize}
            onItemsRendered={onItemsRendered}
          >
            {({ index, style }) => (
              <div
                key={tokens[index]?.address || 'loading-icon'}
                className="flex w-full items-center justify-center overflow-hidden"
                style={{
                  ...style,
                  top: calculateItemPositionTop(index),
                  height: styling.itemSize,
                  marginTop: index ? styling.itemGap : 0,
                }}
              >
                {tokens[index] ? (
                  <TokenListItem className="w-full" option={tokens[index]} />
                ) : (
                  <CircularProgress className="h-full w-full" />
                )}
              </div>
            )}
          </List>
        </div>
      </div>
    )
  },
)

AutocompleteListboxComponent.displayName = 'AutocompleteListboxComponent'
export default AutocompleteListboxComponent
