import { type TokenInfo } from '@renec-foundation/rpl-token-registry'

import { DefaultTokenInfo } from '@/hooks/use-tokens'
import { type CustomTokenInfo } from '@/utils/helpers'

export const searchTokenFromList = (
  pattern: string,
  list: CustomTokenInfo[],
  allowCustomOption = false,
) => {
  const searchValue = pattern.trim().toLowerCase()
  const matchesExact: CustomTokenInfo[] = []
  const matchesPartial: CustomTokenInfo[] = []

  list.forEach((token) => {
    const { address: originalAddress, symbol: originalSymbol, displaySymbol } = token
    const symbol = (displaySymbol || originalSymbol).toLowerCase()
    const address = originalAddress.toLowerCase()

    const isExactMatch = address === searchValue || symbol === searchValue
    const isPartialMatch = symbol.includes(searchValue)

    if (isExactMatch) matchesExact.push(token)
    else if (isPartialMatch) matchesPartial.push(token)
  })

  // Only add the custom option if there is no whole match!
  const matchesCustom =
    allowCustomOption && pattern && !matchesExact.length
      ? [{ ...DefaultTokenInfo, address: pattern }]
      : []

  return [...matchesExact, ...matchesPartial, ...matchesCustom]
}

/**
 * The higher value a token has, the closer it is to the top of the list.
 * Other tokens will have a default order of zero.
 */
const tokenOrder = new Proxy(
  {
    RENEC: 3,
    reUSD: 2.1,
    reVND: 2,
    reETH: 1.9,
    reBTC: 1.9,
    reNGN: 1.9,
  } as Record<string, number>,
  {
    get(target, prop: string) {
      return target[prop] || 0
    },
  },
)

export const sortTokensByOrder = (tokens: TokenInfo[]) => {
  return tokens.sort((tokenA, tokenB) => tokenOrder[tokenB.symbol] - tokenOrder[tokenA.symbol])
}
