import type { Whirlpool } from '@renec-foundation/nemoswap-sdk'
import merge from 'lodash/merge'
import uniq from 'lodash/uniq'
import {
  createContext,
  type Dispatch,
  type MutableRefObject,
  type PropsWithChildren,
  type SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import useLoadingStateTracker from '@/hooks/use-loading-state-tracker'
import { useNemoProgram } from '@/providers/nemo-program'
import { type LiquidityPool, searchLiquidityPools } from '@/utils/apis/liquidity'
import { DEFAULT_POOL_ADDRESSES } from '@/utils/helpers'
import waitKeys from '@/utils/wait-keys'

export type IPool = {
  metadata?: LiquidityPool
  type?: 'approved' | 'pending'
  whirlpool?: Whirlpool
}

export type IPoolResponse = { [poolAddress: string]: Promise<IPool | undefined> }

type IPoolContext = {
  loadingPoolMap: Record<
    string,
    {
      resolver: Promise<LiquidityPool | undefined>
      status: 'all' | 'approved' | 'pending' | 'none'
    }
  >
  loadingWhirlpoolMap: Record<string, Promise<Whirlpool | undefined>>
  poolMap: Record<string, IPool>
  poolMapRef: MutableRefObject<Record<string, IPool>>
  setPoolMap: Dispatch<SetStateAction<Record<string, IPool>>>
}

const initPoolContextValues = {
  loadingPoolMap: {},
  loadingWhirlpoolMap: {},
  poolMap: {},
  poolMapRef: { current: {} },
  setPoolMap: () => null,
}

const PoolContext = createContext<IPoolContext>(initPoolContextValues)

const PoolProvider = ({ children }: PropsWithChildren) => {
  const loadingPoolMap = useRef({} as IPoolContext['loadingPoolMap'])
  const loadingWhirlpoolMap = useRef({} as IPoolContext['loadingWhirlpoolMap'])
  const [poolMap, setPoolMap] = useState({} as IPoolContext['poolMap'])

  const poolMapRef = useRef(poolMap)
  useEffect(() => {
    Object.assign(poolMapRef.current, poolMap)
  }, [poolMap])

  // It is possible that poolMap might be ahead of its loading state. Thus, we
  // need to pass those changes to loadingPoolMap and loadingWhirlpoolMap.
  useEffect(() => {
    for (const [address, pool] of Object.entries(poolMap)) {
      if (!(address in loadingPoolMap.current)) {
        loadingPoolMap.current[address] = {
          resolver: Promise.resolve(pool.metadata),
          status: pool.type ? 'all' : 'none',
        }
      }

      if (!(address in loadingWhirlpoolMap.current)) {
        loadingWhirlpoolMap.current[address] = Promise.resolve(pool.whirlpool)
      }
    }
  }, [poolMap])

  return (
    <PoolContext.Provider
      value={{
        get loadingPoolMap() {
          return loadingPoolMap.current
        },
        get loadingWhirlpoolMap() {
          return loadingWhirlpoolMap.current
        },
        poolMap,
        poolMapRef,
        setPoolMap,
      }}
    >
      {children}
    </PoolContext.Provider>
  )
}

/**
 * Provides access to the pool database with `status` filter.
 *
 * @param status `all` for both `approved` and `pending`, or `none` to skip
 * loading metadata.
 */
export const usePools = <Status extends 'all' | 'approved' | 'pending' | 'none'>(
  status: Status,
) => {
  const { client } = useNemoProgram()

  const { loadingPoolMap, loadingWhirlpoolMap, poolMap, poolMapRef, setPoolMap } =
    useContext(PoolContext)
  const { isLoading, start } = useLoadingStateTracker()

  const filteredPoolMap = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(poolMap).filter(
          ([, pool]) =>
            status === 'none' ||
            (status === 'all' ? ['approved', 'pending'] : [status]).includes(pool.type || ''),
        ),
      ) as Record<string, IPool>,
    [poolMap, status],
  )
  const filteredPools = useMemo(() => Object.values(filteredPoolMap), [filteredPoolMap])

  const getPools = useCallback(
    (
      addresses: string[],
      { refreshMetadata = false, refreshWhirlpool = false } = {},
    ): IPoolResponse => {
      const endInstance = start()

      const poolAddresses = uniq(addresses)

      const whirlpoolsToBeLoaded = poolAddresses.filter(
        (address) =>
          refreshWhirlpool ||
          // Should load if the whirlpool has never been fetched.
          !(address in loadingWhirlpoolMap) ||
          // Should load if the pool has been fetched but its whirlpool does
          // not yet exist.
          (address in poolMapRef.current && !poolMapRef.current[address].whirlpool),
      )
      const whirlpools = client.getPools(whirlpoolsToBeLoaded, refreshWhirlpool)

      const metadatasToBeLoaded =
        status === 'none'
          ? []
          : poolAddresses.filter(
              (address) =>
                refreshMetadata ||
                // Should load if the metadata has never been fetched.
                !(address in loadingPoolMap) ||
                // Skip if previous loaded/loading pools share the same
                // status filter or the previous filter was `all`.
                !['all', status].includes(loadingPoolMap[address].status),
            )
      const metadatas =
        status === 'none'
          ? []
          : metadatasToBeLoaded
              // TODO: support searching multiple keywords at once in /pools
              .map((poolAddress) => searchLiquidityPools(poolAddress, status))

      const loader = Promise.all([whirlpools, ...metadatas]).then(([whirlpools, ...metadatas]) => {
        const loadedPoolMap = {} as Record<string, IPool>

        for (const whirlpool of whirlpools) {
          loadedPoolMap[whirlpool.getAddress().toBase58()] = { whirlpool }
        }

        for (const apiPools of metadatas) {
          for (const apiPool of apiPools.pools) {
            loadedPoolMap[apiPool.pool_address] = merge(loadedPoolMap[apiPool.pool_address], {
              metadata: apiPool,
              type: apiPool.status,
            })
          }
        }

        if (Object.keys(loadedPoolMap).length) {
          setPoolMap((poolMap) => merge({}, poolMap, loadedPoolMap))
        }

        endInstance()

        return loadedPoolMap
      })

      for (const address of whirlpoolsToBeLoaded) {
        const current = loadingWhirlpoolMap[address]
        loadingWhirlpoolMap[address] = loader.then(
          (poolMap) => poolMap[address].whirlpool || current,
        )
      }

      for (const address of metadatasToBeLoaded) {
        const current = loadingPoolMap[address]
        loadingPoolMap[address] = {
          resolver: loader.then((poolMap) => poolMap[address].metadata || current?.resolver),
          // If the status query used for loading this pool was `all`, keep it.
          // This is because pools loaded with `all` need not be loaded again.
          //
          // If the current status query is `none` (meaning no pool metadata is
          // to be loaded), keep the old query.
          // This is because the current query does not load metadata, thus not
          // going to update anything related to it. Whatever was in the old
          // query is still in effect and shall be used.
          //
          // Otherwise, use the current query.
          status:
            current?.status === 'all'
              ? 'all'
              : status === 'none'
              ? current?.status || 'none'
              : status,
        }
      }

      if (!whirlpoolsToBeLoaded.length && !metadatasToBeLoaded.length) {
        endInstance()
      }

      return Object.fromEntries(
        poolAddresses.map((address) => [address, waitKeys(poolMapRef.current)[address]]),
      )
    },
    [client, loadingPoolMap, loadingWhirlpoolMap, poolMapRef, setPoolMap, start, status],
  )

  const getPool = useCallback(
    async (address: string, { refreshMetadata = false, refreshWhirlpool = false } = {}) =>
      getPools([address], { refreshMetadata, refreshWhirlpool })[address],
    [getPools],
  )

  useEffect(() => {
    // Prefetch these default pools!
    getPools(DEFAULT_POOL_ADDRESSES)
  }, [getPools])

  return {
    getPool,
    getPools,
    isLoading,
    poolMap: filteredPoolMap,
    pools: filteredPools,
  }
}

export default PoolProvider
