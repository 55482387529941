import type { ThemeOptions } from '@mui/material'

import type { CustomPaletteMode } from './types'

const typography = {
  fontFamily: '"Inter", sans-serif',
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1200,
  },
}

const components = {
  MuiContainer: {
    styleOverrides: {
      root: {
        '&.MuiContainer-maxWidthLg': {
          maxWidth: breakpoints.values.xl,
        },
      },
    },
  },
}

const settings = {
  light: {
    palette: {
      mode: 'light',
      primary: {
        main: '#E9E9EA',
      },
      background: {
        default: '#E9E9EA',
      },
      secondary: {
        main: '#12193D',
      },
    },
    typography: {
      color: 'black',
      ...typography,
    },
    breakpoints,
    components,
  },
  dark: {
    palette: {
      mode: 'dark',
      primary: {
        main: '#f4f5f4',
      },
      background: {
        default: '#112548',
      },
      secondary: {
        main: '#f4f5f4',
      },
      success: {
        main: '#55B964',
      },
    },
    typography: {
      color: 'white',
      ...typography,
    },
    breakpoints,
    components,
  },
  iw: {
    palette: {
      mode: 'light',
      primary: {
        main: '#f4f5f4',
      },
      background: {
        default: '#112548',
      },
      secondary: {
        main: '#f4f5f4',
      },
      success: {
        main: '#55B964',
      },
      warning: {
        main: '#FEC84B',
      },
      error: {
        main: '#E65552',
      },
    },
    typography: {
      color: 'black',
      ...typography,
    },
    breakpoints,
    components,
  },
}

const getThemeOptions = (mode: CustomPaletteMode) => settings[mode] as ThemeOptions

export default getThemeOptions
