import '@solana/wallet-adapter-react-ui/styles.css'

import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets'
import { Keypair } from '@solana/web3.js'
import bs58 from 'bs58'
import { PropsWithChildren,useMemo } from 'react'

import { CHAIN_CONSTANTS, isChainSolana } from '@/constants/index'
import { isRunningInCypress } from '@/utils/helpers'
import { E2EWalletAdapter } from '@/wallet/adapter'

if (isChainSolana()) {
  // @ts-ignore
  import('@solana/wallet-adapter-react-ui/styles.css')
}

const Provider = ({ children }: PropsWithChildren) => {
  const wallets = useMemo(() => {
    if (isRunningInCypress) {
      const e2eWalletPrivKey = window.Cypress.env('E2E_WALLET_PRIVATE_KEY')
      const config = e2eWalletPrivKey && {
        keypair: Keypair.fromSecretKey(bs58.decode(e2eWalletPrivKey)),
      }

      return [new E2EWalletAdapter(config)]
    }

    return [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
    ]
  }, [])

  return (
    <ConnectionProvider
      endpoint={CHAIN_CONSTANTS.rpc}
      config={{ wsEndpoint: CHAIN_CONSTANTS.wss }}
    >
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}

export default Provider
