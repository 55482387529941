import { BN } from '@project-serum/anchor'
import { ENV as RenecTokenListEnv } from '@renec-foundation/rpl-token-registry'
import axios from 'axios'

import { P2P_FIAT_CURRENCIES } from '@/constants/currency_to_countries'
import { REMITANO_URL } from '@/constants/index'
import { isFoundationToken } from '@/hooks/use-tokens'
import { CustomTokenInfo } from '@/utils/helpers'

import { api } from './'
import { chainParams } from './constants'
import { Kline } from './tickers'

export type TokensAPIParams = {
  keyword?: string
  coin_addresses?: string[]
  token_type?: 'core' | 'user'
  including_24h_vol?: boolean
  only_pool_related?: boolean
  including_chart_data?: boolean
  including_price?: boolean
  page?: number
  per?: number
  symbols?: string[]
  tags?: string[]
}

export type APITokenInfo = {
  id: number
  chain_name: 'renec'
  chain_id: 'mainnet' | 'testnet'
  owner_address: string
  coin_address: string
  token_name: string
  token_symbol: string
  display_symbol?: string
  token_type: 'core' | 'user'
  logo_url: string
  status: 'waiting' | 'pending' | 'approved' | 'success' | 'failed' | 'removed' | 'remove_failed'
  description: string
  website: string | null
  volume_24h: string
  decimals: number
  pools: Array<{
    pool_address: string
    token_a_mint_address: string
    token_b_mint_address: string
    fee: string
    total_liquidity: string
  }>
  metadata?: {
    total_supply?: number
    holders_count?: number
  }
  price?: {
    current_price?: number
    diff_7_days_percent?: number
    diff_24_hours_percent?: number
  }
  chart_data?: Array<Kline>
  tags?: Record<'en' | 'vi', string[]>
}

export const notify = async (payload: object) => {
  const { data } = await api.post('/helper/notify-order-updates', {
    ...payload,
    ...chainParams,
  })
  return data
}

export const fetchRemitanoRate = async () => {
  const { data } = await axios.get('https://price.renec.org/api/quotes')
  return {
    ...data,
    VND: new BN(1).divn(data.VND).toNumber(),
    REVND: data.VND as number,
    REUSD: data.REUSD as number,
    RENEC: data.RENEC as number,
    REETH: data.ETH as number,
    REBTC: data.BTC as number,
  }
}

export const toRegistryTokenInfo = (token: APITokenInfo, lang: 'en' | 'vi' = 'en') =>
  ({
    chainId:
      token.chain_id === 'mainnet' ? RenecTokenListEnv.MainnetBeta : RenecTokenListEnv.Testnet,
    address: token.coin_address,
    name: token.token_name,
    decimals: token.decimals,
    symbol: token.token_symbol,
    logoURI: token.logo_url,
    tags: token.tags?.[lang] ?? [],
    displaySymbol: isFoundationToken(token.coin_address) ? token.token_name : token.token_symbol,
    type: token.token_type,
  } as CustomTokenInfo)

export const fetchAllTokens = async (params: TokensAPIParams = {}) => {
  const { data } = await api.get('/tokens', {
    params: {
      ...params,
      statuses: ['success'],
      v: 'dummy1',
      ...chainParams,
    },
  })

  return data.items.map((token: APITokenInfo) => {
    token.display_symbol = isFoundationToken(token.coin_address)
      ? token.token_name
      : token.token_symbol
    return token
  }) as APITokenInfo[]
}

export const fetchTokenPrices = async (tokenAddresses: string[]) => {
  const { data } = await api.get('/tokens/price', {
    params: {
      coin_addresses: tokenAddresses,
      ...chainParams,
    },
  })
  return data
}

export const fetchPoolRelatedTokens = async (noFilter?: boolean) => {
  return fetchAllTokens(noFilter ? undefined : { only_pool_related: true })
}

export const fetchCoreTokens = async () => {
  return fetchAllTokens({ token_type: 'core', including_24h_vol: true })
}

export const fetchTopCoins = async (tags?: string[]) => {
  return fetchAllTokens({
    token_type: tags ? undefined : 'core',
    including_24h_vol: true,
    including_chart_data: true,
    including_price: true,
    tags,
  })
}

export const fetchGeoInfo = async () => {
  const { data } = await axios.get('/api/geo')
  return data
}

export const fetchUserHash = async (publicKey?: string) => {
  if (publicKey) {
    const { data } = await axios.get(`/api/user-hash?public_key=${publicKey}`)
    return data.user_hash
  }
}

export const fetchCountryInfo = async (params: { ip: string }) => {
  const { data } = await api.get('/geo/country_code', {
    params,
  })
  return data
}

type RemiAmmPoolStateResponse = {
  amm_pool_states: {
    [key: string]: {
      token0: string
      token1: string
      price: number
    }
  }
}

const includedFiats = P2P_FIAT_CURRENCIES.map((currency) => currency.toLowerCase())
export type FiatToPriceRecord = Record<string, number>
export const fetchRateFromRemi = async (): Promise<FiatToPriceRecord> => {
  const { data } = await api.get<RemiAmmPoolStateResponse>(`${REMITANO_URL}/api/v1/amm_pool_states`)
  const fiatPoolsToPriceMap: Record<string, number> = {}
  Object.values(data.amm_pool_states).forEach((pool) => {
    if (includedFiats.includes(pool.token1)) {
      fiatPoolsToPriceMap[pool.token1] = Number(pool.price)
    }
  })
  return fiatPoolsToPriceMap
}

export const fetchFiatRates = async () => {
  const { data } = await axios.get<Record<string, number>>('/api/fiat-rates')
  return data
}
