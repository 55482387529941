import { useMemo } from 'react'

import { FeatureFlagOption, ISettings } from '@/utils/apis/settings'
import { useWallet } from '@/wallet/adapter'

import { useSettings } from './use-settings'

const useFeatureFlag = (featureFlag: keyof ISettings) => {
  const { publicKey, connected } = useWallet()
  const { data: settings, isLoading } = useSettings()

  const enabled = useMemo(() => {
    if (!settings) return false

    const featureSetting = settings[featureFlag] as FeatureFlagOption | boolean
    const testWalletAddresses = settings.nemo_swap_dev_whitelisted_addresses

    if (!featureSetting || featureSetting === 'disabled')
      return false
    if (featureSetting === 'enabled' || featureSetting === true)
      return true

    return connected && publicKey && testWalletAddresses.includes(publicKey.toBase58())
  }, [settings, featureFlag, publicKey, connected])

  return { enabled: Boolean(enabled), isLoading }
}

export default useFeatureFlag
