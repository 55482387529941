import { MINIMUM_NATIVE_FUNDS } from '@/constants/native-funds'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const locale: { [key: string]: any } = {
  header: {
    trade: 'Chuyển đổi',
    liquidity: 'Thanh khoản',
    buy: 'Mua',
    sell: 'Bán',
    assets: 'Tài sản',
    transactions: 'Lịch sử giao dịch P2P',
    limit: 'Lệnh giới hạn',
    p2p_market: 'Chợ USDT',
  },
  limit: {
    use_market: 'Giá thị trường',
    order: 'Đặt lệnh',
    enter_rate: 'Nhập tỉ giá',
    filled_order_successfully: 'Lệnh đã thực hiện thành công!',
    filled_base_with_quote: (base: string, quote: string) => `Đã hoán đổi ${base} thành ${quote}`,
    placed_order_successfully: 'Lệnh đã được đặt!',
    placed_base_at_price: (side: string, base: string, price: string) => `${side} ${base} với giá ${price}`,
    canceled_order_successfully: 'Lệnh đã được huỷ!',
    canceled_base_at_price: (base: string, price: string) => `Đã huỷ ${base} với giá ${price}`,
    pay_with_rate: (isSell: boolean, tokenSymbol: string) => `${isSell ? 'Bán' : 'Mua'} ${tokenSymbol} với tỉ giá`,
    pay: 'Thanh toán',
    receive: 'Nhận được',
    rate: 'Tỉ giá',
    close: 'Đóng lệnh',
    amount: 'Số lượng',
    price: 'Tỉ giá',
    open_order: 'Lệnh đang mở',
    orderbook: 'Sổ đặt lệnh',
    my_balances: 'Số dư của tôi',
    connect_wallet_to_see_balance: 'Kết nối ví để xem số dư của bạn.',
    withdraw: 'Rút tiền về ví',
    show_chart_btn: 'Mở đồ thị',
    hide_chart_btn: 'Đóng đồ thị',
    minimum_amount_token: (amount: string, token: string) => `Tối thiểu ${amount} ${token}`,
  },
  home: {
    swap: 'Hoán đổi',
    fee: 'Phí',
    commission: 'Hoa hồng',
    minimum_received: 'Tối thiểu nhận được',
    minimum_received_help_text:
      'Dù giá của các token thay đổi do các giao dịch khác, bạn vẫn sẽ nhận được ít nhất số lượng này hoặc giao dịch sẽ bị hủy. Số lượng này được xác định bởi tỷ lệ trượt giá cho phép của bạn.',
    maximum_required: 'Tối đa phải trả',
    maximum_required_help_text:
      'Dù giá của các token thay đổi do các giao dịch khác, bạn vẫn sẽ chỉ trả tối đa số lượng này hoặc giao dịch sẽ bị hủy. Số lượng này được xác định bởi tỷ lệ trượt giá cho phép của bạn.',
    swap_completed: 'Hoán đổi hoàn tất!',
    swap_failed: 'Hoán đổi thất bại!',
    you_swapped: 'Bạn đã hoán đổi',
    for_approximately: 'thành xấp xỉ',
    eligible: 'Hợp lệ',
    view_on_renec_explorer: 'Xem trên Explorer',
    enter_amount: 'Nhập số lượng',
    insufficient_balance: 'Số dư không đủ',
    maximum: 'Tối đa',
    minimum: 'Tối thiểu',
    rate: 'Tỷ giá',
    price_impact: 'Tác động giá',
    low_liquidity_notice: 'Cặp giao dịch có thanh khoản thấp. Hãy thử lại với số lượng nhỏ hơn.',
    no_pool_notice: (tokenA: string, tokenB: string) => `Không tìm thấy thanh khoản cho cặp giao dịch ${tokenA}/${tokenB}.`,
    select_token: 'Chọn token',
    select_a_token: 'Chọn một token',
    trade: 'Mua Bán',
    you_pay: 'Bạn thanh toán',
    you_receive: 'Bạn nhận được',
    reduce_amount: 'Giảm số lượng',
    details: 'Chi tiết',
    best_route: 'Phương án tối ưu',
    percent_of_trade: 'Tỉ lệ',
    redirect_deposit_dialog: {
      title: (tokenSymbol: string, fiatSymbol: string) => `Mua bán ${tokenSymbol} tức thời với ${fiatSymbol}`,
      description: (tokenSymbol: string) => `Trải nghiệm phương thức nạp ${tokenSymbol} phi tập trung chỉ có trên mạng Renec.`,
      go_to_deposit: (tokenSymbol: string) => `Đi đến trang nạp ${tokenSymbol}`,
      later: 'Để sau',
    },
    swap_success_dialog: {
      title: 'Hoán đổi thành công',
      congratulations: 'Chúc mừng',
      description: (amountIn: string, amountOut: string) => `Bạn đã hoán đổi thành công ${amountIn} sang ${amountOut}`,
      withdraw_token_to_bank: (tokenSymbol: string) => `Rút ${tokenSymbol} về tài khoản ngân hàng`,
      later: 'Để sau',
    },
  },
  sell: {
    enter_order_limit: 'Nhập giới hạn lệnh',
    price_settings: 'Thiết lập tỉ giá',
    you_sell: 'Bạn bán',
    order_limit: 'Giới hạn đặt lệnh',
    trade_review: 'Thông tin giao dịch',
    set_price_amount: 'Thiết lập tỉ giá và số lượng',
    set_limit_create: 'Thiết lập giới hạn và đặt lệnh',
    bank_transfer: 'Chuyển khoản',
    title: 'Rút',
    you_withdraw: 'Bạn rút',
    sell_btn: 'Rút',
    sell_token_to_fiat: (tokenSymbol: string) => `Rút ${tokenSymbol} về ngân hàng`,
    add_your_payment_method: 'Thêm thông tin thanh toán của bạn',
    edit_your_payment_method: 'Sửa thông tin thanh toán của bạn',
    buyer_awaiting: 'Đang tìm người mua',
    seller_awaiting: 'Đang tìm người bán',
    payment_awaiting: 'Chờ thanh toán',
    paying: 'Đang thanh toán',
    you_sell_success: (amount: string, currency: string) => <>Bạn đã bán thành công <strong>{amount} {currency}</strong></>,
    remaining_amount: (amount: string, fiatCurrency: string) => <>Còn lại {amount} {fiatCurrency}</>,
    waiting_seller: 'Chờ người bán xác nhận',
    waiting_your_confirm: 'Chờ bạn xác nhận',
    in_dispute: 'Đang trong tranh chấp',
    seller_disputes: 'Người bán tranh chấp',
    buyer_disputes: 'Người mua tranh chấp',
    you_disputes: 'Bạn tranh chấp',
    canceled: 'Đã bị huỷ',
    in_release: 'Giao dịch hoàn tất',
    btn_release: 'Tôi đã nhận tiền',
    back: 'Quay lại',
    warning: 'Cảnh báo lừa đảo',
    cheater_warning: 'Cảnh báo lừa đảo',
    risk_of_losing_fund: 'Nguy cơ mất tiền',
    please_confirm: 'Vui lòng xác nhận',
    cheater_warning_confirm: (amount: string, currency: string, bankName: string, bankNumber: string) => (
      <>
        <div className="text-gray-200 iw:text-[#666]">Bạn sẽ mất tiền nếu giải phóng giao dịch này một cách bất cẩn. </div>
        <div>
          <span className="text-gray-200 iw:text-[#666]">Hãy </span>
          <strong>kiểm tra lại </strong>
          <span className="text-gray-200 iw:text-[#666]">là bạn đã nhận chuyển khoản </span>
          <strong>{amount} {currency}</strong>
          <span className="text-gray-200 iw:text-[#666]"> vào tài khoản </span>
          <strong>{bankName} - {bankNumber}</strong>
        </div>
      </>
    ),
    btn_cancel: 'Hủy',
    choose_a_method: 'Chọn phương thức thanh toán',
    you_dont_have_any_payment_methods: 'Bạn chưa có phương thức thanh toán nào.',
    delete_payment_method_title: 'Xoá phương thức thanh toán',
    delete_payment_method_confirmation: 'Bạn có chắc muốn xoá phương thức thanh toán này không?',
    bank_account: 'Chuyển khoản',
    phone_number: 'Số điện thoại',
    email: 'Email',
    bank_name: 'Tên ngân hàng',
    bank_account_number: 'Số tài khoản',
    bank_account_name: 'Tên chủ tài khoản',
    payment_method: 'Phương thức thanh toán',
    payment_with_bank_account: 'Tài khoản ngân hàng',
    form_validation: {
      required: (fieldName: string) => `${fieldName} là bắt buộc.`,
      should_not_contain_special_characters: (fieldName: string) =>
        `${fieldName} không được chứa ký tự đặc biệt.`,
    },
    save: 'Lưu',
    more_details: 'Thông tin thêm',
    trade_ref: 'Mã giao dịch',
    buyer_address: 'Địa chỉ người mua',
    buyer_label: 'Người mua',
    copied_buyer_address_snackbar: 'Đã copy địa chỉ người mua.',
    buyer_has_kyc: 'Người mua đã xác minh KYC với Remitano',
    request_arbitrator_dialog_title: 'Yêu cầu trọng tài',
    dispute_msg: {
      msg1: 'Trọng tài viên sẽ liên hệ với bạn và người mua qua Trò chuyện nội bộ hoặc Thông tin liên hệ.',
      msg2: 'Bạn sẽ nhận được tiền của mình khi trọng tài viên xác nhận rằng người mua chưa thực hiện thanh toán.',
      msg3: 'Phí trọng tài sẽ được khấu trừ vào tiền đặt cọc của người mua',
    },
    request_arbitration_btn: 'Yêu cầu trọng tài',
    i_can_wait_btn: 'Tôi có thể đợi',
    wait_for_buyer: (amountFormatted: string, bankNumber: string, bankName: string) => (
      <>
        Tôi đang chờ thanh toán số tiền{' '}
        <span className="font-bold text-white">{amountFormatted}</span> vào tài khoản{' '}
        <span className="font-bold text-white">{bankNumber}</span> tại{' '}
        <span className="font-bold text-white">{bankName}</span>.
      </>
    ),
    you_wait_for_buyer: (amountFormatted: string, bankNumber: string, bankName: string) => (
      <>
        Bạn đang chờ thanh toán số tiền{' '}
        <span className="font-bold text-white">{amountFormatted}</span> vào tài khoản{' '}
        <span className="font-bold text-white">{bankNumber}</span> tại{' '}
        <span className="font-bold text-white">{bankName}</span>.
      </>
    ),
    wait_for_buyer_short: (formattedAmount: string, fiatCurrency: string, transferNote: string) => (
      <>Số tiền <span className="font-bold text-white iw:text-[#000E0F]">{formattedAmount} {fiatCurrency}</span> nội dung <span className="font-bold text-white iw:text-[#000E0F]">{transferNote}</span>.</>
    ),
    your_withdrawal_is_divided: <>Lệnh rút của bạn sẽ được chia thành <span className="underline">các lệnh nhỏ</span></>,
    wait_for_payment_desc: (
      amountFormatted: string,
      bankNumber: string,
      bankName: string,
      transferNote: string,
    ) => (
      <>
        Người mua sẽ thanh toán <span className="font-bold text-white">{amountFormatted}</span> với
        nội dung{' '}
        <span className="font-bold text-white" data-testid="transfer-note-ref">
          {transferNote}
        </span>{' '}
        vào tài khoản <span className="font-bold text-white">{bankNumber}</span> tại{' '}
        <span className="font-bold text-white">{bankName}</span>.
      </>
    ),
    paid_and_uploaded_evidence: (
      amountFormatted: string,
      currency: string,
      transferNode: string,
    ) => <>Người mua đã thanh toán <strong className="text-white iw:text-[#000E0F]">{amountFormatted} {currency}</strong>, nội dung <strong className="text-white iw:text-[#000E0F]">{transferNode}</strong> và tải lên bằng chứng</>,
    arbitrator_request_description: 'Nếu chưa nhận được thanh toán, vui lòng nhấn Yêu cầu trọng tài để được hỗ trợ.',
    arbitrator_will_review: 'Trọng tài sẽ xem xét bằng chứng thanh toán (nếu có) để xử lý giao dịch',
    arbitrator_will_review_without_reason: 'Trọng tài hiện đang xem xét giao dịch',
    arbitrator_will_review_with_reason: (reason: string) => (
      <>Trọng tài đang xem xét giao dịch do <span className="font-semibold">"{reason}"</span>.</>
    ),
    penalty_warning: (bondTokenSymbol: string, bondIncreaseAmount: string) =>
      `Bỏ qua hoặc không xác nhận giao dịch trong thời gian quy định, ${bondIncreaseAmount} ${bondTokenSymbol} sẽ được cộng vào quảng cáo tiếp theo của bạn.`,
    do_not_trust_proof: "Đừng chỉ tin vào bằng chứng. Hãy kiểm tra tài khoản của bạn và nhấn 'Yêu cầu Trọng tài' để được hỗ trợ nếu bạn chưa nhận được thanh toán.",
    add_bank_account_btn_short: 'Thêm',
    add_bank_account_btn: 'Thêm ngân hàng',
    select_payment_method_label: 'Chọn phương thức thanh toán',
    edit_bank_account_tooltip: 'Sửa thông tin tài khoản',
    you_will_receive: 'Bạn sẽ nhận',
    into_bank_account: 'Vào tài khoản',
    bond_warning: (
      amountFormatted: string,
      tokenSymbol: string,
      minutes: number,
    ) => `Đã khoá ${amountFormatted} ${tokenSymbol} của người mua. Người mua sẽ mất tiền bond nếu có hành vi gian lận hoặc trì hoãn giao dịch quá ${minutes} phút.`,
    start_label: 'Bắt đầu',
    payment_label: 'Thanh toán',
    confirm_label: 'Xác nhận',
    released_label: 'Hoàn tất',
    amount_to_get: 'Nhận',
    receive_via: 'Qua',
    nemoswap_find_buyer: <><strong>Nemoswap</strong> đang tìm người mua.</>,
    nemoswap_find_seller: <><strong>Nemoswap</strong> đang tìm người bán.</>,
    check_payment_info_while_waiting: (onClick: () => void) => (
      <>
        Nhấn để kiểm tra{' '}
        <span onClick={onClick} className="underline cursor-pointer underline-offset-4">
          thông tin thanh toán
        </span>
      </>
    ),
    config_minimum_amount_to_sell: {
      title: 'Nhập số tiền tối thiểu để bán',
      total_amount: 'Tổng số tiền',
      minimum_rate: 'Tối thiểu',
    },
    rate_selector: {
      title: 'Lựa chọn rút',
      express: 'Siêu tốc',
      fast: 'Nhanh',
      regular: 'Tiêu chuẩn',
      minutes: (n: number) => `${n} phút`,
      help_dialog: {
        title: 'Tùy chọn rút',
        description_1: (tokenSymbol: string) => `Phí rút được trả cho người mua ${tokenSymbol}. Phí rút càng cao thì thời gian chờ đợi càng ngắn.`,
        join_telegram_group: (link: string) => (
          <>
            Tham gia <a href={link} target='_blank' rel='noreferrer'>{link}</a> để nhận thông báo mới nhất.
          </>
        ),
        i_understand: 'Tôi đã hiểu',
      },
    },
    or_swap_to_token: (tokenSymbol: string) => `Hoặc hoán đổi về ${tokenSymbol}`,
    unlock_proof: 'Xem biên lai',
    order_completed: 'Đã hoàn tất lệnh!',
    order_canceled: 'Đã huỷ lệnh!',
    trade_released_toast: {
      title: 'Đã xác nhận!',
      message: (tradeId: string) => `Giao dịch #${tradeId} đã được xác nhận thành công!`,
    },
  },
  offer: {
    click_icon_to_confirm_payment: 'Nhấn vào từng biểu tượng để xác nhận thanh toán',
    list_of_payers: 'Danh sách người thanh toán',
    successfully_sold: (formattedFiatAmount: string, fiatSymbol: string) => `Bạn đã bán thành công ${formattedFiatAmount} ${fiatSymbol}`,
    successfully_buy: (formattedFiatAmount: string, fiatSymbol: string) => `Bạn đã mua thành công ${formattedFiatAmount} ${fiatSymbol}`,
    list_of_trades: 'Danh sách thanh toán',
    show_details: 'Xem chi tiết',
    list_of_sellers: 'Danh sách người bán',
    confirm_buy_offer: (
      params: {
        formattedFiatAmount: string,
        fiatCurrency: string,
        formattedCryptoAmount: string,
        cryptoSymbol: string,
        price: string,
        bondAmount: string, bondSymbol: string,
      },
    ) => (
      <>
        <p>Bạn sẽ thanh toán <span className="text-white iw:text-[#000E0F] font-bold">{params.formattedFiatAmount}</span> {params.fiatCurrency} để mua <span className="text-white iw:text-[#000E0F] font-bold">{params.formattedCryptoAmount}</span> {params.cryptoSymbol} từ người bán</p>
        <hr className="border-[#2E3846] iw:border-[#DFF1FF] my-4"/>
        <div className="flex items-center justify-between mb-2">
          <p>Tỉ giá:</p>
          <p><span className="text-white iw:text-[#000E0F] font-bold">{params.price}</span> {params.fiatCurrency}/{params.cryptoSymbol}</p>
        </div>
        {params.bondAmount &&(
          <div className="flex items-center justify-between">
            <p>Tiền bond:</p>
            <p><span className="text-white iw:text-[#000E0F] font-bold">{params.bondAmount}</span> {params.bondSymbol}</p>
          </div>
        )}
      </>
    ),
    warning_buy_offer: (formattedFiatAmount: string, fiatSymbol: string) => (
      <>
        <p className="text-center">Bạn cần hoàn thành các lệnh nạp nhỏ hơn để tích luỹ tiền bond cho giao dịch này</p>
        <p className="mt-5 text-center">Yêu cầu tiền bond: ~<strong className="text-white iw:text-[#000E0F]">{formattedFiatAmount}</strong> {fiatSymbol}</p>
      </>
    ),
    buy_crypto_with: (fiatCurrency: string, fiatSymbol: string) => `Mua ${fiatSymbol} bằng ${fiatCurrency}`,
  },
  deposit: {
    buy_btn: 'Mua',
    sell_btn: 'Bán',
    bank_name: 'Tên ngân hàng',
    bank_account_number: 'Số tài khoản',
    bank_account_name: 'Tên tài khoản',
    transfer_note: 'Nội dung chuyển khoản',
    transfer_instructions: "Please transfer to the seller's account provided below",
    transfer_title: (fiatAmount: string, fiatCurrency: string) =>
      `Hãy thanh toán <span style='font-weight:600;color:white'>${fiatAmount}</span> ${fiatCurrency} đến tài khoản ngân hàng dưới đây.`,
    hide_bank_title: 'Ẩn tài khoản',
    show_bank_title: 'Hiện tài khoản',
    not_found_order: 'Không tồn tại giao dịch',
    scan_qr_code_to_complete_transaction:
      'Quét mã QR dưới đây để thực hiện chuyển khoản ngân hàng.',
    mark_as_paid: 'Tôi đã thanh toán',
    time_payment_message: (min: number) => `Thời gian thanh toán là ${min} phút`,
    wait_for_seller: 'Chờ người bán xác nhận',
    request_arbitrator_dialog_title: 'Yêu cầu trọng tài',
    request_arbitrator_btn: 'Yêu cầu trọng tài',
    cancel_trade: 'Hủy giao dịch',
    upload_payment_evidences: 'Tải lên bằng chứng thanh toán',
    upload_payment_evidences_desc:
      'Vui lòng tải lên bằng chứng thanh toán cho giao dịch này để chứng thực bạn đã thanh toán.',
    drag_n_drop_file_here: 'Lựa chọn hoặc kéo thả file vào đây',
    payment_evidence_file_types: 'JPG, JPEG or PNG, dung lượng file không vượt quá 10MB',
    upload_payment_evidence_error_message:
      '[Lỗi] Bằng chứng thanh toán phải có định dạng JPG, JPEG, PNG và nhỏ hơn 10MB',
    paid_description: (
      fiatAmount: string,
      fiatCurrency: string,
      transferNote: string,
      bankAccountNumber: string,
      bankName: string,
    ) =>
      `Tôi đã gửi <span class="font-semibold text-white iw:text-[#000E0F]">${fiatAmount}</span> ${fiatCurrency}
      với nội dung <span class="font-semibold text-white iw:text-[#000E0F]">${transferNote}</span>
      vào tài khoản <span class="font-semibold text-white iw:text-[#000E0F]">${bankAccountNumber}</span>
      tại <span class="font-semibold text-white iw:text-[#000E0F]">${bankName}</span>.
      `,
    proof_of_payment: 'Bằng chứng thanh toán',
    request_arbitrator_description: {
      text_1:
        'Trọng tài viên sẽ liên hệ với bạn và người mua qua Trò chuyện nội bộ hoặc Thông tin liên hệ.',
      text_2:
        'Bạn sẽ nhận được tiền của mình khi trọng tài viên xác nhận rằng người mua chưa thực hiện thanh toán.',
      text_3: 'Phí trọng tài sẽ được khấu trừ vào tiền đặt cọc của người mua',
    },
    request_arbitrator_description_buyer: (amount: string, currency: string, bankAccountNumber: string, bankName: string) =>
      <>Trọng tài sẽ xem xét Bằng chứng thanh toán cho việc chuyển <strong>{amount} {currency}</strong> vào tài khoản <strong>{bankAccountNumber}</strong> tại <strong>{bankName}</strong> và giải quyết tranh chấp.</>,
    request_arbitrator: 'Yêu cầu trọng tài',
    i_can_wait: 'Tôi có thể đợi',
    arbitrator_reviewing_description: {
      arbitrator_will_consider: 'Trọng tài sẽ xem xét ',
      bank_information: (
        bankAccountNumber: string,
        bankName: string,
        fiatAmount: number | string,
        fiatSymbol: string,
      ) =>
        ` số tiền <span style='font-weight:600;color:white'>${fiatAmount} ${fiatSymbol}</span>
        cho tài khoản <span style='font-weight:600;color:white'>${bankAccountNumber}</span>
        tại <span style='font-weight:600;color:white'>${bankName}</span> để giải quyết tranh chấp`,
    },
  },
  buy: {
    bond_fee_not_enough: {
      title: (bondSymbol: string) => `Không đủ ${bondSymbol}`,
    },
    confirm_matching_engine: (tradeQuantity: number, formattedAmount: string, tokenSymbol: string) =>
      `Hệ thống sẽ tạo ${tradeQuantity} lệnh nạp phù hợp với tổng giá trị là ${formattedAmount} ${tokenSymbol}.`,
  },
  common: {
    you: 'Bạn',
    today: 'Hôm nay',
    show_all: 'Hiển thị tất cả',
    to: 'thành',
    with: 'từ',
    limit: 'Giới hạn',
    error_code: 'Mã lỗi',
    max: 'Max',
    balance: 'Số dư',
    connecting: 'Đang kết nối',
    connect_wallet: 'Kết nối ví',
    copied: 'Đã copy',
    copy_address: 'Copy địa chỉ',
    disconnect: 'Ngắt kết nối',
    payment_info: 'Thông tin thanh toán',
    copy: 'Copy',
    buy: 'Mua',
    sell: 'Bán',
    you_buy: 'Bạn mua',
    buyer: 'Người mua',
    seller: 'Người bán',
    close: 'Đóng',
    token_picker_filter_by: 'Tìm kiếm theo tên, kí hiệu, hoặc địa chỉ token',
    token_picker_no_tokens_found: 'Không tìm thấy token nào',
    bond: 'Tiền Bond',
    token_picker_please_select: 'Hãy chọn một token...',
    copied_address_snackbar: 'Đã copy địa chỉ token.',
    copied_trade_ref_snackbar: 'Đã copy mã giao dịch.',
    failed: 'Lỗi!',
    something_wrong: 'Có lỗi xảy ra, vui lòng thử lại sau!',
    add: 'Thêm',
    delete: 'Xoá',
    update: 'Cập nhật',
    save: 'Lưu',
    cancel: 'Hủy',
    announce_rush_hour: 'Giờ vàng đến, Swap thôi!',
    confirm: 'Xác nhận',
    confirm_to_continue: 'Bấm xác nhận để tiếp tục!',
    contact_support_from_nemoswap: 'Liên hệ hỗ trợ qua Chat',
    contact_support_from_telegram: 'Hoặc qua Telegram',
    contact_support: 'Liên hệ hỗ trợ',
    back: 'Trở lại',
    payment_evidence_dialog: {
      zoom_in: 'Phóng to',
      unlock: 'Mở khoá',
      title: 'Bằng chứng thanh toán',
      close_btn: 'Đóng',
      no_evidence: 'Người mua chưa cập nhật bằng chứng thanh toán. Vui lòng kiểm tra lại sau.',
      upload_no: (no: number) => `Hình #${no}`,
    },
    powered_by_remitano: 'Được cung cấp bởi Remitano',
    deposit: 'Nạp',
    want_to_deposit: 'Tôi muốn nạp',
    withdraw: 'Rút',
    under_maintenance_title: 'Tạm thời không khả dụng',
    under_maintenance_description: 'Tính năng đang bảo trì. Vui lòng quay lại sau.',
    find_potential_offer_multiple: (formattedAmount: string, symbol: string) =>
      `Hiện tại, lệnh nạp tối đa hệ thống có thể hỗ trợ là ${formattedAmount} ${symbol}. Vui lòng thử lại với số lượng nhỏ hơn.`,
    find_potential_offer_minimum: (formattedAmount: string, symbol: string) =>
      `Hiện tại, lệnh nạp tối thiểu hệ thống có thể hỗ trợ là ${formattedAmount} ${symbol}. Vui lòng thử lại với số lượng lớn hơn.`,
    find_potential_offer: (formattedAmount: string, symbol: string) =>
      `Hiện tại, lệnh nạp phù hợp hệ thống có thể hỗ trợ là ${formattedAmount} ${symbol}. Vui lòng thử lại với số lượng tương ứng.`,
    download: 'Tải xuống',
    next: 'Tiếp theo',
    previous: 'Quay lại',
    enter_price: 'Nhập tỉ giá',
    min_amount: (amount: string, symbol: string) => `Lượng tối thiểu không nhỏ hơn ${amount} ${symbol}`,
    max_amount: (amount: string, symbol: string) => `Lượng tối đa không lớn hơn ${amount} ${symbol}`,
    price: 'Giá',
    available: 'Có sẵn',
    payment_method: 'Phương thức thanh toán',
    warning: 'Cảnh báo',
    please_confirm: 'Vui lòng xác nhận',
    later: 'Để sau',
    amount: 'Số tiền',
    btn_got_it: 'Tôi đã hiểu',
    unavailable: 'Chưa khả dụng',
    info: 'Thông tin',
    request_arbitrator_btn: {
      dispute: 'Tranh chấp',
      request_arbitrator: 'Yêu cầu trọng tài',
    },
    require_renec_title: `Không đủ ${MINIMUM_NATIVE_FUNDS.symbol}`,
    require_0_1_renec: `Yêu cầu ${MINIMUM_NATIVE_FUNDS.amount} ${MINIMUM_NATIVE_FUNDS.symbol}`,
    require_renec_description: `Bạn cần khoảng ${MINIMUM_NATIVE_FUNDS.amount} ${MINIMUM_NATIVE_FUNDS.symbol} để giao dịch P2P diễn ra thuận lợi.`,
    take_me_to_swap: 'Đưa tôi đến trang Swap',
    seller_payment_information_incorrect: 'Sai thông tin thanh toán',
    dont_want_to_buy: 'Người mua không muốn mua nữa',
    other: 'Lý do khác',
    tx_notice: {
      confirming: 'Transaction của bạn đang được xác nhận.',
      expired: 'Transaction hết hạn.',
      error_title: 'Đã có lỗi!',
      success_title: 'Hoàn tất!',
      error_message: 'Giao dịch của bạn đã xảy ra lỗi.',
      success_message: 'Giao dịch của bạn đã thành công.',
      unconfirmed_message: 'Giao dịch của bạn không thể được xác nhận.',
      what_should_i_do_now: 'Tôi nên làm gì?',
      errors: {
        '0x1': {
          common: [
            `Đảm bảo bạn có đủ ${MINIMUM_NATIVE_FUNDS.symbol} để trả phí nền tảng (nên có ít nhất ${MINIMUM_NATIVE_FUNDS.amount})`,
          ],
          create_buy_offer: [
            'Đảm bảo bạn có đủ token để trả phí bond khi tham gia mua',
          ],
          create_sell_offer: [
            'Đảm bảo bạn có đủ token để bán và trả phí bond (nếu cần)',
          ],
          create_trade: [
            'Đảm bảo bạn có đủ token để trả bond cho giao dịch',
          ],
          create_limit_order: [
            'Đảm bảo bạn có đủ token trước khi tạo lệnh giới hạn',
          ],
          create_pool: [
            'Đảm bảo bạn có đủ RENEC để trả phí tạo hồ thanh khoản (thường dao động từ 0.08-0.52)',
          ],
          create_position: [
            'Đảm bảo bạn có đủ cả 2 loại token trước khi tạo vị thế',
          ],
          swap: [
            'Đảm bảo bạn có đủ token trước khi giao dịch',
          ],
          make_asset_withdrawal: [
            'Đảm bảo bạn có đủ lượng token muốn rút',
          ],
        },
        'wallet_refused': {
          common: [
            'Đảm bảo bạn đã kết nối ví thành công và đã mở khoá ví',
            'Đảm bảo bạn đã thực hiện xác nhận giao dịch bằng ví đã kết nối',
          ],
        },
        unknown: {
          common: [
            'Kiểm tra kĩ thông tin giao dịch và thử lại',
            'Nếu vẫn xảy ra lỗi, hãy liên hệ đội ngũ hỗ trợ của chúng tôi kèm thông tin giao dịch và mã lỗi dưới đây',
          ],
        },
      },
    },
    missing_secured_payment_info: 'Không thể tìm thấy thông tin thanh toán được mã hoá.',
    select_a_fiat: 'Chọn loại tiền tệ',
    unknown_token: 'Token không xác định',
    warning_unknown_token_content: 'Token này chưa được Renec xác minh. Luôn kiểm tra kĩ địa chỉ của các token và nghiên cứu về chúng trước khi bạn thực hiện giao dịch.',
    i_understand: 'Tôi hiểu',
    connect_your_wallet_to_continue: 'Kết nối ví của bạn để tiếp tục',
    access_denied: 'Không có quyền truy cập!',
    you_are_not_authorized: 'Bạn không có quyền truy cập trang này.',
    back_to_home: 'Quay về trang chủ',
    seller_payment_info: (prop: { bankName: string, accountName: string, accountNumber: string, transferNote: string  }) => (
      <>
        <span>Thông tin thanh toán của người bán: </span>
        <strong className="text-white">{prop.bankName}</strong> - <strong className="text-white">{prop.accountNumber}</strong> - <strong className="text-white">{prop.accountName}</strong>.{' '}
        <span>Nội dung chuyển khoản: </span><strong className="text-white">#{prop.transferNote}</strong>
      </>
    ),
    upload_proof: 'Tải lên bằng chứng thanh toán',
    add_new: 'Tạo mới',
    select_fiat: 'Loại tiền tệ',
  },
  history: {
    no_pending_trade: 'Không có giao dịch nào đang chờ',
    no_pending_offer: 'Không có quảng cáo nào đang chờ',
    reminder_title: (count: number) => `Bạn có ${count} giao dịch chưa hoàn tất`,
    reminder_description: 'Xử lí các giao dịch đang chờ và mở khoá phí của bạn',
    reminder_continue_btn: 'Đi đến lịch sử giao dịch',
    reminder_later_btn: 'Để sau',
    refresh: 'Làm mới',
    pending: 'Đang chờ',
    available: 'Khả dụng',
    trades: 'Giao dịch',
    trades_history: 'Lịch sử giao dịch',
    p2p_offers: 'Quảng cáo P2P',
    p2p_withdraw_offer: 'P2P: Quảng cáo bán',
    buy_offer: 'Quảng cáo mua',
    you_sold: 'Bạn đã bán',
    you_bought: 'Bạn đã mua',
  },
  meta: {
    home: {
      title: 'NemoSwap | DEX trên RENEC Blockchain',
      description:
        'NemoSwap trên RENEC Blockchain giúp giao dịch crypto trở nên đơn giản. Bạn có thể giao dịch token trên NemoSwap một cách nhanh chóng, không tốn kém.',
      thumbnailUrl: '/thumb-share-vi.png',
    },
    instantwire: {
      title: 'Giao dịch USDT trên Solana | InstantWire - P2P Escrow an toàn',
      description:
        'Giao dịch USDT trên Solana an toàn với InstantWire. Trải nghiệm giao dịch P2P nhanh chóng và đáng tin cậy, đảm bảo các giao dịch an toàn và không rắc rối. Bắt đầu giao dịch ngay và hưởng lợi từ nền tảng thân thiện với người dùng và hỗ trợ khách hàng 24/7.',
      keywords: 'Mua bán USDT, mua bán USDT trên Solana, mua bán USDT với VND, mua bán P2P, mua bán tiền điện tử an toàn, InstantWire, nền tảng giao dịch Solana, sàn giao dịch tiền điện tử ngang hàng, giao dịch USDT nhanh chóng, giao dịch tiền điện tử an toàn',
      thumbnailUrl: '/thumb-share-iw-vi.png',
    },
  },
  liquidity: {
    no_positions_found: 'Không tìm thấy vị thế nào.',
    position: {
      status_above_range: 'Ngưng chạy',
      status_in_range: 'Đang chạy',
      status_below_range: 'Ngưng chạy',
      uncollected_fees: 'Tổng hoa hồng chưa nhận',
      close_position: 'Đóng vị thế',
      claim: 'Thu hoạch',
      commission: 'Hoa hồng',
      min_price: 'Giá tối thiểu',
      max_price: 'Giá tối đa',
      current_price: 'Giá hiện tại',
      unit: 'Đơn vị',
      withdraw_completed: 'Rút thành công!',
      withdraw_failed: 'Rút thất bại!',
      close_position_successfully: 'Đóng vị thế thành công!',
      close_position_failed: 'Đóng vị thế thất bại!',
    },
    create_pool_form: {
      header: 'Khởi tạo hồ thanh khoản',
      button_submit: 'Tạo mới',
      button_submitting: 'Đang khởi tạo...',
      initial_price: 'Giá ban đầu',
      fee_tier: 'Chọn mức phí cho hồ thanh khoản',
      success_message: 'Hồ thanh khoản đã được khởi tạo thành công và đang chờ phê duyệt.',
      error_message: 'Không thể khởi tạo hoặc yêu cầu phê duyệt hồ thanh khoản. Vui lòng thử lại.',
      message_dialog_button_close: 'Đóng',
      swap_prices: 'Hoán đổi giá',
    },
    bar: {
      pools: 'Hồ',
      positions: 'Vị thế',
    },
    pool: {
      total_liquidity: 'Tổng thanh khoản',
      volume_24h: 'Khối lượng 24h',
      deposit: 'Tham gia',
      fee_24h: 'Thu nhập 24h',
      reward: 'Thưởng',
      day: 'Ngày',
    },
    pool_search_placeholder: 'Tên token, địa chỉ token hoặc hồ thanh khoản',
    no_results_found: 'Không tìm thấy kết quả nào.',
    no_pools_found: 'Chưa có hồ thanh khoản nào.',
    open_position: {
      add_new_position: 'Tạo vị thế mới',
      deposit_ratio: 'Tỉ lệ',
      current_price: 'Giá hiện tại',
      min_price: 'Giá tối thiểu',
      max_price: 'Giá tối đa',
      price_range: 'Khoảng giá',
      change: 'Thay đổi',
      set_liquidity_dialog_title: 'Cài đặt giá thanh khoản',
      apply: 'Áp dụng',
      max_balance: 'Số dư',
      total: 'Tổng',
      revert_prices_icon: 'Hoán đổi giá',
      errors: {
        must_be_gt_min_price: 'Phải lớn hơn giá tối thiểu',
        must_be_lt_max_price: 'Phải nhỏ hơn giá tối đa',
        must_be_a_number: 'Phải là số hợp lệ',
      },
      open_position_successfully: 'Tạo vị thế thành công!',
      open_position_failed: 'Tạo vị thế thất bại!',
    },
  },
  quota: {
    copied_address_snackbar: 'Đã copy địa chỉ.',
    profile: 'Hồ sơ',
    level: 'Mức',
    kyc: 'Xác minh',
    now: 'ngay',
    unverified: 'Chưa xác minh',
  },
  core_tokens_list: {
    title: 'Tài sản của bạn',
    spot: 'Giao ngay',
    top_coins: 'Top Coins',
    open_chart: 'Mở biểu đồ',
    last_7_days: '7 ngày qua',
    description: 'Rút và nạp tiền điện tử dễ dàng chỉ bằng vài cú nhấp chuột!',
    table_headers: {
      token_name: 'Tên token',
      about: 'Mô tả',
      balance: 'Số dư',
      action: 'Thao tác',
      market_cap: 'Vốn hoá thị trường',
    },
    token_detail: {
      title: 'Thông tin token',
      volume_24h: 'Khối lượng 24h',
      link: 'Đường dẫn',
      website: 'Trang web',
      close: 'Đóng',
    },
    search_name_placeholder: 'Nhập tên token',
    trade: 'Giao dịch',
    bank: {
      withdraw: 'Về tài khoản ngân hàng',
      deposit: 'Từ ngân hàng',
      description: 'thông qua giao dịch P2P',
    },
    network: {
      withdraw: 'Về ví khác',
      deposit: 'Từ một ví khác',
      description: 'thông qua mạng lưới blockchain',
    },
    swap_with_others: 'Mua bán với token khác',
    others: {
      withdraw: 'Về tài khoản ngân hàng (cashout)',
      deposit: (tokenSymbol: string) => `Mua ${tokenSymbol} với reVND`,
      description: (tokenSymbol: string, isWithdraw: boolean) => `hoặc Swap ${tokenSymbol} ${isWithdraw ? 'sang' : 'với'} token bất kỳ`,
    },
  },
  arbitrators: {
    text: 'Trọng tài',
    enterArbitratorAddress: 'Nhập địa chỉ trọng tài',
    specialize: 'Chuyên môn',
    processed: 'Đã xử lý',
    processedTooltip: 'Số lượng giao dịch đã xử lí',
    volume: 'Volume',
    volumeTooltip: 'Tổng khối lượng giao dịch đã xử lý',
  },
  no_data: 'Không có dữ liệu',
  terms_of_use: 'Điều khoản sử dụng',
  wrapped_token: {
    title: {
      you_have_token: (name: string) => `Bạn có ${name}?`,
      step_to_deposit: (name: string) => `Các bước nạp ${name}`,
      wait_to_confirm: 'Chờ mạng lưới xác nhận',
      wrapping_token: (name: string) => `Việc phát hành ${name}`,
    },
    you_can_trade: (name: string) => `Vui lòng kết nối Ví trước khi bắt đầu nạp ${name}`,
    connect_first: 'Trước tiên, bạn cần Kết nối Ví',
    where_to_transfer: (name: string) => `Bạn sẽ chuyển ${name} từ nơi nào tới?`,
    please_send: 'Hãy gửi',
    through_network: 'thông qua mạng lưới',
    select_network: 'Chọn Network',
    btn_deposited: (name: string) => `Tôi đã gửi ${name}`,
    to_address_below: 'tới địa chỉ dưới đây',
    please_wait_the_transaction: 'Vui lòng đợi trong quá trình mạng lưới xác nhận giao dịch của bạn.',
    learn_more: 'Tìm hiểu thêm',
    your_balance: 'Số dư',
    info_wrapped_token: (tokenSymbol: string, reTokenSymbol: string) => (
      `Khi gửi ${tokenSymbol} đến mạng lưới của RENEC, ${tokenSymbol} của bạn sẽ được phát hành bởi ${reTokenSymbol} theo tỷ lệ 1:1`
    ),
    info_unwrap_token: (tokenSymbol: string, reTokenSymbol: string) => (
      `Bạn có thể thực hiện hoàn trả ${reTokenSymbol} thành ${tokenSymbol} tại `
    ),
    btn_got_it: 'Tôi đã hiểu',
    btn_close: 'Đóng',
  },
  asset_withdrawal: {
    btn_close: 'Đóng',
    you_withdrew: 'Bạn đã rút',
    you_can_trade: (name: string) => `Bạn có thể giao dịch ${name} cùng NemoSwap.`,
    connect_first: 'Trước tiên, bạn cần Kết nối Ví',
    select_network: 'Chọn Network',
    through_network: 'thông qua mạng lưới',
    you_withdrawing: 'Bạn đang rút',
    withdrawing: (name: string) => `Rút ${name}`,
    withdraw_to_wallet_address: 'tới địa chỉ ví dưới đây',
    wallet_address: (tokenSymbol: string) => `địa chỉ ví ${tokenSymbol}`,
    withdraw_token: (tokenSymbol: string) => `Rút ${tokenSymbol}`,
    amount: 'số lượng',
    issues: {
      enter_wallet_address: 'Nhập địa chỉ ví',
      enter_amount: 'Nhập số lượng',
      no_token_info: 'Chọn token',
      insufficient_balance: 'Không đủ số dư',
    },
  },
  referral: {
    title: 'Đường dẫn giới thiệu',
    description: 'Nhận ngay 2 NSF khi bạn bè giao dịch tại NemoSwap',
  },
  p2p_market: {
    p2p_create: 'Tạo quảng cáo',
    order_info: 'Thông tin giao dịch',
    buy_and_sell_usdt: (chain: string) => `Mua và Bán USDT trên ${chain}`,
    zero_trading_fees: 'không phí giao dịch',
    buy: 'Mua',
    sell: 'Bán',
    enter_amount: 'Nhập số lượng',
    buyer: 'Người mua',
    seller: 'Người bán',
    price: 'Tỉ giá',
    available_and_offer_limit: 'Có sẵn | Giới hạn giao dịch',
    payment_method: 'Phương thức thanh toán',
    trade: 'Giao dịch',
    no_offers_found: 'Không có quảng cáo.',
    loading_offers: 'Đang tải quảng cáo...',
    avatar_you: 'Bạn',
    bank_transfer: 'Chuyển khoản',
    view: 'Xem',
    available: 'Có sẵn',
    limit: 'Giới hạn',
    buy_confirm_message: (
      fiatAmount: string,
      tokenAmount: string,
      fiatCurrency: string,
      tokenSymbol: string,
    ) => (
      <>
        <span className="text-gray-200 iw:text-[#666]">Bạn sẽ trả </span>
        <span className="font-bold text-white iw:text-[#000E0F]">{fiatAmount} {fiatCurrency}</span>
        <span className="text-gray-200  iw:text-[#666]"> để mua </span>
        <span className="font-bold text-white iw:text-[#000E0F]">{tokenAmount} {tokenSymbol}</span>
        <span className="text-gray-200  iw:text-[#666]"> từ người bán.</span>
      </>
    ),
    offer_ref: 'Mã giao dịch:',
    show_only_my_offers: 'Chỉ hiện quảng cáo của tôi',
    trade_detail: {
      dispute_after: 'Tranh chấp sau',
      you_will_receive: 'Bạn sẽ nhận',
      make_payment: 'Thanh toán',
      notify_seller: 'Thông báo người bán',
      notify_seller_description: 'Sau khi chuyển khoản nhấn vào nút "Tôi đã thanh toán, Thông báo cho người bán"',
      trade_info: 'Thông tin giao dịch',
      back_to_p2p_market: 'Quay lại Chợ P2P',
      pay_the_seller_within: 'Thanh toán cho người bán trong',
      wait_the_buyer_pay: 'Đợi người mua thanh toán',
      wait_the_seller_confirm: 'Chờ người bán xác nhận',
      time_created: 'Thời gian tạo',
      trade: 'Giao dịch',
      heading: {
        wait_seller_confirm_message: 'Đợi người bán xác nhận thanh toán và giải phóng coin',
        successfully_purchased: 'Đã mua thành công',
      },
      mark_as_paid: 'Tôi đã thanh toán',
      cancel_dialog: {
        title: 'Chọn lý do',
        description: 'Vui lòng cho chúng tôi biết bạn đang gặp khó khăn gì bên dưới',
        dont_want_to_buy_anymore: 'Tôi không muốn mua nữa',
        payment_information_incorrect: 'Thông tin thanh toán của người bán không chính xác',
        note: 'Nếu bạn hủy giao dịch, bạn sẽ không được hoàn lại tiền cọc.',
        other: 'Khác',
      },
      chat_dialog: {
        chat_with_seller: 'Chat với người bán',
        chat_with_buyer: 'Chat với người mua',
        enter_message: 'Nhập tin nhắn',
        chat_system_message_buyer_01: <>Vui lòng chụp ảnh <span className="font-semibold text-white iw:text-[#000E0F]">bằng chứng thanh toán</span> để xác minh với người bán.</>,
        chat_system_message_buyer_02: <>Sau khi chuyển tiền xong hãy bấm vào nút{' '}<span className="font-semibold text-white iw:text-[#000E0F]">"Tôi đã thanh toán"</span> bên dưới.</>,
      },
      show_message: 'Hiện tin nhắn',
      confirm_cancel_trade_dialog: {
        message: 'Xin vui lòng xác nhận rằng bạn chưa chuyển khoản cho người bán, nếu không bạn có thể mất số tiền đó.',
      },
      buyer_info: 'Thông tin người mua',
      confirm_for_buyer: 'Xác nhận cho người mua',
      confirm_for_buyer_message: "Nếu bạn chưa nhận được thanh toán, vui lòng nhấn 'Yêu cầu Trọng tài viên' để được hỗ trợ",
      buyer_will_pay_content: (fiatAmount: string, fiatCurrency: string, transferNote: string, bank_number: string, bank_name: string) => (
        <>
          <span>Người mua sẽ trả </span>
          <span className="font-bold text-white iw:text-[#000E0F]">{fiatAmount} {fiatCurrency}</span>
          <span> với nội dung </span>
          <span className="font-bold text-white iw:text-[#000E0F]">#{transferNote}</span>
          <span> vào tài khoản của bạn </span>
          <span className="font-bold text-white iw:text-[#000E0F]">{bank_number}</span>
          <span> tại </span>
          <span className="font-bold text-white iw:text-[#000E0F]">{bank_name}</span>.
        </>
      ),
      buyer_has_paid_content: (fiatAmount: string, fiatCurrency: string, transferNote: string, bank_number: string, bank_name: string) => (
        <>
          <span>Người mua đã thanh toán </span>
          <span className="font-bold text-white iw:text-[#000E0F]">{fiatAmount} {fiatCurrency}</span>
          <span> với nội dung </span>
          <span className="font-bold text-white iw:text-[#000E0F]">#{transferNote}</span>
          <span> vào tài khoản của bạn </span>
          <span className="font-bold text-white iw:text-[#000E0F]">{bank_number}</span>
          <span> tại </span>
          <span className="font-bold text-white iw:text-[#000E0F]">{bank_name}</span>.
        </>
      ),
      i_need_help: 'Tôi cần giúp đỡ',
    },
    how_p2p_work: {
      title: 'How P2P work?',
      description: 'Unlock Possibilities: P2P Market offers limitless trading opportunities by connecting individuals directly, transcending traditional boundaries for seamless transactions.',
      items: [
        {
          title: 'Place Buy Order',
          description: 'Unlock Possibilities: P2P Market offers limitless trading opportunities by connecting individuals directly, transcending traditional boundaries for seamless transactions.',
        },
        {
          title: 'Pay the seller',
          description: 'Unlock Possibilities: P2P Market offers limitless trading opportunities by connecting individuals directly, transcending traditional boundaries for seamless transactions.',
        },
        {
          title: 'Receive the Crypto',
          description: 'Unlock Possibilities: P2P Market offers limitless trading opportunities by connecting individuals directly, transcending traditional boundaries for seamless transactions.',
        },
      ],
    },
    benefits_of_escrow: {
      title: 'Why should buy on Escrow?',
      description: 'Unlock Possibilities: P2P Market offers limitless trading opportunities by connecting individuals directly, transcending traditional boundaries for seamless transactions.',
      items: [
        {
          title: 'Built in Security',
          description: 'Built-in security features ensure peace of mind in the crypto market, safeguarding assets and transactions.',
        },
        {
          title: 'Flexible Payments Method',
          description: 'Enjoy flexible payment methods tailored to your preferences in the crypto market, enhancing convenience and accessibility.',
        },
        {
          title: 'Built in Security',
          description: 'Built-in security features ensure peace of mind in the crypto market, safeguarding assets and transactions.',
        },
        {
          title: 'Flexible Payments Method',
          description: 'Enjoy flexible payment methods tailored to your preferences in the crypto market, enhancing convenience and accessibility.',
        },
      ],
    },
  },
  p2p_offer_detail: {
    offer: 'Lệnh',
    time_created: 'Khởi tạo',
    offer_info: {
      title: 'Thông tin lệnh',
      available: 'Còn lại',
      pending: 'Đang chờ',
      price: 'Tỉ giá',
      see_detail: 'Chi tiết',
      sell: 'Bán',
      buy: 'Mua',
      to: 'ra',
      from: 'từ',
      you_sold: 'Đã bán',
      you_bought: 'Đã mua',
      total_received: 'Tổng nhận',
      limit: 'Giới hạn',
      payment_method: 'Chuyển đến',
      bond: 'Bond',
      total_paid: 'Tổng đã trả',
    },
    buyer_list: {
      buyer_list_title: 'Danh sách người mua',
      waiting_for_buyer_title: 'Đang chờ người mua',
      please_confirm_payment: 'Vui lòng xác nhận thanh toán cho các lệnh mua.',
      details: 'Xem chi tiết',
    },
    seller_list: {
      title: 'Danh sách người bán',
    },
    trade_detail_card: {
      buyer: 'Người mua',
      seller: 'Người bán',
      amount: 'Số tiền',
      message: 'lời nhắn',
      view: 'Xem',
    },
    new_price: 'Tỉ giá mới',
    update_price: 'Cập nhật tỉ giá',
    edit_offer: 'Chỉnh sửa',
    edit_price: 'Chỉnh sửa tỉ giá',
    cancel_offer: 'Huỷ Lệnh',
    cancelling: 'Đang huỷ...',
    offer_status_badge: {
      fulfilled: 'Đã hoàn tất',
      canceled: 'Đã huỷ',
      in_progress: 'Đang xử lý',
      buyer_awaiting: 'Đang chờ người mua',
      seller_awaiting: 'Đang chờ người bán',
      not_applicable: 'Không khả dụng',
    },
    market_price: (price: string) => <>Giá thị trường: <span className="font-semibold text-sm text-white iw:text-[#000E0F]">{price}</span></>,
    estimated_wait_time: (isFast: boolean) => (
      <>
        Thời gian chờ dự kiến: {
          isFast
            ? <span className="text-sm text-[#55B964] iw:text-[#00BD90]">Nhanh</span>
            : <span className="text-sm text-[#EB5757] iw:text-[#EB5757]">Chậm</span>
        }
      </>
    ),
    multiple_dispute_dialog: {
      heading: 'Tranh chấp nhiều giao dịch',
      message: (noTrades: number) => (
        <>
          Bạn đang có <span className="font-semibold text-white iw:text-[#000E0F]">{noTrades}</span>{' '}
          giao dịch có thể tranh chấp.
        </>
      ),
      dispute_all_btn: 'Tranh chấp tất cả',
    },
    multiple_dispute_message: 'Bạn có một số giao dịch có thể tranh chấp.',
    dispute_btn: 'Yêu cầu tranh chấp',
    dispute_success_toast_message: 'Yêu cầu tranh chấp nhiều giao dịch của bạn đã được gửi.',
  },
  p2p_leader_board_text: 'Bảng xếp hạng giao dịch hàng tuần',
  scan_to_download_wallet_app: 'Quét mã QR để tải ví Carax',
  telegram_subscribe: {
    subscribe: 'Đăng ký',
    message: 'Theo dõi Telegram Bot của chúng tôi để nhận những cập nhật và thông báo mới nhất.',
  },
  telegram_dialog: {
    unsubscribe: {
      title: 'Huỷ đăng kí Telegram',
      sub_title: 'Tắt thông báo',
      description: 'Bạn sẽ KHÔNG nhận được thông báo sau khi hủy đăng ký Bot Telegram.',
      confirm_btn: 'Hủy đăng ký',
    },
    subscribe: {
      title: 'Đăng ký Telegram',
      sub_title: 'Nhận thông báo',
      description: 'Liên kết ví này với Telegram để nhận thông báo về giao dịch P2P.',
      confirm_btn: 'Đăng ký',
    },
  },
  bond: {
    required: 'Yêu cầu tiền cọc',
    reason: (amount: string, tokenSymbol: string, date: string) =>
      `Bạn cần đóng cọc ${amount} ${tokenSymbol} cho giao dịch này vì lý do: không xác nhận giao dịch kịp thời vào ngày ${date}`,
    total: 'Tổng tiền cọc',
    reduces_after: 'Cọc giảm bớt sau ngày',
    need_for_bond: (amount: string, tokenSymbol: string) => `Cần ${amount} ${tokenSymbol} cho tiền cọc`,
  },
  leader_board: {
    title: 'Bảng xếp hạng',
    description: '20 địa chỉ ví có khối lượng giao dịch cao nhất sẽ được vinh danh và nhận thưởng. Tham gia giao dịch P2P để nhận những phần thưởng hấp dẫn!',
    footer_description: 'Bảng xếp hạng nhà giao dịch sẽ được làm mới hàng tuần và gửi đến ví của bạn vào tuần tiếp theo!',
    position: 'Vị trí',
    wallet: 'Ví',
    volume_buy: 'Khối lượng mua',
    volume_sell: 'Khối lượng bán',
    volume_total: 'Tổng khối lượng',
    select_currency: 'Chọn đơn vị',
    this_week: 'Tuần này',
    last_week: 'Tuần trước',
    up_to: 'Lên đến',
    reward: 'Phần thưởng',
    reward_value: 'Giá trị phần thưởng',
    reward_description: 'Giao dịch ngay để kiếm phần thưởng thú vị và nâng cao trải nghiệm giao dịch của bạn!',
    reward_card_desc_1: <>Top <span className="text-[#FF6701]">20 người đứng đầu</span> sẽ nhận được phần thưởng tương ứng mỗi tuần.</>,
    reward_card_desc_2: <>Tổng khối lượng giao dịch đạt <span className="text-[#FF6701]">100,000</span> USDT</>,
    reward_card_list_1: [
      <><span className="text-[#1F5F8D] font-semibold">100, 50, 10 USDT</span> cho Top 1, 2, 3</>,
      <><span className="text-[#1F5F8D] font-semibold">5 USDT</span> cho Top 20</>,
    ],
    reward_card_list_2: [
      <><span className="text-[#1F5F8D] font-semibold">100 USDT</span> cho mỗi ví</>,
      <><span className="text-[#1F5F8D] font-semibold">10%</span> của tổng khối lượng</>,
    ],
    reward_card_list_3: [
      <><span className="text-[#1F5F8D] font-semibold">100 USDT</span> cho mỗi ví</>,
      <><span className="text-[#1F5F8D] font-semibold">20%</span> của tổng khối lượng</>,
    ],
  },
}

export default locale
