import { BN } from '@project-serum/anchor'
import {
  type IOfferDataInfo,
  type ITradeDataInfo,
  OfferState,
  TradeSide,
  Version as DataVersion,
} from '@renec-foundation/escrow-sdk'
import { useCallback } from 'react'

import useEscrowContext from '@/hooks/use-escrow-ctx'

import useNemoWallet from './use-nemo-wallet'

const useFetchTradeOrders = () => {
  const { publicKey } = useNemoWallet()
  const { escrowRouter } = useEscrowContext()

  const fetchOffers = useCallback(
    async (params?: {
      predicateFn?: (tradeOrder: IOfferDataInfo) => boolean,
      side?: typeof TradeSide[keyof typeof TradeSide],
    }) => {
      const { predicateFn, side } = params || {}
      const offers = await escrowRouter.getOffersByStatus(
        OfferState.Awaiting,
        side,
      )

      return (offers || []).filter((offer: IOfferDataInfo | null) => {
        if (!offer) return false
        if (!predicateFn) return true
        return predicateFn(offer)
      }) as IOfferDataInfo[]
    },
    [escrowRouter],
  )

  const fetchMyOffers = useCallback(async () => {
    if (!publicKey) return []

    return escrowRouter.getOffersByMakerAddress(publicKey)
  }, [escrowRouter, publicKey])

  const fetchMyTrades = useCallback(
    async (
      side: keyof typeof TradeSide,
    ) => {
      if (!publicKey) return []

      return side === 'Sell'
        ? (await escrowRouter.getTradesBySellerAddress(publicKey)).filter(Boolean) as ITradeDataInfo[]
        : (await escrowRouter.getTradesByBuyerAddress(publicKey)).filter(Boolean) as ITradeDataInfo[]
    },
    [escrowRouter, publicKey],
  )

  const fetchOfferById = useCallback(async (offerId: number) => {
    try {
      const order = await escrowRouter.getOfferDataByID(
        new BN(offerId),
        DataVersion.V1,
      )

      return order
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
      return null
    }
  }, [escrowRouter])

  /**
   * Fetch trade by id
   * @Params `tradeId` Id of trade trade data
   * @returns `undefined` If setting or context didn't ready yet
   * @returns `null` If there is something went wrong while fetching trade trade client
   * @returns `ITradeDataInfo`
   */

  const fetchTradeOrderById = useCallback(async (tradeId: number) => {
    try {
      const trade = await escrowRouter.getTradeDataByID(
        new BN(tradeId),
        DataVersion.V1,
        true,
      )

      return trade
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
      return null
    }
  }, [escrowRouter])

  return { fetchOffers, fetchMyOffers, fetchMyTrades, fetchOfferById, fetchTradeOrderById }
}

export default useFetchTradeOrders
