import Decimal from 'decimal.js'
import isEqual from 'lodash/isEqual'
import uniqWith from 'lodash/uniqWith'
import { useCallback, useEffect, useMemo } from 'react'

import useCachedObject from './use-cached-object'

export type UsePaginationParams<T> = {
  queryKey: string
  loader: (page: number) => Promise<T[]>
  comparator?: (x: T, y: T) => boolean
  initialData?: T[]
  itemsPerPage?: number
}

const usePagination = <T>({
  queryKey,
  loader,
  comparator = isEqual,
  initialData = [],
  itemsPerPage = 10,
}: UsePaginationParams<T>) => {
  const {
    value: { items, hasMoreItems },
    setValue,
  } = useCachedObject({
    initialData: {} as Record<string, { items: T[]; hasMoreItems: boolean }>,
    key: queryKey,
    defaultValue: {
      items: initialData,
      hasMoreItems: true,
    },
  })

  const setItems = useCallback(
    (items: T[]) => {
      setValue({ items, hasMoreItems: true })
    },
    [setValue],
  )

  const loadMore = useCallback(
    async (itemCount = 0) => {
      itemCount = itemCount > 0 ? itemCount : items.length + itemsPerPage

      const loadedItems = [] as T[]

      const pageStart = new Decimal(items.length).div(itemsPerPage).floor()
      const pageEnd = new Decimal(itemCount).div(itemsPerPage).ceil()

      for (let page = pageStart; page.lt(pageEnd); page = page.add(1)) {
        const moreItems = await loader(page.add(1).toNumber())
        loadedItems.push(...moreItems)
      }

      setValue(({ items, hasMoreItems }) => {
        const mergedItems = uniqWith([...items, ...loadedItems], comparator)

        return {
          items: mergedItems,
          hasMoreItems:
            // if number of items is smaller than requested item count, the list is already final
            // if number of items is equal to requested item count,
            //   if no new item is loaded, we can't tell if the list is final or not
            //   if new items are loaded, the list is NOT final
            // if number of items is larger than requested item count, the list is NOT final
            mergedItems.length < itemCount
              ? false
              : mergedItems.length === itemCount
              ? loadedItems.length
                ? true
                : hasMoreItems
              : true,
        }
      })
    },
    [comparator, items, itemsPerPage, loader, setValue],
  )

  const isInitialLoading = useMemo(
    () => !items.length && hasMoreItems,
    [items.length, hasMoreItems],
  )

  useEffect(() => {
    if (isInitialLoading) {
      loadMore(itemsPerPage)
    }
  }, [isInitialLoading, itemsPerPage, loadMore])

  return { hasMoreItems, isInitialLoading, items, loadMore, setItems }
}

export default usePagination
