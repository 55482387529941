import { SvgProps } from './types'

export type SelectedTopRightRoundedProps = SvgProps & {
  mask?: string
}

const SelectedTopRightRounded = ({
  height = 24,
  width = 24,
  fill = '#00BD90',
  mask = 'white',
}: SelectedTopRightRoundedProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H16C20.4183 0 24 3.58172 24 8V24L0 0Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.1976 5.46967C19.4905 5.76256 19.4905 6.23744 19.1976 6.53033L15.531 10.197C15.2381 10.4899 14.7632 10.4899 14.4703 10.197L12.8037 8.53033C12.5108 8.23744 12.5108 7.76256 12.8037 7.46967C13.0965 7.17678 13.5714 7.17678 13.8643 7.46967L15.0007 8.60601L18.137 5.46967C18.4299 5.17678 18.9048 5.17678 19.1976 5.46967Z" fill={mask} />
    </svg>
  )
}

export default SelectedTopRightRounded
