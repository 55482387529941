import CheckIcon from '@mui/icons-material/Check'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Autocomplete, Box,FilterOptionsState,FormGroup, FormHelperText, InputBase, Stack } from '@mui/material'
import cls from 'classnames'
import { useCallback } from 'react'

import BaseDialog from '@/components/base/BaseDialog'
import Button from '@/components/base/Button'
import IconZelle from '@/components/base/IconZelle'
import { FiatBank, toFiatBank } from '@/hooks/use-fiat-banks'
import useTrans from '@/hooks/useTrans'

import { usePaymentInfo } from '..'
import { InfoDialogProps } from './types'
import { getInputExamplePlaceholder } from './utils'

const View = (props: InfoDialogProps) => {
  const {
    isDialogOpened,
    bank,
    setBank,
    errorBankName,
    bankAccountNumber,
    setBankAccountNumber,
    errorBankAccountNumber,
    bankAccountName,
    setBankAccountName,
    errorBankAccountName,
    handleSubmitForm,
    handleClose,
    onInputBlur,
    onInputFocus,
    dialogTitle,
    isZelle,
    isBankTransferring,
    onSelectZelle,
    onSelectBankToPayment,
    hasZelleTransfer,
    handleDelete,
    isAddingNew,
    isVndFiat,
  } = props
  const trans = useTrans()
  const { fiatSupportedBanks } = usePaymentInfo()

  const handleCompareBanks = useCallback(
    (bankA: FiatBank, bankB: FiatBank) => bankA.id === bankB.id,
    [],
  )

  const handleFilterBanks = useCallback(
    (options: FiatBank[], state: FilterOptionsState<FiatBank>) =>
      options.filter((option) =>
        option.displayName.toLowerCase().includes(state.inputValue.toLowerCase()),
      ),
    [],
  )

  return (
    <BaseDialog
      useDrawerOnMobile
      title={dialogTitle}
      body={
        <Stack data-testid="info-dialog" className="py-4 w-full max-w-full" spacing={2}>
          <FormGroup>
            <Stack spacing={3}>
              {hasZelleTransfer && (
                <Stack data-test="transfer-method">
                  <label
                    htmlFor="input-bank-account-number"
                    className="font-semibold text-white iw:text-[#666666] text-md"
                  >
                    <span>{trans.sell.payment_method}</span>
                  </label>
                  <Box className="grid grid-cols-2 gap-3 mt-3">
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      className={cls(
                        'py-4 rounded-lg border border-[#3A3F5D] bg-[#2A3748] iw:bg-white iw:border-[#EAEAEA] cursor-pointer relative font-semibold',
                        {
                          'border-[#55B964] iw:border-[#00BD90] iw:!bg-[#DFF1FF]':
                            isBankTransferring,
                          'border-[#3A3F5D]': !isBankTransferring,
                        },
                      )}
                      onClick={onSelectBankToPayment}
                      data-testid="select-bank-account"
                    >
                      <span
                        className={cls(
                          'absolute right-0 top-0 w-[29px] h-[29px] rounded-tr-md',
                          'border-t-0 border-l-0 border-r-[29px] border-b-[29px]',
                          'border-t-transparent border-l-transparent border-b-transparent border-r-[#55B964]',
                          {
                            hidden: isZelle,
                          },
                        )}
                      >
                        <CheckIcon className="text-xs absolute top-[2px] left-[14px] stroke-[3px] stroke-white" />
                      </span>
                      {trans.sell.payment_with_bank_account}
                    </Stack>

                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      className={cls(
                        'py-4 rounded-lg border border-[#3A3F5D] bg-[#2A3748] iw:bg-white iw:border-[#EAEAEA] cursor-pointer relative',
                        {
                          'border-[#55B964] iw:border-[#00BD90] iw:!bg-[#DFF1FF]': isZelle,
                          'border-[#3A3F5D]': !isZelle,
                        },
                      )}
                      onClick={onSelectZelle}
                      data-testid="select-zelle"
                    >
                      <span
                        className={cls(
                          'absolute right-0 top-0 w-[29px] h-[29px] rounded-tr-md',
                          'border-t-0 border-l-0 border-r-[29px] border-b-[29px]',
                          'border-t-transparent border-l-transparent border-b-transparent border-r-[#55B964]',
                          {
                            hidden: !isZelle,
                          },
                        )}
                      >
                        <CheckIcon className="text-xs absolute top-[2px] left-[14px] stroke-[3px] stroke-white" />
                      </span>
                      <IconZelle />
                    </Stack>
                  </Box>
                </Stack>
              )}
              {isBankTransferring && (
                <Autocomplete
                  options={fiatSupportedBanks}
                  value={bank}
                  inputValue={bank.displayName}
                  filterOptions={handleFilterBanks}
                  isOptionEqualToValue={handleCompareBanks}
                  fullWidth
                  disableClearable
                  freeSolo
                  forcePopupIcon
                  slotProps={{
                    popper: {
                      id: 'bank-name-autocomplete-popper',
                    },
                  }}
                  onChange={(_, value) => setBank(toFiatBank(value))}
                  renderInput={(params) => {
                    const { inputProps, InputProps, InputLabelProps, ...rest } = params
                    return (
                      <Stack spacing={1}>
                        <label
                          {...InputLabelProps}
                          htmlFor="input-bank-name"
                          className="font-semibold text-white iw:text-[#666666] text-md"
                        >
                          {trans.sell.bank_name}
                        </label>
                        <InputBase
                          {...InputProps}
                          {...rest}
                          data-testid="input-bank-name"
                          aria-describedby="input-bank-name-helper-text"
                          onBlur={onInputBlur}
                          onFocus={onInputFocus}
                          onChange={(e) => setBank(toFiatBank(e.target.value))}
                          placeholder={trans.sell.bank_name}
                          error={Number(errorBankName?.length) > 0}
                          ref={InputProps.ref}
                          className={
                            'bg-[#2A3748] iw:bg-white iw:border iw:border-solid iw:border-[#EAEAEA] rounded-lg'
                          }
                          inputProps={{
                            ...params.inputProps,
                            id: 'input-bank-name',
                            'aria-label': '0',
                            className: `py-2.5 px-3 text-white iw:text-[#000E0F] text-md font-regular ${InputProps.className}`,
                            min: 0,
                          }}
                        />
                        {errorBankName && (
                          <FormHelperText
                            id="input-bank-name-helper-text"
                            data-testid="input-bank-name-helper-text"
                            className="text-red-400"
                          >
                            {errorBankName}
                          </FormHelperText>
                        )}
                      </Stack>
                    )
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      data-is-suggested-bank-item
                      className={cls(
                        'px-6 py-3 text-sm cursor-pointer',
                        selected && 'bg-[#1D242F] iw:bg-[#DFF1FF]',
                        !selected &&
                          'hover:bg-[#1D242F] hover:bg-opacity-50 iw:hover:bg-[#F6FBFF] iw:hover:bg-opacity-100',
                      )}
                    >
                      <span className="font-semibold text-white iw:text-[#000E0F]">
                        {option.shortName}
                      </span>
                      {option.fullName && (
                        <span className="text-[#DDE0E5] iw:text-[#666] ml-1">
                          - {option.fullName}
                        </span>
                      )}
                    </li>
                  )}
                  ListboxProps={{
                    className: 'bg-none rounded-lg px-0 py-2',
                  }}
                  componentsProps={{
                    paper: {
                      className: 'bg-none bg-[#0C1727] iw:bg-white relative top-2',
                    },
                  }}
                />
              )}
              <Stack spacing={1}>
                <label
                  htmlFor="input-bank-account-number"
                  className="font-semibold text-white iw:text-[#666666] text-md"
                >
                  <span>
                    {isBankTransferring ? trans.sell.bank_account_number : trans.sell.phone_number}
                  </span>
                </label>
                <InputBase
                  id="input-bank-account-number"
                  data-testid="input-bank-account-number"
                  aria-describedby="input-bank-account-number-helper-text"
                  placeholder={getInputExamplePlaceholder('bankAccountNumber', isZelle, isVndFiat)}
                  value={bankAccountNumber}
                  onChange={(v) => setBankAccountNumber?.(v.target.value)}
                  onFocus={onInputFocus}
                  error={Number(errorBankAccountNumber?.length) > 0}
                  className="bg-[#2A3748] iw:bg-white iw:border iw:border-[#EAEAEA] rounded-lg"
                  fullWidth
                  inputProps={{
                    'aria-label': '0',
                    className: 'py-2.5 px-3 text-white iw:text-[#000E0F] text-md font-regular',
                    min: 0,
                  }}
                />
                {errorBankAccountNumber && (
                  <FormHelperText
                    id="input-bank-account-number-helper-text"
                    data-testid="input-bank-account-number-helper-text"
                    className="text-red-400"
                  >
                    {errorBankAccountNumber}
                  </FormHelperText>
                )}
              </Stack>
              <Stack spacing={1}>
                <label
                  htmlFor="input-bank-account-name"
                  className="font-semibold text-white iw:text-[#666666] text-md"
                >
                  <span>
                    {isBankTransferring ? trans.sell.bank_account_name : trans.sell.email}
                  </span>
                </label>
                <InputBase
                  id="input-bank-account-name"
                  data-testid="input-bank-account-name"
                  aria-describedby="input-bank-account-name-helper-text"
                  placeholder={getInputExamplePlaceholder('bankAccountName', isZelle, isVndFiat)}
                  value={bankAccountName}
                  onChange={(v) => setBankAccountName?.(v.target.value)}
                  onFocus={onInputFocus}
                  error={Number(errorBankAccountName?.length) > 0}
                  className="bg-[#2A3748] iw:bg-white iw:border iw:border-[#EAEAEA] rounded-lg"
                  fullWidth
                  inputProps={{
                    'aria-label': '0',
                    className: 'py-2.5 px-3 text-white iw:text-[#000E0F] text-md font-regular',
                    min: 0,
                  }}
                />
                {errorBankAccountName && (
                  <FormHelperText
                    id="input-bank-account-name-helper-text"
                    data-testid="input-bank-account-name-helper-text"
                    className="text-red-400"
                  >
                    {errorBankAccountName}
                  </FormHelperText>
                )}
              </Stack>
            </Stack>
          </FormGroup>
        </Stack>
      }
      isOpened={!!isDialogOpened}
      handleClose={handleClose}
      buttonDirection="row"
      buttons={
        isAddingNew
          ? [
              <Button
                key="submit"
                variant="primary"
                data-testid="btn-submit-edit-method-form"
                onClick={handleSubmitForm}
                className="w-full"
              >
                {trans.common.add}
              </Button>,
            ]
          : [
              <Button
                key="delete"
                variant="outlined"
                data-testid="btn-delete-method"
                onClick={handleDelete}
                className="iw:before:border-[#EB5757] iw:text-[#EB5757] w-2/5"
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <DeleteOutlineIcon fontSize="small" />
                  <span>{trans.common.delete}</span>
                </Stack>
              </Button>,
              <Button
                key="submit"
                variant="primary"
                data-testid="btn-submit-edit-method-form"
                onClick={handleSubmitForm}
                className="w-3/5"
              >
                {trans.common.update}
              </Button>,
            ]
      }
    />
  )
}

View.displayName = 'InfoDialogView'
export default View
