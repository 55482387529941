import * as amplitude from '@amplitude/analytics-browser'
import { useEffect,useMemo } from 'react'

import { AMPLITUDE_API_KEY, EVENT_NAMES } from '@/constants/amplitude'
import usePreviousValue from '@/hooks/use-previous-value'
import { useWallet } from '@/wallet/adapter'

const useAmplitude = () => {
  const { publicKey } = useWallet()
  const currentWalletAddress = useMemo(() => publicKey?.toBase58(), [publicKey])
  const prevWalletAddress = usePreviousValue(currentWalletAddress)

  useEffect(() => {
    if (!AMPLITUDE_API_KEY) {
      return
    }

    if (currentWalletAddress !== prevWalletAddress) {
      if (!currentWalletAddress) {
        amplitude.track(EVENT_NAMES.page_disconnect_wallet)
      }

      amplitude.reset()
    }

    amplitude.init(
      AMPLITUDE_API_KEY,
      currentWalletAddress,
      {
        logLevel: amplitude.Types.LogLevel.Warn,
        defaultTracking: {
          sessions: true,
          pageViews: true,
        },
      },
    )
  }, [currentWalletAddress, prevWalletAddress])

  if (!AMPLITUDE_API_KEY) {
    // without AMPLITUDE_API_KEY, imitate amplitude instance to avoid errors
    return { track: () => undefined }
  }

  return amplitude
}

export default useAmplitude

export { EVENT_NAMES } from '@/constants/amplitude'
