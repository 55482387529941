import {
  ENV as RenecTokenListEnv,
} from '@renec-foundation/rpl-token-registry'
import { NATIVE_MINT } from '@solana/spl-token'
import {
  useCallback,
  useMemo,
} from 'react'

import {
  CHAIN_CONSTANTS,
  isChainSolana,
  isMainnet,
} from '@/constants/index'
import { useFetchPoolRelatedTokenList } from '@/hooks/use-nemo-apis'
import { useSettings } from '@/hooks/use-settings'
import { APITokenInfo, toRegistryTokenInfo } from '@/utils/apis/helper'
import { CustomTokenInfo } from '@/utils/helpers'
import { IconName } from '@/utils/static-import-icon'
import { staticImportIcon } from '@/utils/static-import-icon'

import { mapApiTokenInfo } from './helpers'

export type SwapTokenInfo = CustomTokenInfo & {
  nickName?: string,
}

export type FiatTokenInfo = CustomTokenInfo & {
  fiatCurrency?: string,
  fiatIcon?: string,
}

export type PairInfo = [SwapTokenInfo | undefined, SwapTokenInfo | undefined]

export const DefaultAPITokenInfo: APITokenInfo = {
  id: 0,
  chain_name: 'renec',
  chain_id: isMainnet ? 'mainnet' : 'testnet',
  owner_address: '',
  coin_address: '',
  token_name: 'Unknown',
  token_symbol: 'Unknown',
  token_type: 'user',
  logo_url: 'https://icotar.com/initials/Not%20Available.svg',
  status: 'success',
  description: '',
  website: null,
  volume_24h: '0',
  decimals: isChainSolana() ? 6 : 9,
  pools: [],
  tags: { en: [], vi: [] },
}

export const DefaultTokenInfo: CustomTokenInfo = {
  chainId: RenecTokenListEnv.MainnetBeta,
  address: '',
  name: 'Unknown',
  decimals: isChainSolana() ? 6 : 9,
  symbol: 'Unknown',
  logoURI: 'https://icotar.com/initials/Not%20Available.svg',
  tags: [],
}

export const REUSD = 'reUSD'
export const REUSD_MINT_ADDRESS = CHAIN_CONSTANTS.usdtMintAddress

export const REVND = 'reVND'
export const REVND_MINT_ADDRESS = isMainnet ?
  '2kNzm2v6KR5dpzgavS2nssLV9RxogVP6py2S6doJEfuZ' :
  'DSodi59U9ZWRnVgP94VNnKamFybYpsqYj2iKL1jQF7Ag'

export const RENEC = 'RENEC'
export const RENEC_MINT_ADDRESS = NATIVE_MINT.toBase58()

export const RENGN_MINT_ADDRESS = isMainnet ?
  'BfSYryW6Q93iUKE4uNsUtAdxQT9uU4GSVg2si3outLk1' :
  'CHe7TGhNzxpqiW6pdVJ2H2Mw5t7yHXTNyCfzJ1WFR5Dw'

export const RE_MYR_MINT_ADDRESS = isMainnet ? '' : '9KnRz1XiM4EnPG7ziiYkhfsZJnv3MeyJ7nLU64JpLqx8'
export const RE_INR_MINT_ADDRESS = isMainnet ? '' : 'A5qAyJAJ96wXUigYvvGViDxHkWVKeHsTVnEzcmc4xC9A'

export const REBTC_MINT_ADDRESS = isMainnet ?
  'GwPQTMg3eMVpDTEE3daZDtGsBtNHBK3X47dbBJvXUzF4' :
  '3GyCTH5u2TgctJUs6vJTMJ51GcBEHo3WBNYfsJJ3Vw3v'
export const REETH_MINT_ADDRESS = isMainnet ?
  'GwGh3b7iNibT3gpGn6SwZA9xZme7Th4NZmuGVD75jpZL' :
  'HqNYxW2MztYF8mGp2f9XN6RTE7Xk4KY8nbxAmww6fdH2'

export const RE_FIAT_FIAT_MAP = {
  [REVND_MINT_ADDRESS]: {
    currency: 'VND',
    icon: IconName.FIAT_VND,
  },
  [REUSD_MINT_ADDRESS]: {
    currency: 'USD',
    icon: IconName.FIAT_USD,
  },
  [RENGN_MINT_ADDRESS]: {
    currency: 'NGN',
    icon: IconName.FIAT_NGN,
  },
  [RE_MYR_MINT_ADDRESS]: {
    currency: 'MYR',
    icon: IconName.FIAT_USD, // TODO: request designer for this fiat icon
  },
  [RE_INR_MINT_ADDRESS]: {
    currency: 'IDR',
    icon: IconName.FIAT_USD, // TODO: request designer for this fiat icon
  },
}

export const FOUNDATION_TOKEN_ADDRESSES = [
  REETH_MINT_ADDRESS,
  REBTC_MINT_ADDRESS,
  REUSD_MINT_ADDRESS,
  NATIVE_MINT.toBase58(),
]
export const isFoundationToken = (address: string) => FOUNDATION_TOKEN_ADDRESSES.includes(address)

export const useTokens = (noFilter?: boolean) => {
  const { data: apiTokenList, isLoading, isFetching } = useFetchPoolRelatedTokenList(noFilter)
  const { data: settingsData } = useSettings()

  const tokenList = useMemo(() => {
    return apiTokenList?.map(mapApiTokenInfo)
  }, [apiTokenList])

  const apiTokenListMap = useMemo(() => {
    return (tokenList ?? []).reduce((map, token) => {
      map.set(token.coin_address, token)
      return map
    }, new Map<string, APITokenInfo>())
  }, [tokenList])

  const tokenListMap = useMemo(() => {
    return [...apiTokenListMap].reduce((map, [address, token]) => {
      map.set(address, toRegistryTokenInfo(token))
      return map
    }, new Map<string, CustomTokenInfo>())
  }, [apiTokenListMap])

  const fiatTokensList = useMemo<FiatTokenInfo[]>(() => {
    return Array.from((settingsData?.off_ramp_fiat_tokens || []) as string[])
      .reduce<FiatTokenInfo[]>((acc, tokenMintAddress) => {
        const tokenInfo = tokenListMap.get(tokenMintAddress)
        if (tokenInfo) {
          const fiatInfo = RE_FIAT_FIAT_MAP[tokenInfo.address as keyof typeof RE_FIAT_FIAT_MAP]
          const isAlreadyAdded = acc.some(fiatTokenInfo => fiatTokenInfo.address === tokenInfo.address)
          if (!isAlreadyAdded) {
            acc.push({
              ...tokenInfo,
              fiatCurrency: fiatInfo.currency,
              fiatIcon: fiatInfo.icon,
            })
          }
        }
        return acc
      }, [])
  }, [tokenListMap, settingsData])

  const fiatTokensMap = useMemo(() => {
    const map = new Map<string, FiatTokenInfo>()
    fiatTokensList.forEach(fiatTokenInfo => {
      map.set(fiatTokenInfo.address, fiatTokenInfo)
    })
    return map
  }, [fiatTokensList])

  const getTokenInfo = useCallback((mintAddress: string) => {
    let tokenInfo = tokenListMap.get(mintAddress)

    if (!tokenInfo) {
      tokenInfo = {
        ...DefaultTokenInfo,
        address: mintAddress,
      }
    }
    return tokenInfo
  }, [tokenListMap])

  const getFiatInfo = useCallback((mintAddress: string) => {
    let fiatInfo = fiatTokensMap.get(mintAddress)
    if (!fiatInfo) {
      fiatInfo = {
        ...DefaultTokenInfo,
        address: mintAddress,
        fiatCurrency: 'UNK',
        fiatIcon: '',
      }
    }
    return fiatInfo
  }, [fiatTokensMap])

  const getAPITokenInfo = useCallback(
    (mintAddress: string) => {
      return apiTokenListMap.get(mintAddress) ?? { ...DefaultAPITokenInfo, coin_address: mintAddress }
    },
    [apiTokenListMap],
  )

  const [fiatCustomInfoMap, fiatCustomInfoList] = useMemo(() => {
    const map = new Map<string, FiatTokenInfo>()
    const list = fiatTokensList.map((fiatToken) => {
      const fiatCustomInfo: FiatTokenInfo = {
        ...fiatToken,
        logoURI: staticImportIcon(fiatToken?.fiatIcon || '')?.src,
        displaySymbol: fiatToken.fiatCurrency,
      }
      map.set(fiatToken.address, fiatCustomInfo)
      return fiatCustomInfo
    })
    return [map, list]
  }, [fiatTokensList])

  const getTokenInfoByCurrency = useCallback((currency: string) => {
    return fiatTokensList?.find(token => token.fiatCurrency?.toLowerCase() === currency.toLowerCase())
  }, [fiatTokensList])

  return useMemo(() => ({
    tokenList,
    tokenListMap,
    apiTokenListMap,
    getTokenInfo,
    getAPITokenInfo,
    renecTokenInfo: getTokenInfo(RENEC_MINT_ADDRESS),
    reUsdTokenInfo: getTokenInfo(REUSD_MINT_ADDRESS),
    reVndTokenInfo: getTokenInfo(REVND_MINT_ADDRESS),
    reNgnTokenInfo: getTokenInfo(RENGN_MINT_ADDRESS),
    isFetching,
    isLoading,
    fiatTokensList,
    fiatTokensMap,
    reVndFiatInfo: getFiatInfo(REVND_MINT_ADDRESS),
    reNgnFiatInfo: getFiatInfo(RENGN_MINT_ADDRESS),
    reUsdFiatInfo: getFiatInfo(REUSD_MINT_ADDRESS),
    fiatCustomInfoMap,
    fiatCustomInfoList,
    getTokenInfoByCurrency,
  }), [
    tokenList,
    tokenListMap,
    apiTokenListMap,
    getTokenInfo,
    getAPITokenInfo,
    getFiatInfo,
    isFetching,
    isLoading,
    fiatTokensList,
    fiatTokensMap,
    fiatCustomInfoMap,
    fiatCustomInfoList,
    getTokenInfoByCurrency,
  ])
}

export default useTokens
