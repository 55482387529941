import MuiButton from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import cls from 'classnames'
import { forwardRef, useMemo } from 'react'

import { ButtonBgColorCustom, ButtonProps, ButtonSize, ButtonVariant } from './types'

/**
 * Base UI Button Component in Nemoswap
 */
const Button = forwardRef((
  {
    children,
    disabled,
    loading,
    className,
    startIcon,
    endIcon,
    variant = 'primary',
    size = 'lg',
    bgColor,
    onClick,
    ...restProps
  }: ButtonProps,
  forwardedRef: React.ForwardedRef<HTMLButtonElement>,
) => {

  const mapClassesBySize = useMemo(() => {
    return {
      xl: { font: 'text-[16px] leading-[24px]', padding: 'py-[16px] px-[12px]' },
      lg: { font: 'text-[16px] leading-[24px]', padding: 'py-[12px] px-[12px]' },
      md: { font: 'text-[14px] leading-[20px]', padding: 'py-[10px] px-[12px]' },
      sm: { font: 'text-[12px] leading-[16px]', padding: 'py-[8px] px-[12px]' },
    } as Record<ButtonSize, { font: string, padding: string }>
  }, [])

  const bgColorCustom = useMemo(() => {
    if (!bgColor) return

    const mapObj = {
      error: 'bg-[#EB5757] hover:bg-[#EB5757] iw:hover:bg-[transparent] iw:hover:text-[#EB5757] iw:hover:before:border-[#EB5757] disabled:bg-[#EB5757]',
      success: 'bg-[#55B964] hover:bg-[#55B964] iw:hover:bg-[transparent] iw:hover:text-[#55B964] iw:hover:before:border-[#55B964] disabled:bg-[#55B964]',
    } as Record<ButtonBgColorCustom, string>

    return bgColor in mapObj ? mapObj[bgColor] : `bg-[${bgColor}] hover:bg-[${bgColor}] iw:hover:bg-[transparent] iw:hover:text-[${bgColor}] iw:hover:before:border-[${bgColor}] disabled:bg-[${bgColor}]`
  }, [bgColor])

  const mapClassesByVariant = useMemo(() => {
    return {
      primary: [
        bgColorCustom ? bgColorCustom : 'bg-gradient-btn iw:bg-gradient-blue-btn bg-no-repeat iw:bg-[#1F5F8D] iw:hover:bg-[#1F5F8D] iw:hover:bg-none',
        'text-white',
        'btn-with-border-absolute before:rounded-lg hover:before:border-white iw:hover:before:border-[#1F5F8D]',
        'disabled:text-white disabled:opacity-40',
      ].join(' '),
      secondary: [
        'bg-[#2E3846] hover:bg-[#2E3846] text-[#C2CBD9]',
        'iw:bg-[#DFF1FF] iw:hover:bg-[#DFF1FF] iw:text-[#1F5F8D]',
        'btn-with-border-absolute before:rounded-lg hover:before:border-[#C2CBD9] iw:hover:before:border-[#1F5F8D] hover:bg-[#2E3846]',
        'disabled:bg-[#1E2735] disabled:opacity-40',
        'iw:disabled:bg-[#EAEAEA] iw:disabled:text-[#999999]',
      ].join(' '),
      outlined: [
        'bg-transparent text-[#C2CBD9] iw:text-[#1F5F8D]',
        'btn-with-border-absolute before:rounded-lg before:border-[#61667E]',
        'iw:before:border-[#1F5F8D] iw:hover:bg-[#DFF1FF]',
        'disabled:opacity-40',
        'iw:disabled:before:border-[#C6C6C6] iw:disabled:text-[#999999]',
      ].join(' '),
      link: 'bg-transparent hover:bg-transparent text-[#C2CBD9] iw:text-[#1F5F8D]',
    } as Record<ButtonVariant, string>
  }, [bgColorCustom])

  const classesVariant = mapClassesByVariant[variant]
  const classesPaddingBySize = variant === 'link' ? 'p-0' : mapClassesBySize[size].padding
  const classesFontBySize = mapClassesBySize[size].font

  const classes = useMemo(() => {
    return cls(
      'relative normal-case rounded-lg font-semibold',
      classesVariant,
      classesFontBySize,
      classesPaddingBySize,
      className,
    )
  }, [classesVariant, classesFontBySize, classesPaddingBySize, className])

  return (
    <MuiButton
      ref={forwardedRef}
      {...restProps}
      disabled={loading || disabled}
      disableRipple={variant === 'link' || disabled}
      className={classes}
      onClick={loading ? undefined : onClick}
    >
      {loading && (
        <span className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
          <CircularProgress size="1em" className="block text-inherit" data-testid="btn-circular-progress" />
        </span>
      )}
      {startIcon}
      <span
        className={cls(
          'relative',
          loading && 'opacity-0',
          variant === 'link' && 'underline underline-offset-4',
        )}
      >
        {children}
      </span>
      {endIcon}
    </MuiButton>
  )
})

Button.displayName = 'BaseButton'
export default Button
