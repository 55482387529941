import axios from 'axios'

const baseUrl = process.env.NODE_ENV !== 'production' ? process.env.NEXT_PUBLIC_SITE_URL : process.env.NEXT_PUBLIC_BACKEND_URL
const baseURL_V1 = `${baseUrl}/api/v1`
const baseURL_V2 = `${baseUrl}/api/v2`

export const api = axios.create({
  baseURL: baseURL_V1,
  headers: {
    'Content-type': 'application/json',
  },
})

export const api_v2 = axios.create({
  baseURL: baseURL_V2,
  headers: {
    'Content-type': 'application/json',
  },
})
