import { type IntercomBootProps,useIntercom } from '@thanhvo102/react-use-intercom'
import { useEffect, useMemo,useState } from 'react'
import { useQuery } from 'react-query'

import useAmplitude from '@/hooks/use-amplitude'
import usePreviousValue from '@/hooks/use-previous-value'
import { fetchUserHash } from '@/utils/apis/helper'
import {
  detectElementMutated,
  onIntercomWidgetHidden,
  onIntercomWidgetShowed,
  removeIntercomActiveClass,
} from '@/utils/helpers'
import { useWallet } from '@/wallet/adapter'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any;
  }
}

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID || ''

const BootIntercom = () => {
  const { publicKey } = useWallet()
  const currentWalletAddress = useMemo(() => publicKey?.toBase58(), [publicKey])
  const prevWalletAddress = usePreviousValue(currentWalletAddress)
  const { boot, update, shutdown } = useIntercom()
  const [hasIntercom, setHasIntercom] = useState(false)
  const [booted, setBooted] = useState(false)
  const { data: computedUserHash } = useQuery(
    ['fetchUserHash', currentWalletAddress],
    () => fetchUserHash(currentWalletAddress),
    { refetchOnWindowFocus: false },
  )

  useAmplitude() // to track page view by default

  useEffect(() => {
    if (currentWalletAddress && computedUserHash && booted) {
      update({ userId: currentWalletAddress, userHash: computedUserHash })
    }
  }, [
    currentWalletAddress,
    computedUserHash,
    booted,
    update,
  ])

  useEffect(() => {
    if (currentWalletAddress !== prevWalletAddress) {
      setBooted(false)
      shutdown()
      setTimeout(() => {
        const bootProps: IntercomBootProps = {
          appId: INTERCOM_APP_ID,
          // @ts-ignore
          apiBase: `https://${INTERCOM_APP_ID}.intercom-messenger.com`,
        }
        if (computedUserHash) {
          bootProps.userId = currentWalletAddress
          bootProps.userHash = computedUserHash
        }
        boot(bootProps)
        setBooted(true)
      }, 400)
    }
  }, [
    computedUserHash,
    currentWalletAddress,
    prevWalletAddress,
    update,
    shutdown,
    boot,
  ])

  useEffect(() => {
    if (window.Intercom && !hasIntercom) {
      setHasIntercom(true)

      window.Intercom('onHide', onIntercomWidgetHidden)
      window.Intercom('onShow', onIntercomWidgetShowed)
    }

    detectElementMutated(document.querySelector('html'), removeIntercomActiveClass)
  }, [hasIntercom])

  return null
}

export default BootIntercom
