import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { fetchCountryInfo, fetchGeoInfo } from '@/utils/apis/helper'

import {
  RENGN_MINT_ADDRESS,
  REUSD_MINT_ADDRESS,
  REVND_MINT_ADDRESS,
} from './use-tokens'

const COUNTRY_CODE_TO_FIAT_TOKEN_ADDRESS: { [key: string]: string } = {
  'VN': REVND_MINT_ADDRESS,
  'NG': RENGN_MINT_ADDRESS,
  'US': REUSD_MINT_ADDRESS,
}

export const useCountry = (enabled: boolean) => {
  const { data: geoData } = useQuery(
    ['fetchGeoInfo'],
    async () => {
      const geoInfo = await fetchGeoInfo()
      const localIp = geoInfo?.info?.ip === '::1' ? '1.54.3.194' : geoInfo?.info?.ip
      if (localIp) {
        return await fetchCountryInfo({ ip: localIp })
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: enabled,
      cacheTime: Infinity,
    },
  )

  return useMemo(() => {
    const countryCode = geoData?.country_code || 'VN'
    const fiatTokenAddress = COUNTRY_CODE_TO_FIAT_TOKEN_ADDRESS[countryCode]

    return {
      countryCode,
      fiatTokenAddress,
    }
  }, [geoData])
}
