import { useCallback, useMemo, useState } from 'react'

import useSecuredPaymentInfoOfSeller from '@/hooks/use-secured-payment-info-of-seller'
import { SecuredPaymentInfo } from '@/model/bank-info'
import { ZELLE } from '@/utils/helpers'

import { usePaymentInfo } from '..'
import DialogConfirmDeletePaymentInfoForm from '../dialog-confirm-delete-form'
import DialogEditPaymentInfoForm from '../dialog-edit-form'
import View from './view'

const Container = () => {
  const {
    openPaymentInfoDialog,
    setOpenPaymentInfoDialog,
    fiatToken,
    fiatSupportedBanks,
  } = usePaymentInfo()
  const {
    allMethods,
    lastSelectedMethodIndex,
    addPaymentInfo,
    removePaymentInfoByIndex,
    replacePaymentInfoByIndex,
    selectPaymentInfoByIndex,
  } = useSecuredPaymentInfoOfSeller(fiatToken?.symbol)

  const [showEditDialog, setShowEditDialog] = useState(false)
  const [isAddingNew, setIsAddingNew] = useState(false)
  const [editingMethodIndex, setEditingMethodIndex] = useState<number>()
  const [editingPaymentInfo, setEditingPaymentInfo] = useState<SecuredPaymentInfo>()

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false)
  const [deletingMethodIndex, setDeletingMethodIndex] = useState<number>()

  const methods = useMemo(
    () =>
      allMethods.map((bank, idx) => ({
        isZelle: bank.rawBankName === ZELLE,
        bankName: bank.rawBankName,
        bankAccountNumber: bank.rawBankAccountNumber,
        bankAccountName: bank.rawBankAccountName,
        email: bank.rawBankAccountName,
        phone: bank.rawBankAccountNumber,
        selected: idx === lastSelectedMethodIndex,
      })),
    [allMethods, lastSelectedMethodIndex],
  )

  const handleCloseSelectForm = useCallback(() => {
    setOpenPaymentInfoDialog(false)
  }, [setOpenPaymentInfoDialog])

  const handleAddNewMethod = useCallback(() => {
    setShowEditDialog(true)
    setIsAddingNew(true)
    setEditingMethodIndex(undefined)
    setEditingPaymentInfo(undefined)
  }, [])

  const handleEditMethod = useCallback(
    (index: number) => {
      if (index < allMethods.length) {
        setShowEditDialog(true)
        setIsAddingNew(false)
        setEditingMethodIndex(index)
        setEditingPaymentInfo(allMethods[index])
      }
    },
    [allMethods],
  )

  const handleSelectMethod = useCallback(
    (index: number) => {
      selectPaymentInfoByIndex(index)
    },
    [selectPaymentInfoByIndex],
  )

  const handleCloseEditForm = useCallback(() => {
    setShowEditDialog(false)
    setEditingMethodIndex(undefined)
    setEditingPaymentInfo(undefined)
  }, [])

  const handleDeleteMethod = useCallback(() => {
    if (editingMethodIndex !== undefined) {
      setShowConfirmDeleteDialog(true)
      setDeletingMethodIndex(editingMethodIndex)
    }
  }, [editingMethodIndex])

  const handleSaveMethod = useCallback(
    (bankInfo: SecuredPaymentInfo) => {
      if (isAddingNew) {
        addPaymentInfo(bankInfo)
        handleCloseEditForm()
      }

      if (!isAddingNew && editingMethodIndex !== undefined) {
        replacePaymentInfoByIndex(editingMethodIndex, bankInfo)
        handleCloseEditForm()
      }
    },
    [addPaymentInfo, editingMethodIndex, handleCloseEditForm, isAddingNew, replacePaymentInfoByIndex],
  )

  const handleCancelDeleteMethod = useCallback(() => {
    setShowConfirmDeleteDialog(false)
    setDeletingMethodIndex(undefined)
  }, [])

  const handleConfirmDeleteMethod = useCallback(() => {
    if (deletingMethodIndex !== undefined) {
      removePaymentInfoByIndex(deletingMethodIndex)
      handleCloseEditForm()
      handleCancelDeleteMethod()
    }
  }, [deletingMethodIndex, handleCancelDeleteMethod, handleCloseEditForm, removePaymentInfoByIndex])

  return (
    <>
      <View
        methods={fiatToken ? methods : []}
        isOpened={openPaymentInfoDialog}
        handleAddNewMethod={handleAddNewMethod}
        handleEditMethod={handleEditMethod}
        handleClose={handleCloseSelectForm}
        handleSelectMethod={handleSelectMethod}
      />
      <DialogEditPaymentInfoForm
        editingPaymentInfo={editingPaymentInfo}
        fiatToken={fiatToken}
        isAddingNew={isAddingNew}
        isDialogOpened={showEditDialog}
        handleClose={handleCloseEditForm}
        handleDelete={handleDeleteMethod}
        handleSave={handleSaveMethod}
        fiatSupportedBanks={fiatSupportedBanks}
      />
      <DialogConfirmDeletePaymentInfoForm
        isOpened={showConfirmDeleteDialog}
        handleCancel={handleCancelDeleteMethod}
        handleConfirm={handleConfirmDeleteMethod}
      />
    </>
  )
}

export default Container
