import { AnchorProvider } from '@project-serum/anchor'
import {
  AccountFetcher,
  buildWhirlpoolClient,
  WhirlpoolClient,
  WhirlpoolContext,
} from '@renec-foundation/nemoswap-sdk'
import { PublicKey } from '@solana/web3.js'
import { createContext, PropsWithChildren, useContext, useMemo } from 'react'

import useNemoWallet from '@/hooks/use-nemo-wallet'
import { useSettings } from '@/hooks/use-settings'
import { DEFAULT_CONFIG_KEY, DEFAULT_PROGRAM_ID } from '@/utils/helpers'
import { useConnection } from '@/wallet/adapter'

export type NemoProgram = {
  client: WhirlpoolClient
  configKey: PublicKey
  programId: PublicKey
}

const NemoProgramContext = createContext({} as NemoProgram)

const NemoProgramProvider = ({ children }: PropsWithChildren) => {
  const { connection } = useConnection()
  const fetcher = useMemo(() => new AccountFetcher(connection), [connection])
  const { anchorWallet } = useNemoWallet()

  const { data: settingsData } = useSettings()

  const contextValue = useMemo(() => {
    const provider = new AnchorProvider(connection, anchorWallet, {})
    const programId = new PublicKey(settingsData?.pool_program_id || DEFAULT_PROGRAM_ID)
    const context = WhirlpoolContext.withProvider(provider, programId, fetcher)
    const client = buildWhirlpoolClient(context)
    const configKey = new PublicKey(settingsData?.config_key || DEFAULT_CONFIG_KEY)

    return {
      client,
      configKey,
      programId,
    }
  }, [anchorWallet, connection, fetcher, settingsData])

  return <NemoProgramContext.Provider value={contextValue}>{children}</NemoProgramContext.Provider>
}

export default NemoProgramProvider

export const useNemoProgram = () => useContext(NemoProgramContext)
