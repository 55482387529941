import { ResizeObserver } from '@juggle/resize-observer'
import useResizeObserverHook from 'use-resize-observer'

const useResizeObserver: typeof useResizeObserverHook = (...args) => {
  if (window && !window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver
  }
  return useResizeObserverHook(...args)
}

export default useResizeObserver
