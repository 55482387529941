import Box from '@mui/material/Box'
import { useContext } from 'react'

import BaseDialog from '@/components/base/BaseDialog'
import useTrans from '@/hooks/useTrans'

import TokenListContext from '../context'

const TokenPickerDialog = ({ children, isOpened, id }: HocProps & { isOpened: boolean, id?: string }) => {
  const { handleClose } = useContext(TokenListContext)
  const trans = useTrans()

  return (
    <BaseDialog
      data-testid="token-picker-dialog"
      isOpened={isOpened}
      handleClose={handleClose}
      title={trans.common.select_a_fiat}
      body={(
        <Box id={id}>
          {children}
        </Box>
      )}
      hideConfirmButton
      useDrawerOnMobile={true}
    />
  )
}

export default TokenPickerDialog
