import ClickAwayListener from '@mui/material/ClickAwayListener'
import { useContext, useMemo } from 'react'

import TokenPickerDialog from './components/TokenListDialog'
import TokenListPopper from './components/TokenListPopper'
import TokenListContext from './context'
import { type TokenListProps } from './types'
import View from './view'

const Container = ({ anchorEl, isShowSearch = true }: TokenListProps) => {
  const { handleClose, isShowDialog } = useContext(TokenListContext)

  const ViewListTokens = useMemo(() => {
    return (
      <ClickAwayListener onClickAway={handleClose}>
        <View isShowInputSearch={isShowSearch} />
      </ClickAwayListener>
    )
  }, [isShowSearch, handleClose])

  if (isShowDialog) {
    return (
      <TokenPickerDialog
        id={anchorEl ? 'token-picker' : undefined}
        isOpened={!!anchorEl}
      >
        {ViewListTokens}
      </TokenPickerDialog>
    )
  }

  return (
    <TokenListPopper
      id={anchorEl ? 'token-picker' : undefined}
      open={!!anchorEl}
      anchorEl={anchorEl}
      placement="bottom-start"
    >
      {ViewListTokens}
    </TokenListPopper>
  )
}

export default Container
