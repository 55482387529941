import Decimal from 'decimal.js'
import { createContext, useMemo } from 'react'

import { SWAP_RENEC_GAS_FEE } from '@/constants/index'
import { RplTokenBalances, useBalances } from '@/hooks/use-balances'

interface WalletBalancesContextValues {
  userBalances: RplTokenBalances
  isLoading: boolean
}

export const WalletBalancesContext = createContext({} as WalletBalancesContextValues)

const Provider = ({ children }: HocProps) => {
  const { balances, isLoading } = useBalances()
  const userBalances = useMemo(
    () =>
      ({
        ...balances,
        RENEC: Math.max(0, new Decimal(balances['RENEC'] || 0).sub(SWAP_RENEC_GAS_FEE).toNumber()),
      } as RplTokenBalances),
    [balances],
  )

  return (
    <WalletBalancesContext.Provider value={{ userBalances: userBalances, isLoading }}>
      {children}
    </WalletBalancesContext.Provider>
  )
}

export default Provider
