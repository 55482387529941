import '@/root/src/styles/global.scss'

import { CacheProvider, EmotionCache } from '@emotion/react'
import { ErrorBoundary, Provider } from '@rollbar/react'
import { IntercomProvider } from '@thanhvo102/react-use-intercom'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { SnackbarProvider } from 'notistack'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { isChainSolana } from '@/constants/index'
import { PendingTransactionsProvider } from '@/contexts/pending-transactions'
import { RoutingSpinnerProvider } from '@/contexts/routing-spinner'
import BootIntercom from '@/pages/_bootIntercom.page'
import CableProvider from '@/providers/cable'
import LanguageProvider from '@/providers/language'
import LiquidityChartProvider from '@/providers/liquidity-chart'
import NemoProgramProvider from '@/providers/nemo-program'
import SnackBar from '@/providers/notification'
import PaymentInfoProvider from '@/providers/payment-info'
import PoolProvider from '@/providers/pool'
import SignatureProvider from '@/providers/signature'
import ThemeProvider from '@/providers/theme'
import RenecWalletProvider from '@/providers/wallet/index'
import SolanaWalletProvider from '@/providers/wallet/solana'
import WalletBalancesProvider from '@/providers/wallet-balances'
import { createEmotionCache } from '@/utils/createEmotionCache'
import { isRunningInCypress,onIntercomWidgetHidden, onIntercomWidgetShowed } from '@/utils/helpers'
import { IsSsrMobileContext } from '@/utils/use-is-mobile'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache: EmotionCache
}

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID || ''

const rollbarConfig = {
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  autoInstrument: process.env.NODE_ENV === 'production',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NODE_ENV === 'production' ? 'production' : 'testenv',
  nodeSourceMaps: true,
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const queryClient = useMemo(() => new QueryClient(), [])

  const WalletProvider = useMemo(() => isChainSolana() ? SolanaWalletProvider : RenecWalletProvider, [])
  const { route } = useRouter()

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <CacheProvider value={emotionCache}>
          <Head>
            <title>Nemo Swap | A decentralized exchange from RENEC team</title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
            />
          </Head>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <IsSsrMobileContext.Provider value={pageProps.isSsrMobile}>
                <ThemeProvider>
                  { route === '/mini-app/bank-info/[fiatCurrency]' ? (
                    <Component {...pageProps} />
                  ) : (
                    <LanguageProvider>
                      <RoutingSpinnerProvider>
                        <WalletProvider>
                          <NemoProgramProvider>
                            <WalletBalancesProvider>
                              <SignatureProvider>
                                <PoolProvider>
                                  <IntercomProvider
                                    appId={INTERCOM_APP_ID}
                                    apiBase={`https://${INTERCOM_APP_ID}.intercom-messenger.com`}
                                    widgetUrl={'https://renec.org/intercom-shim.latest.js'}
                                    onHide={onIntercomWidgetHidden}
                                    onShow={onIntercomWidgetShowed}
                                    autoBoot
                                  >
                                    <BootIntercom />
                                    <SnackbarProvider maxSnack={3}>
                                      <PendingTransactionsProvider>
                                        <LiquidityChartProvider>
                                          <CableProvider>
                                            <PaymentInfoProvider>
                                              <Component {...pageProps} />
                                            </PaymentInfoProvider>
                                          </CableProvider>
                                        </LiquidityChartProvider>
                                      </PendingTransactionsProvider>
                                      {!isRunningInCypress && (
                                        <ReactQueryDevtools initialIsOpen={false} />
                                      )}
                                      <SnackBar />
                                    </SnackbarProvider>
                                  </IntercomProvider>
                                </PoolProvider>
                              </SignatureProvider>
                            </WalletBalancesProvider>
                          </NemoProgramProvider>
                        </WalletProvider>
                      </RoutingSpinnerProvider>
                    </LanguageProvider>
                  )}
                </ThemeProvider>
              </IsSsrMobileContext.Provider>
            </Hydrate>
          </QueryClientProvider>
        </CacheProvider>
      </ErrorBoundary>
    </Provider>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
}
