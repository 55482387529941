import { SwapTokenInfo } from '@/hooks/use-tokens'

import { api, api_v2 } from './'

export interface LiquidityPool {
  id: number
  pool_address: string
  status: 'approved' | 'pending'
  token_a_name: string
  token_a_symbol: string
  token_a_mint_address: string
  token_a_logo_url: string
  token_b_name: string
  token_b_symbol: string
  token_b_mint_address: string
  token_b_logo_url: string
  volumes_in_24h: number
  total_liquidity: number
  fee: number
}

export interface LiquidityPosition {
  id: number
  created_at: Date
  position_address: string
}

export interface LiquidityPoolByTokenPairInfo {
  pool: LiquidityPool,
  exist: boolean,
}

export interface LiquidityPoolsInfo {
  pools: LiquidityPool[],
  has_next_page: boolean,
}

export const fetchLiquidityPools = async (page?: number): Promise<LiquidityPoolsInfo> => {
  const { data } = await api_v2.get(`/pools?page=${page || ''}`)
  return data as LiquidityPoolsInfo
}

export const fetchLiquidityPoolByTokenPair = async (
  token_a_mint_address: string,
  token_b_mint_address: string,
): Promise<LiquidityPoolByTokenPairInfo> => {
  const { data } = await api_v2.get('/pools_by_tokens', {
    params: {
      token_a_mint_address,
      token_b_mint_address,
    },
  })
  return data as LiquidityPoolByTokenPairInfo
}

export const submitPoolAddressForReview = async (
  poolAddress: string,
  tokenA: SwapTokenInfo,
  tokenB: SwapTokenInfo,
  feePercentage: number,
) => {
  const { data } = await api.post('/pools', {
    pool_address: poolAddress,
    token_a_name: tokenA.name,
    token_a_symbol: tokenA.symbol,
    token_a_mint_address: tokenA.address,
    token_b_name: tokenB.name,
    token_b_symbol: tokenB.symbol,
    token_b_mint_address: tokenB.address,
    fee: feePercentage,
  })
  return data
}

export const searchLiquidityPools = async (
  keyword: string,
  status: 'all' | 'approved' | 'pending' = 'all',
  page?: number,
): Promise<LiquidityPoolsInfo> => {

  const { data } = await api_v2.get(`/pools?status=${status}&search=${keyword}&page=${page || ''}`)

  return data as LiquidityPoolsInfo
}

export const fetchMyLiquidityPositions = async (wallet_address?: string): Promise<LiquidityPosition[]> => {
  const { data } = await api.get(`/nemoswap/liquidity_positions?wallet_address=${wallet_address}`)
  return data as LiquidityPosition[]
}
