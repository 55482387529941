import { type PublicKey } from '@solana/web3.js'
import base58 from 'bs58'
import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { EncodedMessage, MessageToSign } from '@/constants/escrow'
import { useWallet } from '@/wallet/adapter'

type WithCachedSignatureCallback = <TConsumerResult,>(
  signatureConsumer: (
    signResult: {
      signature: string,
      message: string,
    },
    signAndRetry?: () => Promise<TConsumerResult | boolean | undefined>
  ) => Promise<TConsumerResult | boolean | undefined>
) => Promise<TConsumerResult | boolean | undefined>

type SignatureContextType =
  | {
      lastMessage: string
      lastSignature: string
      lastSignedAt: Date
      publicKey: null
      sign: null
      withCachedSignature: null
    }
  | {
      lastMessage: string
      lastSignature: string
      lastSignedAt: Date
      publicKey: PublicKey
      sign: (useEscrowConstants?: boolean) => Promise<{ message: string; signature: string }>
      withCachedSignature: WithCachedSignatureCallback
    }

const initialValue = {
  lastMessage: '',
  lastSignature: '',
  lastSignedAt: new Date(0),
  publicKey: null,
  sign: null,
  withCachedSignature: null,
}

const SignatureContext = createContext<SignatureContextType>(initialValue)

const SignatureProvider = ({ children }: PropsWithChildren) => {
  const { publicKey, signMessage } = useWallet()
  const signing = useRef<Promise<{ message: string; signature: string }> | null>(null)

  const [lastMessage, setLastMessage] = useState('')
  const [lastSignature, setLastSignature] = useState('')
  const [lastSignedAt, setLastSignedAt] = useState(new Date(0))

  const sign = useMemo(
    () =>
      publicKey && signMessage
        ? async (useEscrowConstants = false) => {
            // Avoid opening multiple signing windows
            if (signing.current) {
              return signing.current
            }

            return (signing.current = (async () => {
              const message = useEscrowConstants ? MessageToSign : new Date().getTime().toString()
              const encodedMessage = useEscrowConstants
                ? EncodedMessage
                : new TextEncoder().encode(message)
              const signature = base58.encode(await signMessage(encodedMessage))

              setLastMessage(message)
              setLastSignature(signature)
              setLastSignedAt(new Date())

              return { message, signature }
            })().finally(() => {
              signing.current = null
            }))
          }
        : null,
    [publicKey, signMessage],
  )

  const resetLastSignResult = useCallback(() => {
    setLastMessage('')
    setLastSignature('')
    setLastSignedAt(new Date(0))
  }, [])

  useEffect(() => {
    resetLastSignResult()
  }, [publicKey, resetLastSignResult])

  const withCachedSignature: WithCachedSignatureCallback = useCallback(async signatureConsumer => {
    if (!sign) return

    const hasCachedSignature = Boolean(lastSignature && lastMessage)
    const signAndRunConsumer = async () => {
      try {
        // normal signing flow without cached signature
        // no `retry` passed to avoid infinite loop
        return await signatureConsumer(await sign())
      } catch (err) {
        return err === 'user denied sign message' ? false : undefined
      }
    }

    if (!hasCachedSignature) {
      return await signAndRunConsumer()
    } else { // use cached signature and allow retrying
      const cachedSignature = { signature: lastSignature, message: lastMessage }

      return await signatureConsumer(cachedSignature, signAndRunConsumer)
    }
  }, [sign, lastSignature, lastMessage])

  return (
    <SignatureContext.Provider
      value={{ lastMessage, lastSignature, lastSignedAt, publicKey, sign, withCachedSignature } as SignatureContextType}
    >
      {children}
    </SignatureContext.Provider>
  )
}

export const useSignature = () => {
  return useContext(SignatureContext)
}

export default SignatureProvider
