import { CircularProgress } from '@mui/material'
import Autocomplete, { type AutocompleteCloseReason } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import classNames from 'classnames'
import { type ChangeEvent, forwardRef, memo, useContext } from 'react'

import useTrans from '@/hooks/useTrans'

import AutocompleteInputBase from './components/AutocompleteInputBase'
import AutocompleteListboxComponent from './components/AutocompleteListboxComponent'
import AutocompletePopperComponent from './components/AutocompletePopperComponent'
import TokenListContext from './context'

// eslint-disable-next-line react/display-name
const View = forwardRef((props: { isShowInputSearch: boolean }, ref) => {
  const { isShowInputSearch } = props
  const { isInitialLoading, tokens, handleClose, setFilterKeyword, isShowDialog } = useContext(TokenListContext)

  const trans = useTrans()

  return (
    <Box ref={ref} className={isShowDialog ? 'w-full h-full' : 'w-[320px] min-h-[362px]'}>
      <Autocomplete
        open
        ListboxComponent={AutocompleteListboxComponent}
        /** The props `renderInput` is required.
         *  MUI is using this field to generate some computed values and apply it to option-elements.
         *  We only can hide it by using CSS */
        renderInput={(params) => (
          <AutocompleteInputBase
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            placeholder={trans.common.token_picker_filter_by}
            required
            className={classNames(
              !isShowInputSearch && 'input-hidden',
              isShowDialog && 'px-0 pt-0',
            )}
          />
        )}
        onClose={(_event: ChangeEvent<object>, reason: AutocompleteCloseReason) => {
          if (reason === 'escape') {
            handleClose()
          }
        }}
        options={tokens}
        PopperComponent={AutocompletePopperComponent}
        ListboxProps={{
          style: {
            padding: isShowDialog ? '0 0 16px': '0 16px 16px',
            backgroundColor: isShowDialog ? 'transparent' : '#0A1320',
            overflow: 'hidden',
          },
        }}
        renderTags={() => null}
        noOptionsText={
          isInitialLoading ? <CircularProgress /> : trans.common.token_picker_no_tokens_found
        }
        getOptionLabel={(option) => option.address}
        clearOnBlur={false}
        filterOptions={(options) => options}
        onInputChange={(_, value) => setFilterKeyword(value)}
      />
    </Box>
  )
})

const memoView = memo(View)
memoView.displayName = 'TokenListView'
export default memoView
