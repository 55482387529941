import { type TokenInfo } from '@renec-foundation/rpl-token-registry'
import { createContext, type Dispatch, type SetStateAction } from 'react'

import { type SwapTokenInfo } from '@/hooks/use-tokens'

export interface ITokenListContext {
  filterKeyword: string
  setFilterKeyword: Dispatch<SetStateAction<string>>
  selectedToken: SwapTokenInfo | undefined
  tokens: TokenInfo[]
  nickName: string
  handleClose: () => void
  handleTokenSelect: (token: TokenInfo) => void
  hasMoreTokens: boolean
  loadMoreTokens: (uptoIndex?: number) => Promise<void>
  isInitialLoading: boolean
  isDisplayAddress: boolean
  isShowDialog: boolean
  availableRecentTokens: TokenInfo[],
}

const TokenListContextDefaultValue: ITokenListContext = {
  filterKeyword: '',
  setFilterKeyword: () => null,
  selectedToken: undefined,
  tokens: [],
  nickName: '',
  handleClose: () => null,
  handleTokenSelect: () => null,
  hasMoreTokens: false,
  loadMoreTokens: () => Promise.resolve(),
  isInitialLoading: false,
  isDisplayAddress: true,
  isShowDialog: false,
  availableRecentTokens: [],
}

const TokenListContext = createContext(TokenListContextDefaultValue)
export default TokenListContext
