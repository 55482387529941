import { useCallback, useRef, useState } from 'react'

/**
 * Tracks overall loading state among callbacks.
 *
 * ```ts
 * const { isLoading, start, track } = useLoadingStateTracker()
 *
 * const asyncFnWithStart = useCallback(async () => {
 *   const endFn = start()
 *   // ...
 *   endFn()
 * }, [start])
 *
 * useEffect(() => {
 *   // call the function normally
 *   asyncFnWithStart()
 * }, [asyncFnWithStart])
 *
 * const asyncFnWithoutStart = useCallback(async () => {
 *   // ...
 * }, [])
 *
 * useEffect(() => {
 *   // call the function with track to have it automatically wrapped between
 *   // start() and endFn().
 *   track(asyncFnWithStart)
 * }, [asyncFnWithStart, track])
 *
 * // now use isLoading to detect if any async function is running
 * useEffect(() => {
 *   if (isLoading) {
 *     // do something while a function is running
 *   } else {
 *     // do something while no function is running
 *   }
 * }, [isLoading])
 * ```
 */
const useLoadingStateTracker = () => {
  const instanceIdRef = useRef(0)
  const [instances, setInstances] = useState([] as number[])

  const start = useCallback(() => {
    const instanceId = instanceIdRef.current++
    setInstances((instances) => [...instances, instanceId])

    let ended = false
    return () => {
      if (!ended) {
        ended = true
        setInstances((instances) => instances.filter((instance) => instance !== instanceId))
      }
    }
  }, [])

  const track = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async <F extends (...args: any[]) => any>(
      fn: F,
      thisArg?: unknown,
      ...args: Parameters<F>
    ): Promise<Awaited<ReturnType<F>>> => {
      const endFn = start()
      return Promise.resolve(fn.apply(thisArg, args)).finally(endFn)
    },
    [start],
  )

  return { isLoading: !!instances.length, start, track }
}

export default useLoadingStateTracker
