import InputBase from '@mui/material/InputBase'
import { styled } from '@mui/material/styles'

const AutocompleteInputBase = styled(InputBase)(({ theme }) => ({
  padding: 16,
  width: '100%',
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === 'light' ? 'rgba(3, 102, 214, 0.3)' : 'rgb(12, 45, 107)'
      }`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
  '&.input-hidden': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '&.input-hidden input': {
    border: 0,
    visibility: 'hidden',
    height: 0,
    padding: 0,
    margin: 0,
    overflow: 'hidden',
  },
}))

AutocompleteInputBase.displayName = 'AutocompleteInputBase'
export default AutocompleteInputBase
