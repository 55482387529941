// first country code in the array is the representative country code, flag of which will be used for fiat currency
const CURRENCY_TO_COUNTRIES_MAPPER = {
  'JMD': ['jm'],
  'USD': ['us', 'ec', 'zw', 'bd', 'kh', 'global'],
  'SGD': ['sg'],
  'LKR': ['lk'],
  'PKR': ['pk'],
  'PHP': ['ph'],
  'PYG': ['py'],
  'NGN': ['ng'],
  'LBP': ['lb'],
  'EGP': ['eg'],
  'TTD': ['tt'],
  'XAF': ['cf', 'cm'],
  'BSD': ['bs'],
  'BHD': ['bh'],
  'AMD': ['am'],
  'AUD': ['au'],
  'EUR': ['eu', 'lt', 'be', 'it', 'cy', 'ee', 'ie', 'vu', 'pt', 'mt', 'fi', 'fr', 'sm', 'sk', 'es', 'si', 'lv', 'de', 'mc', 'lu', 'gr', 'at'],
  'LSL': ['ls'],
  'NIO': ['ni'],
  'PAB': ['pa'],
  'HRK': ['hr'],
  'KZT': ['kz'],
  'XOF': ['tg', 'bf', 'ml', 'bj', 'sn', 'ne'],
  'SZL': ['sz'],
  'JOD': ['jo'],
  'BYN': ['by'],
  'RON': ['ro'],
  'RSD': ['rs'],
  'KWD': ['kw'],
  'MDL': ['md'],
  'IQD': ['iq'],
  'GHS': ['gh'],
  'UYU': ['uy'],
  'ETB': ['et'],
  'KES': ['ke'],
  'AOA': ['ao'],
  'MRU': ['mr'],
  'MAD': ['ma'],
  'MGA': ['mg'],
  'SCR': ['sc'],
  'NOK': ['no'],
  'UZS': ['uz'],
  'NPR': ['np'],
  'TJS': ['tj'],
  'GBP': ['gb'],
  'MNT': ['mn'],
  'SEK': ['se'],
  'COP': ['co'],
  'SAR': ['sa'],
  'AED': ['ae'],
  'ZMW': ['zm'],
  'CLP': ['cl'],
  'AFN': ['af'],
  'OMR': ['om'],
  'JPY': ['jp'],
  'NAD': ['na'],
  'SLL': ['sl'],
  'GEL': ['ge'],
  'HKD': ['hk'],
  'NZD': ['nz'],
  'CRC': ['cr'],
  'SOS': ['so'],
  'CNY': ['cn'],
  'DOP': ['do'],
  'CZK': ['cz'],
  'TWD': ['tw'],
  'LYD': ['ly'],
  'BND': ['bn'],
  'LAK': ['la'],
  'BAM': ['ba'],
  'RUB': ['ru'],
  'MWK': ['mw'],
  'CFA': ['ci'],
  'GTQ': ['gt'],
  'TZS': ['tz'],
  'MMK': ['mm'],
  'INR': ['in'],
  'TRY': ['tr'],
  'VND': ['vn'],
  'MYR': ['my'],
  'UGX': ['ug'],
  'ZAR': ['za'],
  'BIF': ['bi'],
  'LRD': ['lr'],
  'ILS': ['il'],
  'THB': ['th'],
  'VES': ['ve'],
  'IRR': ['ir'],
  'PGK': ['pg'],
  'AZN': ['az'],
  'HTG': ['ht'],
  'PEN': ['pe'],
  'IDR': ['id'],
  'DZD': ['dz'],
  'BOB': ['bo'],
  'TND': ['tn'],
  'PLN': ['pl'],
  'BWP': ['bw'],
  'MXN': ['mx'],
  'QAR': ['qa'],
  'CAD': ['ca'],
  'BRL': ['br'],
  'RWF': ['rw'],
  'CDF': ['cd'],
  'UAH': ['ua'],
  'GMD': ['gm'],
  'DKK': ['dk'],
  'CHF': ['ch'],
  'KRW': ['kr'],
  'ARS': ['ar'],
}

export default CURRENCY_TO_COUNTRIES_MAPPER

export const P2P_FIAT_CURRENCIES = Object.keys(CURRENCY_TO_COUNTRIES_MAPPER)
