import { AnchorProvider } from '@project-serum/anchor'
import { EscrowContext, EscrowFetcher, EscrowRouter, PDA } from '@renec-foundation/escrow-sdk'
import { PublicKey } from '@solana/web3.js'
import { useMemo, useRef } from 'react'

import { DEFAULT_ESCROW_CONFIG_ID, DEFAULT_ESCROW_PROGRAM_ID } from '@/utils/helpers'
import { useConnection } from '@/wallet/adapter'

import useNemoWallet from './use-nemo-wallet'
import { useSettings } from './use-settings'

const useEscrowContext = () => {
  const { data: settingsData } = useSettings()
  const { anchorWallet } = useNemoWallet()
  const { connection } = useConnection()
  const connectionRef = useRef(connection)

  const programId = useMemo(
    () => settingsData?.off_ramp_program_id || DEFAULT_ESCROW_PROGRAM_ID,
    [settingsData],
  )
  const configId = useMemo(
    () => settingsData?.off_ramp_config_id || DEFAULT_ESCROW_CONFIG_ID,
    [settingsData],
  )

  const escrowRouter = useMemo(() => {
    return new EscrowRouter(connectionRef.current, new PublicKey(programId), configId)
  }, [connectionRef, programId, configId])

  const escrowFetcher = useMemo(() => {
    const pda = new PDA(new PublicKey(programId), configId, configId)
    return new EscrowFetcher(connectionRef.current, pda)
  }, [connectionRef, programId, configId])

  const context = useMemo(() => {
    const provider = new AnchorProvider(connectionRef.current, anchorWallet, {})
    return EscrowContext.withProvider(provider, new PublicKey(programId))
  }, [connectionRef, anchorWallet, programId])

  return {
    escrowRouter,
    context,
    escrowFetcher,
    programId,
    configId,
  }
}

export default useEscrowContext
