import { deleteCookie,getCookies } from 'cookies-next'

export const deleteCookiesByPrefix = (prefix: string) => {
  const cookies = getCookies()

  Object.keys(cookies).forEach((cookieName) => {
    if (cookieName.startsWith(prefix)) {
      deleteCookie(cookieName)
    }
  })
}
