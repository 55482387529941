import { MINIMUM_NATIVE_FUNDS } from '@/constants/native-funds'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const locale = {
  header: {
    trade: 'Swap',
    liquidity: 'Liquidity',
    buy: 'Buy',
    sell: 'Sell',
    assets: 'Assets',
    transactions: 'P2P History',
    limit: 'Limit order',
    p2p_market: 'USDT Market',
  },
  limit: {
    use_market: 'Use market',
    order: 'Order',
    enter_rate: 'Input rate',
    filled_order_successfully: 'Filled order successfully!',
    filled_base_with_quote: (base: string, quote: string) => `Filled ${base} with ${quote}`,
    placed_order_successfully: 'Placed order successfully!',
    placed_base_at_price: (side: string, base: string, price: string) => `${side} ${base} at the price of ${price}`,
    canceled_order_successfully: 'Canceled order successfully!',
    canceled_base_at_price: (base: string, price: string) => `Canceled ${base} at the price of ${price}`,
    pay_with_rate: (isSell: boolean, tokenSymbol: string) => `${isSell ? 'Sell' : 'Buy'} ${tokenSymbol} at rate`,
    pay: 'Pay',
    receive: 'Receive',
    rate: 'Rate',
    close: 'Close',
    amount: 'Amount',
    price: 'Price',
    open_order: 'Open Orders',
    orderbook: 'Orderbook',
    my_balances: 'My Balances',
    connect_wallet_to_see_balance: 'Connect your wallet to see your balances.',
    withdraw: 'Withdraw funds',
    show_chart_btn: 'Show chart',
    hide_chart_btn: 'Hide chart',
    minimum_amount_token: (amount: string, token: string) => `Minimum ${amount} ${token}`,
  },
  home: {
    swap: 'Swap',
    fee: 'Fee rate',
    commission: 'Commission',
    minimum_received: 'Minimum received',
    minimum_received_help_text:
      'Even if the price of the tokens shifts due to other trades, you will receive at least this amount or the transaction will revert. This amount is determined by your slippage tolerance.',
    maximum_required: 'Maximum required',
    maximum_required_help_text:
      'Even if the price of the tokens shifts due to other trades, you will spend at most this amount or the transaction will revert. This amount is determined by your slippage tolerance.',
    swap_completed: 'Swap completed!',
    swap_failed: 'Swap failed!',
    you_swapped: 'You swapped',
    for_approximately: 'for approximately',
    eligible: 'Eligible',
    view_on_renec_explorer: 'View on Explorer',
    enter_amount: 'Enter amount',
    insufficient_balance: 'Insufficient balance',
    maximum: 'Maximum',
    minimum: 'Minimum',
    rate: 'Rate',
    price_impact: 'Price impact',
    low_liquidity_notice: 'The trading pair has low liquidity. Please reduce the swapping amount.',
    no_pool_notice: (tokenA: string, tokenB: string) => `No liquidity was found for trading ${tokenA}/${tokenB}.`,
    select_token: 'Select token',
    select_a_token: 'Select a token',
    trade: 'Trade',
    you_pay: 'You pay',
    you_receive: 'You receive',
    reduce_amount: 'Reduce amount',
    details: 'Details',
    best_route: 'Best route',
    percent_of_trade: 'Ratio',
    redirect_deposit_dialog: {
      title: (tokenSymbol: string, fiatSymbol: string) => `Buy and sell ${tokenSymbol} instantly with ${fiatSymbol}`,
      description: (tokenSymbol: string) => `Deposit ${tokenSymbol} via decentralized P2P trading. Only available on Renec network.`,
      go_to_deposit: (tokenSymbol: string) => `Take me to Deposit ${tokenSymbol}`,
      later: 'Later',
    },
    swap_success_dialog: {
      title: 'Swap completed',
      congratulations: 'Congratulations',
      description: (amountIn: string, amountOut: string) => `You have successfully swapped ${amountIn} to ${amountOut}`,
      withdraw_token_to_bank: (tokenSymbol: string) => `Withdraw ${tokenSymbol} to bank account`,
      later: 'Later',
    },
  },
  sell: {
    enter_order_limit: 'Enter order limit',
    price_settings: 'Price Settings',
    you_sell: 'You sell',
    order_limit: 'Order limit',
    trade_review: 'Trade review',
    set_price_amount: 'Set price & amount',
    set_limit_create: 'Set limit & create',
    bank_transfer: 'Bank Transfer',
    title: 'Withdraw',
    you_withdraw: 'You withdraw',
    sell_btn: 'Withdraw',
    sell_token_to_fiat: (tokenSymbol: string) => `Withdraw ${tokenSymbol} to bank account`,
    add_your_payment_method: 'Add your payment method',
    edit_your_payment_method: 'Edit your payment method',
    buyer_awaiting: 'Buyer awaiting',
    seller_awaiting: 'Seller awaiting',
    payment_awaiting: 'Payment awaiting',
    paying: 'Payment awaiting',
    you_sell_success: (amount: string, currency: string) => <>You have successfully sold <strong>{amount} {currency}</strong></>,
    remaining_amount: (amount: string, fiatCurrency: string) => <>Remaining amount {amount} {fiatCurrency}</>,
    waiting_seller: 'Waiting for seller',
    waiting_your_confirm: 'Waiting for your confirmation',
    in_dispute: 'In dispute',
    seller_disputes: 'Seller dispute',
    buyer_disputes: 'Buyer dispute',
    you_disputes: 'You dispute',
    canceled: 'Canceled',
    in_release: 'Released',
    btn_release: 'I have received money',
    back: 'Back',
    warning: 'Warning',
    cheater_warning: 'Cheater warning',
    risk_of_losing_fund: 'Risk of losing fund',
    please_confirm: 'Please Confirm',
    cheater_warning_confirm: (amount: string, currency: string, bankName: string, bankNumber: string) => (
      <>
        <div className="text-gray-200 iw:text-[#666]">You will lose your fund if you release this trade carelessly. </div>
        <div>
          <span className="text-gray-200 iw:text-[#666]">Please </span>
          <strong>double-check </strong>
          <span className="text-gray-200 iw:text-[#666]">that you have received </span>
          <strong>{amount} {currency}</strong>
          <span className="text-gray-200 iw:text-[#666]"> into account </span>
          <strong>{bankName} - {bankNumber}</strong>
        </div>
      </>
    ),
    btn_cancel: 'Cancel',
    choose_a_method: 'Choose a method',
    you_dont_have_any_payment_methods: "You don't have any payment methods.",
    delete_payment_method_title: 'Delete Payment Method',
    delete_payment_method_confirmation: 'Are you sure to delete this payment method?',
    bank_account: 'Bank Account',
    phone_number: 'Phone number',
    email: 'Email',
    bank_name: 'Bank name',
    bank_account_number: 'Bank account number',
    bank_account_name: 'Bank account name',
    payment_method: 'Payment method',
    payment_with_bank_account: 'Bank account',
    form_validation: {
      required: (fieldName: string) => `${fieldName} is required.`,
      should_not_contain_special_characters: (fieldName: string) =>
        `${fieldName} should not contain special characters.`,
    },
    save: 'Save',
    more_details: 'More details',
    trade_ref: 'Trade ref',
    buyer_address: 'Buyer address',
    buyer_label: 'Buyer',
    copied_buyer_address_snackbar: 'Copied buyer address to clipboard.',
    buyer_has_kyc: 'Buyer has KYC with Remitano',
    request_arbitrator_dialog_title: 'Request arbitrator',
    dispute_msg: {
      msg1: 'Arbitrator will contact you and the buyer via internal Chat or Contact information.',
      msg2: 'You will receive your fund when arbitrator confirms that buyer has not yet made the payment.',
      msg3: "Arbitrator's fee will be deducted from Buyer's bond",
    },
    request_arbitration_btn: 'Request arbitration',
    i_can_wait_btn: 'I can wait',
    wait_for_buyer: (amountFormatted: string, bankNumber: string, bankName: string) => (
      <>
        I am awaiting the payment of <span className="font-bold text-white">{amountFormatted}</span>{' '}
        to be credited to my account number{' '}
        <span className="font-bold text-white">{bankNumber}</span> at{' '}
        <span className="font-bold text-white">{bankName}</span>.
      </>
    ),
    you_wait_for_buyer: (amountFormatted: string, bankNumber: string, bankName: string) => (
      <>
        You are awaiting the payment of <span className="font-bold text-white">{amountFormatted}</span>{' '}
        to be credited to my account number{' '}
        <span className="font-bold text-white">{bankNumber}</span> at{' '}
        <span className="font-bold text-white">{bankName}</span>.
      </>
    ),
    wait_for_buyer_short: (formattedAmount: string, fiatCurrency: string, transferNote: string) => (
      <>
        The payment of{' '}
        <span className="font-bold text-white iw:text-[#000E0F]">
          {formattedAmount} {fiatCurrency}
        </span>{' '}
        and the reference <span className="font-bold text-white iw:text-[#000E0F]">{transferNote}</span>.
      </>
    ),
    your_withdrawal_is_divided: (
      <>
        Your withdrawal order will be divided into <span className="underline">small orders</span>
      </>
    ),
    wait_for_payment_desc: (
      amountFormatted: string,
      bankNumber: string,
      bankName: string,
      transferNote: string,
    ) => (
      <>
        The buyer will make a payment of{' '}
        <span className="font-bold text-white">{amountFormatted}</span> with the reference{' '}
        <span className="font-bold text-white" data-testid="transfer-note-ref">
          {transferNote}
        </span>{' '}
        to the account number <span className="font-bold text-white">{bankNumber}</span> at{' '}
        <span className="font-bold text-white">{bankName}</span>.
      </>
    ),
    paid_and_uploaded_evidence: (
      amountFormatted: string,
      currency: string,
      transferNode: string,
    ) => <>Buyer has paid <strong className="text-white iw:text-[#000E0F]">{amountFormatted} {currency}</strong>, the reference <strong className="text-white iw:text-[#000E0F]">{transferNode}</strong> and uploaded proof of payment</>,
    arbitrator_request_description: 'If payment has not been received, please click Request Arbitration for assistance.',
    arbitrator_will_review: 'The arbitrator will review proof of payment (if any) to process the transaction',
    arbitrator_will_review_without_reason: 'The arbitrator is currently reviewing the trade',
    arbitrator_will_review_with_reason: (reason: string) => (
      <>The arbitrator is reviewing the trade due to <span className="font-semibold">"{reason}"</span>.</>
    ),
    penalty_warning: (bondTokenSymbol: string, bondIncreaseAmount: string) =>
      `Ignore or fail to release trade in time will add ${bondIncreaseAmount} ${bondTokenSymbol} to your bond for the next offer.`,
    do_not_trust_proof: "Do not trust the Proof only. Check your account and press 'Request Arbitrator' for assistance if you not received your payment.",
    add_bank_account_btn_short: 'Add',
    add_bank_account_btn: 'Add Payment Method',
    select_payment_method_label: 'Select Payment Method',
    edit_bank_account_tooltip: 'Edit bank account',
    you_will_receive: 'You will receive',
    into_bank_account: 'Into bank account',
    bond_warning: (
      amountFormatted: string,
      tokenSymbol: string,
      minutes: number,
    ) => `Locked the buyer's ${amountFormatted} ${tokenSymbol}. The buyer will lose the bond in case of fraud or transaction delay exceeding ${minutes} minute(s).`,
    start_label: 'Start',
    payment_label: 'Payment',
    confirm_label: 'Confirm',
    released_label: 'Released',
    amount_to_get: 'Receive',
    receive_via: 'Via',
    nemoswap_find_buyer: <><strong>Nemoswap</strong> is looking for a buyer.</>,
    nemoswap_find_seller: <><strong>Nemoswap</strong> is looking for a seller.</>,
    check_payment_info_while_waiting: (onClick: () => void) => (
      <>
        Click to check{' '}
        <span onClick={onClick} className="underline cursor-pointer underline-offset-4">
          payment information
        </span>
      </>
    ),
    config_minimum_amount_to_sell: {
      title: 'Enter minimum amount you want to sell',
      total_amount: 'Total amount',
      minimum_rate: 'Minimum',
    },
    rate_selector: {
      title: 'Options',
      express: 'Express',
      fast: 'Fast',
      regular: 'Regular',
      minutes: (n: number) => `${n} min${n !== 1 ? 's' : ''}`,
      help_dialog: {
        title: 'Withdrawal options',
        description_1: (tokenSymbol: string) => `The fee is paid to the buyer of ${tokenSymbol}. The higher the fee, the shorter the waiting time.`,
        join_telegram_group: (link: string) => (
          <>
            Join <a href={link} target='_blank' rel='noreferrer'>{link}</a> to receive the latest notifications.
          </>
        ),
        i_understand: 'I understand',
      },
    },
    or_swap_to_token: (tokenSymbol: string) => `Or swap to ${tokenSymbol}`,
    unlock_proof: 'Unlock proof',
    order_completed: 'Order Completed!',
    order_canceled: 'Order Canceled!',
    trade_released_toast: {
      title: 'Released!',
      message: (tradeId: string) => `The trade #${tradeId} was released successfully!`,
    },
  },
  offer: {
    click_icon_to_confirm_payment: 'Click on each icon to confirm payment',
    list_of_payers: 'List of payers',
    successfully_sold: (formattedFiatAmount: string, fiatSymbol: string) => `You have successfully sold ${formattedFiatAmount} ${fiatSymbol}`,
    successfully_buy: (formattedFiatAmount: string, fiatSymbol: string) => `You have successfully bought ${formattedFiatAmount} ${fiatSymbol}`,
    list_of_trades: 'List of Trades',
    show_details: 'Show details',
    list_of_sellers: 'List of Sellers',
    // TODO: resolve the warning of "validateDOMNesting(...): <p> cannot appear as a descendant of <p>."
    confirm_buy_offer: (
      params: {
        formattedFiatAmount: string,
        fiatCurrency: string,
        formattedCryptoAmount: string,
        cryptoSymbol: string,
        price: string,
        bondAmount: string,
        bondSymbol: string,
      },
    ) => (
      <>
        <p>You will pay <span className="text-white iw:text-[#000E0F] font-bold">{params.formattedFiatAmount}</span> {params.fiatCurrency} to buy <span className="text-white iw:text-[#000E0F] font-bold">{params.formattedCryptoAmount}</span> {params.cryptoSymbol} from the seller.</p>
        <hr className="border-[#2E3846] iw:border-[#DFF1FF] my-4" />
        <div className="flex items-center justify-between mb-2">
          <p>Price:</p>
          <p><span className="text-white iw:text-[#000E0F] font-bold">{params.price}</span> {params.fiatCurrency}/{params.cryptoSymbol}</p>
        </div>
        {params.bondAmount && (
          <div className="flex items-center justify-between">
            <p>Bond:</p>
            <p><span className="text-white iw:text-[#000E0F] font-bold">{params.bondAmount}</span> {params.bondSymbol}</p>
          </div>
        )}
      </>
    ),
    warning_buy_offer: (formattedFiatAmount: string, fiatSymbol: string) => (
      <>
        <p className="text-center">You need to complete smaller deposit orders to accumulate enough bond for this transaction</p>
        <p className="mt-5 text-center">Bond requirement: ~<strong className="text-white iw:text-[#000E0F]">{formattedFiatAmount}</strong> {fiatSymbol}</p>
      </>
    ),
    buy_crypto_with: (fiatCurrency: string, fiatSymbol: string) => `Buy ${fiatSymbol} with ${fiatCurrency}`,
  },
  deposit: {
    buy_btn: 'Buy',
    sell_btn: 'Sell',
    bank_name: 'Bank name',
    bank_account_number: 'Account number',
    bank_account_name: 'Account name',
    transfer_note: 'Content',
    transfer_instructions: "Please transfer to the seller's account provided below",
    transfer_title: (fiatAmount: string, fiatCurrency: string) =>
      `Please pay <span style='font-weight:600;color:white'>${fiatAmount}</span> ${fiatCurrency} to the bank account below.`,
    hide_bank_title: 'Hide bank account',
    show_bank_title: 'Show bank account',
    not_found_order: 'This order could not be found',
    scan_qr_code_to_complete_transaction: 'Scan this QR code to do bank transfer.',
    mark_as_paid: 'Mark as paid',
    time_payment_message: (min: number) => `Payment time is ${min} minute${min !== 1 ? 's' : ''}`,
    wait_for_seller: 'Wait for seller confirmation',
    request_arbitrator_dialog_title: 'Request arbitrator',
    request_arbitrator_btn: 'Request arbitrator',
    cancel_trade: 'Cancel trade',
    upload_payment_evidences: 'Upload evidence of payment',
    upload_payment_evidences_desc:
      'Please upload the evidence of payment for this transaction to verify that you have paid.',
    drag_n_drop_file_here: 'Select a file or drag and drop here',
    payment_evidence_file_types: 'JPG, JPEG or PNG, file size no more than 10MB',
    upload_payment_evidence_error_message:
      '[Error] Proof of payment must be in JPG, JPEG, PNG format and smaller than 10MB.',
    paid_description: (
      fiatAmount: string,
      fiatCurrency: string,
      transferNote: string,
      bankAccountNumber: string,
      bankName: string,
    ) =>
      `I have deposited <span class="font-semibold text-white iw:text-[#000E0F]">${fiatAmount}</span> ${fiatCurrency}
      with content <span class="font-semibold text-white iw:text-[#000E0F]">${transferNote}</span>
      into account <span class="font-semibold text-white iw:text-[#000E0F]">${bankAccountNumber}</span>
      at <span class="font-semibold text-white iw:text-[#000E0F]">${bankName}</span>.
      `,
    proof_of_payment: 'Proof of payment',
    request_arbitrator_description: {
      text_1: 'Arbitrator will contact you and the buyer via internal Chat or Contact information.',
      text_2:
        'You will receive your fund when arbitrator confirms that buyer has not yet made the payment.',
      text_3: "Arbitrator's fee will be deducted from Buyer's bond",
    },
    request_arbitrator_description_buyer: (amount: string, currency: string, bankAccountNumber: string, bankName: string) =>
      <>Arbitrator will review Proof of payment for transferring <strong>{amount} {currency}</strong> to account <strong>{bankAccountNumber}</strong> at <strong>{bankName}</strong> and resolve the dispute.</>,
    request_arbitrator: 'Request Arbitrator',
    i_can_wait: 'I can wait',
    arbitrator_reviewing_description: {
      arbitrator_will_consider: 'Arbitrator will review ',
      bank_information: (
        bankAccountNumber: string,
        bankName: string,
        fiatAmount: number | string,
        fiatSymbol: string,
      ) =>
        ` for transferring <span style='font-weight:600;color:white'>${fiatAmount} ${fiatSymbol}</span>
         to account <span style='font-weight:600;color:white'>${bankAccountNumber}</span>
         at <span style='font-weight:600;color:white'>${bankName}</span> and resolve the dispute
        `,
    },
  },
  buy: {
    bond_fee_not_enough: {
      title: (bondSymbol: string) => `Not enough ${bondSymbol}`,
    },
    confirm_matching_engine: (tradeQuantity: number, formattedAmount: string, tokenSymbol: string) =>
      `The system will create ${tradeQuantity} deposit order(s) that matches the total value of ${formattedAmount} ${tokenSymbol}.`,
  },
  common: {
    you: 'You',
    today: 'Today',
    show_all: 'Show All',
    to: 'to',
    with: 'with',
    limit: 'Limit',
    error_code: 'Error Code',
    max: 'Max',
    balance: 'Balance',
    connecting: 'Connecting',
    connect_wallet: 'Connect wallet',
    copied: 'Copied',
    copy_address: 'Copy Address',
    disconnect: 'Disconnect',
    payment_info: 'Payment Info',
    copy: 'Copy',
    buy: 'Buy',
    sell: 'Sell',
    you_buy: 'You buy',
    buyer: 'Buyer',
    close: 'Close',
    seller: 'Seller',
    token_picker_filter_by: 'Filter by token name, symbol, or mint address',
    token_picker_no_tokens_found: 'No tokens found',
    bond: 'Bond',
    token_picker_please_select: 'Input your token mint address here',
    copied_address_snackbar: 'Copied token address to clipboard.',
    copied_trade_ref_snackbar: 'Copied trade ref to clipboard.',
    failed: 'Failed',
    something_wrong: 'Something went wrong. Please try again later!',
    add: 'Add',
    delete: 'Delete',
    update: 'Update',
    save: 'Save',
    cancel: 'Cancel',
    announce_rush_hour: 'Tick-Tock! Time to Swap!',
    confirm: 'Confirm',
    confirm_to_continue: 'Click confirm to continue!',
    contact_support_from_nemoswap: 'Contact Support via Chat',
    contact_support_from_telegram: 'Or via Telegram',
    contact_support: 'Contact support',
    back: 'Back',
    payment_evidence_dialog: {
      zoom_in: 'Zoom in',
      unlock: 'Unlock',
      title: 'Proof of payment',
      close_btn: 'Close',
      no_evidence: 'The buyer has not yet uploaded any proof. Please check again later.',
      upload_no: (no: number) => `Upload #${no}`,
    },
    powered_by_remitano: 'Powered by Remitano',
    deposit: 'Deposit',
    want_to_deposit: 'I want to deposit',
    withdraw: 'Withdraw',
    under_maintenance_title: 'Temporarily Unavailable',
    under_maintenance_description: 'The feature is currently undergoing maintenance. Please come back later.',
    find_potential_offer_multiple: (formattedAmount: string, symbol: string) =>
      `Currently, the maximum deposit order that the system can support is ${formattedAmount} ${symbol}. Please try again with a smaller amount.`,
    find_potential_offer_minimum: (formattedAmount: string, symbol: string) =>
      `Currently, the minimum deposit order that the system can support is ${formattedAmount} ${symbol}. Please try again with a higher amount.`,
    find_potential_offer: (formattedAmount: string, symbol: string) =>
      `Currently, the matching deposit order that the system can support is ${formattedAmount} ${symbol}. Please try again with the corresponding amount.`,
    download: 'Download',
    next: 'Next',
    previous: 'Previous',
    enter_price: 'Enter price',
    min_amount: (amount: string, symbol: string) => `Min amount is not less than ${amount} ${symbol}`,
    max_amount: (amount: string, symbol: string) => `Max amount is not larger than ${amount} ${symbol}`,
    price: 'Price',
    available: 'Available',
    payment_method: 'Payment Method',
    warning: 'Warning',
    please_confirm: 'Please Confirm',
    later: 'Later',
    amount: 'Amount',
    btn_got_it: 'Got it',
    unavailable: 'Not available yet',
    info: 'Info',
    request_arbitrator_btn: {
      dispute: 'Dispute',
      request_arbitrator: 'Request arbitrator',
    },
    require_renec_title: `Not Enough ${MINIMUM_NATIVE_FUNDS.symbol}`,
    require_0_1_renec: `Require ${MINIMUM_NATIVE_FUNDS.amount} ${MINIMUM_NATIVE_FUNDS.symbol}`,
    require_renec_description: `You need approximately ${MINIMUM_NATIVE_FUNDS.amount} ${MINIMUM_NATIVE_FUNDS.symbol} for a seamless experience with P2P trading.`,
    take_me_to_swap: 'Take me to Swap',
    seller_payment_information_incorrect: 'Wrong payment information',
    dont_want_to_buy: "Buyer doesn't want to buy anymore",
    other: 'Other reason',
    tx_notice: {
      confirming: 'Your transaction is being confirmed.',
      expired: 'Transaction is expired.',
      error_title: 'Error!',
      success_title: 'Success!',
      error_message: 'Something went wrong with your transaction.',
      success_message: 'Your transaction has been processed successfully.',
      unconfirmed_message: 'Your transaction could not be confirmed.',
      what_should_i_do_now: 'What should I do now?',
      errors: {
        '0x1': {
          common: [
            `Check if you have enough ${MINIMUM_NATIVE_FUNDS.symbol} to pay the network fee (recommended at least ${MINIMUM_NATIVE_FUNDS.amount})`,
          ],
          create_buy_offer: [
            'Check if you have enough token to pay the bond for buying',
          ],
          create_sell_offer: [
            'Check if you have enough token for selling and paying bond (if required)',
          ],
          create_trade: [
            'Check if you have enough token to pay the bond for the trade',
          ],
          create_limit_order: [
            'Check if you have enough token before placing the order',
          ],
          create_pool: [
            'Check if you have enough RENEC to pay the pool creation fee (usually around 0.08-0.52)',
          ],
          create_position: [
            'Check if you have enough of both tokens before creating the position',
          ],
          swap: [
            'Check if you have enough token for swapping',
          ],
          make_asset_withdrawal: [
            'Check if you have enough token for withdrawal',
          ],
        },
        'wallet_refused': {
          common: [
            'Check if you have connected your wallet and unlocked it',
            'Check if you have confirmed the transaction properly using your connected wallet',
          ],
        },
        unknown: {
          common: [
            'Double check your transaction and try again',
            'If not working, contact us with the information of your transaction and the error code below',
          ],
        },
      },
    },
    missing_secured_payment_info: 'Missing secured payment information.',
    select_a_fiat: 'Select a fiat',
    unknown_token: 'Unknown token',
    warning_unknown_token_content: 'This token is not verified by Renec. Always verify the mint address and perform your own research before proceeding with any transactions.',
    i_understand: 'I understand',
    connect_your_wallet_to_continue: 'Connect your wallet to continue',
    access_denied: 'Access denied!',
    you_are_not_authorized: 'You do not have permission to access this page.',
    back_to_home: 'Back to Home',
    seller_payment_info: (prop: { bankName: string, accountName: string, accountNumber: string, transferNote: string }) => (
      <>
        <span>Seller's payment info: </span>
        <strong className="text-white">{prop.bankName}</strong> - <strong className="text-white">{prop.accountNumber}</strong> - <strong className="text-white">{prop.accountName}</strong>.{' '}
        <span>Message content: </span><strong className="text-white">#{prop.transferNote}</strong>.
      </>
    ),
    upload_proof: 'Upload Proof',
    add_new: 'Add New',
    select_fiat: 'Select Fiat',
  },
  history: {
    no_pending_trade: 'There are no pending trade',
    no_pending_offer: 'There are no pending offer',
    reminder_title: (count: number) => `You have ${count} unfinished transaction(s)`,
    reminder_description: 'Resolve pending transactions and unlock your fund',
    reminder_continue_btn: 'Let\'s go to Transactions',
    reminder_later_btn: 'Later',
    refresh: 'Reload',
    pending: 'Pending',
    available: 'Available',
    trades: 'Trades',
    trades_history: 'Trades History',
    p2p_offers: 'P2P Offers',
    p2p_withdraw_offer: 'P2P: Withdraw Offer',
    buy_offer: 'Buy Offer',
    you_sold: 'You sold',
    you_bought: 'You bought',
  },
  meta: {
    home: {
      title: 'NemoSwap | DEX on RENEC Blockchain',
      description:
        "The RENEC blockchain's NemoSwap makes crypto trading simple. You can exchange tokens on NemoSwap fast, cheaply, and with confidence.",
      thumbnailUrl: '/thumb-share-en.png',
    },
    instantwire: {
      title: 'Trade USDT on Solana | InstantWire - Secure P2P Escrow',
      description:
        'Trade USDT on Solana securely with InstantWire. Enjoy fast and reliable P2P escrow services, ensuring safe and hassle-free transactions. Start trading now and benefit from our user-friendly platform and 24/7 customer support.',
      keywords: 'Trade USDT, Trade USDT with VND, Trade USDT with NGN, Solana USDT trading, P2P escrow, secure cryptocurrency trading, InstantWire, peer-to-peer crypto exchange, USDT escrow services, fast USDT transactions, safe crypto trading',
      thumbnailUrl: '/thumb-share-iw-en.jpg',
    },
  },
  liquidity: {
    no_positions_found: 'No positions found.',
    position: {
      status_above_range: 'Above range',
      status_in_range: 'In range',
      status_below_range: 'Below range',
      uncollected_fees: 'Total uncollected fees',
      close_position: 'Close position',
      claim: 'Claim',
      commission: 'Commission',
      min_price: 'Min price',
      max_price: 'Max price',
      current_price: 'Current price',
      unit: 'Unit',
      withdraw_completed: 'Withdrawal completed!',
      withdraw_failed: 'Withdrawal failed!',
      close_position_successfully: 'Close position successfully!',
      close_position_failed: 'Close position failed!',
    },
    create_pool_form: {
      header: 'Create new pool',
      button_submit: 'Create',
      button_submitting: 'Creating...',
      initial_price: 'Initial price',
      fee_tier: 'Select fee tier for pool',
      success_message: 'Pool has been successfully created and submitted for review.',
      error_message: 'Failed to create or submit pool for review. Please try again.',
      message_dialog_button_close: 'Close',
      swap_prices: 'Swap prices',
    },
    bar: {
      pools: 'Pools',
      positions: 'Positions',
    },
    pool: {
      total_liquidity: 'Total liquidity',
      volume_24h: '24h volume',
      deposit: 'Deposit',
      fee_24h: '24h fee',
      reward: 'Reward',
      day: 'Day',
    },
    pool_search_placeholder: 'Token address, symbol, or pool address',
    no_results_found: 'No results found matching your search criteria.',
    no_pools_found: 'No pools found.',
    open_position: {
      add_new_position: 'Add new Position',
      deposit_ratio: 'Deposit ratio',
      current_price: 'Current price',
      min_price: 'Min price',
      max_price: 'Max price',
      price_range: 'Price range',
      change: 'Change',
      set_liquidity_dialog_title: 'Set liquidity price',
      apply: 'Apply',
      max_balance: 'Balance',
      total: 'Total',
      revert_prices_icon: 'Revert prices',
      errors: {
        must_be_gt_min_price: 'Must be > Min price',
        must_be_lt_max_price: 'Must be < Max price',
        must_be_a_number: 'Must be a number',
      },
      open_position_successfully: 'Open position successfully!',
      open_position_failed: 'Open position failed!',
    },
  },
  quota: {
    copied_address_snackbar: 'Copied address to clipboard.',
    profile: 'Profile',
    level: 'Level',
    kyc: 'KYC',
    now: 'now',
    unverified: 'Unverified',
  },
  core_tokens_list: {
    title: 'Your assets',
    spot: 'Spot',
    top_coins: 'Top coins',
    open_chart: 'Open Chart',
    last_7_days: 'Last 7 Days',
    description: 'Withdrawing and depositing cryptocurrency is as easy as a few clicks!',
    table_headers: {
      token_name: 'Token name',
      about: 'About',
      balance: 'Balance',
      action: 'Action',
      market_cap: 'Market Cap',
    },
    token_detail: {
      title: 'Token info',
      volume_24h: '24H volume',
      link: 'Link',
      website: 'Website',
      close: 'Close',
    },
    search_name_placeholder: 'Search by token name',
    trade: 'Trade',
    bank: {
      withdraw: 'To bank account',
      deposit: 'From bank account',
      description: 'via P2P trading',
    },
    network: {
      withdraw: 'To other wallets',
      deposit: 'From other wallets',
      description: 'via blockchain',
    },
    swap_with_others: 'Swap with other token',
    others: {
      withdraw: 'To bank account (cashout)',
      deposit: (tokenSymbol: string) => `Buy ${tokenSymbol} with reVND`,
      description: (tokenSymbol: string, isWithdraw: boolean) => `or Swap ${tokenSymbol} ${isWithdraw ? 'to' : 'with'} other tokens`,
    },
  },
  arbitrators: {
    text: 'Arbitrators',
    enterArbitratorAddress: 'Enter arbitrator address',
    specialize: 'Specialize',
    processed: 'Processed',
    processedTooltip: 'Number of transactions processed',
    volume: 'Volume',
    volumeTooltip: 'Total volume of processed transactions',
  },
  no_data: 'No data',
  terms_of_use: 'Terms of use',
  wrapped_token: {
    title: {
      you_have_token: (name: string) => `Deposit ${name}?`,
      step_to_deposit: (name: string) => `Steps to deposit ${name}`,
      wait_to_confirm: 'Wait for network confirmation',
      wrapping_token: (name: string) => `Wrapping ${name}`,
    },
    you_can_trade: (name: string) => `Please connect your wallet before proceeding with the ${name} deposit`,
    connect_first: 'First you need to Connect Wallet',
    where_to_transfer: (name: string) => `Where will you transfer ${name} from?`,
    please_send: 'Please send',
    through_network: 'through the network',
    select_network: 'Select Network',
    btn_deposited: (name: string) => `I have deposited ${name}`,
    to_address_below: 'to the address below',
    please_wait_the_transaction: 'Please wait while the network confirms your transactions.',
    learn_more: 'Learn more',
    your_balance: 'Your balance',
    info_wrapped_token: (displaySymbol: string, symbol: string) => (
      `When sending ${displaySymbol} to RENEC network, your ${displaySymbol} will be wrapped to ${symbol} with a rate of 1:1`
    ),
    info_unwrap_token: (displaySymbol: string, symbol: string) => (
      `You can unwrap your ${symbol} back to ${displaySymbol} at `
    ),
    btn_got_it: 'Got it',
    btn_close: 'Close',
  },
  asset_withdrawal: {
    btn_close: 'Close',
    you_withdrew: 'You withdrew',
    you_can_trade: (name: string) => `You can trade ${name} with NemoSwap.`,
    connect_first: 'First you need to Connect Wallet',
    select_network: 'Select Network',
    you_withdrawing: 'You are withdrawing',
    through_network: 'through the network',
    withdrawing: (name: string) => `Withdraw ${name}`,
    withdraw_to_wallet_address: 'withdraw to this wallet address',
    wallet_address: (tokenSymbol: string) => `${tokenSymbol} wallet address`,
    withdraw_token: (tokenSymbol: string) => `Withdraw ${tokenSymbol}`,
    amount: 'amount',
    issues: {
      enter_wallet_address: 'Enter wallet address',
      enter_amount: 'Enter amount',
      no_token_info: 'Select token',
      insufficient_balance: 'Insufficient balance',
    },
  },
  referral: {
    title: 'Referral link',
    description: 'Receive 2 NSF immediately when your friends trade on NemoSwap',
  },
  p2p_market: {
    p2p_create: 'Create Offer',
    order_info: 'Offer Info',
    buy_and_sell_usdt: (chain: string) => `Buy and Sell USDT on ${chain}`,
    zero_trading_fees: 'with zero trading fees',
    buy: 'Buy',
    sell: 'Sell',
    enter_amount: 'Enter amount',
    buyer: 'Buyer',
    seller: 'Seller',
    price: 'Price',
    available_and_offer_limit: 'Available | Offer Limit',
    payment_method: 'Payment Method',
    trade: 'Trade',
    no_offers_found: 'No offers found.',
    loading_offers: 'Loading offers...',
    avatar_you: 'You',
    bank_transfer: 'Bank Transfer',
    view: 'View',
    available: 'Available',
    limit: 'Limit',
    buy_confirm_message: (
      fiatAmount: string,
      tokenAmount: string,
      fiatCurrency: string,
      tokenSymbol: string,
    ) => (
      <>
        <span className="text-gray-200 iw:text-[#666]">You will pay </span>
        <span className="font-bold text-white iw:text-[#000E0F]">{fiatAmount} {fiatCurrency}</span>
        <span className="text-gray-200 iw:text-[#666]"> to buy </span>
        <span className="font-bold text-white iw:text-[#000E0F]">{tokenAmount} {tokenSymbol}</span>
        <span className="text-gray-200 iw:text-[#666]"> from the seller.</span>
      </>
    ),
    offer_ref: 'Offer ID:',
    show_only_my_offers: 'Show Only My Offers',
    trade_detail: {
      dispute_after: 'Dispute after',
      you_will_receive: 'You will receive',
      make_payment: 'Make Payment',
      notify_seller: 'Notify Seller',
      notify_seller_description: 'After transferring, click on the "I\'ve paid, Notify Seller" button',
      trade_info: 'Trade Info',
      back_to_p2p_market: 'Back to P2P Market',
      pay_the_seller_within: 'Pay the seller within',
      wait_the_buyer_pay: "Wait for buyer's payment",
      wait_the_seller_confirm: 'Wait the seller confirm',
      time_created: 'Time created',
      trade: 'Trade',
      heading: {
        wait_seller_confirm_message: 'Wait for the seller to confirm the payment and release your token',
        successfully_purchased: 'Successfully purchased',
      },
      mark_as_paid: "I've paid, Notify Seller",
      cancel_dialog: {
        title: 'Choose a reason',
        description: 'Please let us know which difficulty you are experiencing below',
        dont_want_to_buy_anymore: "I don't want to buy anymore",
        payment_information_incorrect: "The seller's payment information is incorrect",
        note: 'If you cancel the trade, you will not be refunded the bond.',
        other: 'Others',
      },
      chat_dialog: {
        chat_with_seller: 'Chat with Seller',
        chat_with_buyer: 'Chat with Buyer',
        enter_message: 'Enter the message',
        chat_system_message_buyer_01: <>Please take a photo of the <span className="font-semibold text-white iw:text-[#000E0F]">proof of payment</span> to verify with the seller.</>,
        chat_system_message_buyer_02: <>After transferring the money, please click the{' '}<span className="font-semibold text-white iw:text-[#000E0F]">"I've paid, Notify Seller"</span> button below.</>,
      },
      show_message: 'Show message',
      confirm_cancel_trade_dialog: {
        message: 'Please confirm that you have not transferred the money to the seller, otherwise you may lose it.',
      },
      buyer_info: 'Buyer Info',
      confirm_for_buyer: 'Confirm for buyer',
      confirm_for_buyer_message: "If you haven't received the payment, please press 'Request Arbitrator' for assistance",
      buyer_will_pay_content: (fiatAmount: string, fiatCurrency: string, transferNote: string, bank_number: string, bank_name: string) => (
        <>
          <span>The buyer will pay </span>
          <span className="font-bold text-white iw:text-[#000E0F]">{fiatAmount} {fiatCurrency}</span>
          <span> with content </span>
          <span className="font-bold text-white iw:text-[#000E0F]">#{transferNote}</span>
          <span> to your account </span>
          <span className="font-bold text-white iw:text-[#000E0F]">{bank_number}</span>
          <span> at </span>
          <span className="font-bold text-white iw:text-[#000E0F]">{bank_name}</span>.
        </>
      ),
      buyer_has_paid_content: (fiatAmount: string, fiatCurrency: string, transferNote: string, bank_number: string, bank_name: string) => (
        <>
          <span>The buyer has paid </span>
          <span className="font-bold text-white iw:text-[#000E0F]">{fiatAmount} {fiatCurrency}</span>
          <span> with content </span>
          <span className="font-bold text-white iw:text-[#000E0F]">#{transferNote}</span>
          <span> to your account </span>
          <span className="font-bold text-white iw:text-[#000E0F]">{bank_number}</span>
          <span> at </span>
          <span className="font-bold text-white iw:text-[#000E0F]">{bank_name}</span>.
        </>
      ),
      i_need_help: 'I need help',
    },
    how_p2p_work: {
      title: 'How P2P work?',
      description: 'Unlock Possibilities: P2P Market offers limitless trading opportunities by connecting individuals directly, transcending traditional boundaries for seamless transactions.',
      items: [
        {
          title: 'Place Buy Order',
          description: 'Unlock Possibilities: P2P Market offers limitless trading opportunities by connecting individuals directly, transcending traditional boundaries for seamless transactions.',
        },
        {
          title: 'Pay the seller',
          description: 'Unlock Possibilities: P2P Market offers limitless trading opportunities by connecting individuals directly, transcending traditional boundaries for seamless transactions.',
        },
        {
          title: 'Receive the Crypto',
          description: 'Unlock Possibilities: P2P Market offers limitless trading opportunities by connecting individuals directly, transcending traditional boundaries for seamless transactions.',
        },
      ],
    },
    benefits_of_escrow: {
      title: 'Why should I buy on Escrow?',
      description: 'Unlock Possibilities: P2P Market offers limitless trading opportunities by connecting individuals directly, transcending traditional boundaries for seamless transactions.',
      items: [
        {
          title: 'Built-in Security',
          description: 'Built-in security features ensure peace of mind in the crypto market, safeguarding assets and transactions.',
        },
        {
          title: 'Flexible Payment Methods',
          description: 'Enjoy flexible payment methods tailored to your preferences in the crypto market, enhancing convenience and accessibility.',
        },
        {
          title: 'Built-in Security',
          description: 'Built-in security features ensure peace of mind in the crypto market, safeguarding assets and transactions.',
        },
        {
          title: 'Flexible Payment Methods',
          description: 'Enjoy flexible payment methods tailored to your preferences in the crypto market, enhancing convenience and accessibility.',
        },
      ],
    },
  },
  p2p_offer_detail: {
    offer: 'Offer',
    time_created: 'Time created',
    offer_info: {
      title: 'Offer Info',
      available: 'Available',
      pending: 'Pending',
      price: 'Price',
      see_detail: 'See detail',
      sell: 'Sell',
      buy: 'Buy',
      to: 'to',
      from: 'from',
      you_sold: 'You sold',
      you_bought: 'You bought',
      total_received: 'Total received',
      limit: 'Limit',
      payment_method: 'Payment method',
      bond: 'Bond',
      total_paid: 'Total paid',
    },
    buyer_list: {
      buyer_list_title: 'Buyer list',
      waiting_for_buyer_title: 'Waiting for buyer(s)',
      please_confirm_payment: 'Please confirm payments for the buyers.',
      details: 'See detail',
    },
    seller_list: {
      title: "Seller's list",
    },
    trade_detail_card: {
      buyer: 'Buyer',
      seller: 'Seller',
      amount: 'Amount',
      message: 'message',
      view: 'View',
    },
    new_price: 'New price',
    update_price: 'Update Price',
    edit_offer: 'Edit Offer',
    edit_price: 'Edit Price',
    cancel_offer: 'Cancel Offer',
    cancelling: 'Cancelling...',
    offer_status_badge: {
      fulfilled: 'Fulfilled',
      canceled: 'Canceled',
      in_progress: 'In progress',
      buyer_awaiting: 'Buyer awaiting',
      seller_awaiting: 'Seller awaiting',
      not_applicable: 'Not available',
    },
    market_price: (price: string) => <>Market price: <span className="font-semibold text-sm text-white iw:text-[#000E0F] ml-1">{price}</span></>,
    estimated_wait_time: (isFast: boolean) => (
      <>
        Estimated waiting time: {
          isFast
            ? <span className="text-sm text-[#55B964] iw:text-[#00BD90] ml-1">Fast</span>
            : <span className="text-sm text-[#EB5757] iw:text-[#EB5757] ml-1">Slow</span>
        }
      </>
    ),
    multiple_dispute_dialog: {
      heading: 'Multiple Disputes',
      message: (noTrades: number) => (
        <>
          You have <span className="font-semibold text-white iw:text-[#000E0F]">{noTrades}</span>{' '}
          trade{noTrades > 1 ? 's' : ''} to dispute.
        </>
      ),
      dispute_all_btn: 'Dispute All',
    },
    multiple_dispute_message: 'You have some trades that can be disputed.',
    dispute_btn: 'Dispute all',
    dispute_success_toast_message: 'Your multiple dispute request has been sent successfully.',
  },
  p2p_leader_board_text: 'Top weekly trading volume of P2P Market',
  scan_to_download_wallet_app: 'Scan to download Carax App',
  telegram_subscribe: {
    subscribe: 'Subscribe',
    message: 'Follow our Telegram channel to receive the latest updates and notifications.',
  },
  telegram_dialog: {
    unsubscribe: {
      title: 'Unsubscribe Telegram',
      sub_title: 'Mute Notifications',
      description: 'You will NOT receive notifications after unsubscribing from the Telegram Bot.',
      confirm_btn: 'Unsubscribe',
    },
    subscribe: {
      title: 'Subscribe Telegram',
      sub_title: 'Receive notifications',
      description: 'Link this wallet with Telegram to receive notifications for P2P trades.',
      confirm_btn: 'Subscribe',
    },
  },
  bond: {
    required: 'Require Bond',
    reason: (amount: string, tokenSymbol: string, date: string) =>
      `You need to bond ${amount} ${tokenSymbol} for this trade because you failed to confirm a trade on time on ${date}`,
    total: 'Total Bond',
    reduces_after: 'Bond reduces after',
    need_for_bond: (amount: string, tokenSymbol: string) => `Need ${amount} ${tokenSymbol} for Bond`,
  },
  leader_board: {
    title: 'Leader Board',
    description: 'The top 20 wallet addresses with the highest transaction volume will be honored and rewarded. Join the P2P trading to earn attractive rewards!',
    footer_description: 'The trader leader board will be refreshed weekly and sent to your wallet the following week!',
    position: 'Position',
    wallet: 'Wallet',
    volume_buy: 'Volume Buy',
    volume_sell: 'Volume Sell',
    volume_total: 'Total Volume',
    select_currency: 'Select currency',
    this_week: 'This week',
    last_week: 'Last week',
    up_to: 'Up to',
    reward: 'Reward',
    reward_value: 'Reward value',
    reward_description: 'Trade now to earn exciting rewards and elevate your trading experience!',
    reward_card_desc_1: <>The <span className="text-[#FF6701]">top 20</span> will receive corresponding rewards each week.</>,
    reward_card_desc_2: <>The total trading volume reaches <span className="text-[#FF6701]">100,000</span> USDT</>,
    reward_card_list_1: [
      <><span className="text-[#1F5F8D] font-semibold">100, 50, 10 USDT</span> for Top 1, 2, 3</>,
      <><span className="text-[#1F5F8D] font-semibold">5 USDT</span> for Top 20</>,
    ],
    reward_card_list_2: [
      <><span className="text-[#1F5F8D] font-semibold">100 USDT</span> for each wallet</>,
      <><span className="text-[#1F5F8D] font-semibold">10%</span> of the total volume</>,
    ],
    reward_card_list_3: [
      <><span className="text-[#1F5F8D] font-semibold">100 USDT</span> for each wallet</>,
      <><span className="text-[#1F5F8D] font-semibold">20%</span> of the total volume</>,
    ],
  },
}

export default locale
