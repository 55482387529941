import { useRollbarPerson } from '@rollbar/react'
import type {
  Transaction,
  VersionedTransaction,
} from '@solana/web3.js'
import {
  Keypair,
} from '@solana/web3.js'
import {
  useCallback,
  useMemo,
} from 'react'

import {
  DEMON_WALLET_URL,
} from '@/constants/index'
import {
  isDemonWalletDetected,
  isRunningInCypress,
} from '@/utils/helpers'
import {
  AnchorWallet,
  DemonWalletName,
  E2EWalletName,
  useAnchorWallet,
  useWallet,
  useWalletModal,
} from '@/wallet/adapter'

import useHook from './use-hook'

declare global {
  interface Window {
    phantom: {
      solana: any;
    }
  }
}

const useNemoWallet = () => {
  const [anchorWalletRef, anchorWallet] = useHook(useAnchorWallet)
  const { wallet, connect, select, publicKey } = useWallet()
  const { visible, setVisible } = useWalletModal()

  useRollbarPerson({ wallet_address: publicKey?.toBase58() || 'anonymous' })

  const safeWallet = useMemo(
    () =>
      ({
        get publicKey() {
          return anchorWalletRef?.current?.publicKey || Keypair.generate().publicKey
        },
        signTransaction: <T extends Transaction | VersionedTransaction>(tx: T) => {
          return anchorWalletRef?.current ? anchorWalletRef.current.signTransaction(tx) : tx
        },
        signAllTransactions: <T extends Transaction | VersionedTransaction>(txs: T[]) => {
          return anchorWalletRef?.current ? anchorWalletRef.current.signAllTransactions(txs) : txs
        },
      } as AnchorWallet),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [anchorWallet, anchorWalletRef],
  )

  const connectWallet = useCallback(() => {
    if (isRunningInCypress) {
      select(E2EWalletName)
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      connect().catch(() => {})
    } else {
      if (wallet) {
        if (wallet.adapter?.url?.includes('solanamobile.com/wallets')) {
          wallet.adapter.url = DEMON_WALLET_URL
        }

        if (wallet.adapter.name === DemonWalletName) {
          if (!isDemonWalletDetected()) {
            setVisible(!visible)
            return
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        connect().catch(() => {})
      } else {
        setVisible(!visible)
      }
    }
  }, [connect, select, setVisible, visible, wallet])

  return useMemo(
    () => ({
      anchorWallet: safeWallet,
      publicKey,
      connectWallet,
    }),
    [safeWallet, publicKey, connectWallet],
  )
}

export default useNemoWallet
