import { useRouter } from 'next/router'
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import useDefaultFiatAddressByCountry from '@/hooks/use-default-fiat-address-by-country'
import useFiatBanks, { FiatBank, toFiatBank } from '@/hooks/use-fiat-banks'
import useSecuredPaymentInfoOfSeller from '@/hooks/use-secured-payment-info-of-seller'
import useTokens, { FiatTokenInfo } from '@/hooks/use-tokens'
import { SecuredPaymentInfo } from '@/model/bank-info'
import { useWallet } from '@/wallet/adapter'

import DialogSelectForm from './dialog-select-form'

type PaymentInfoContextType = {
  fiatSupportedBanks: FiatBank[]
  isFixedFiatToken: boolean
  isPaymentInfoDialogAvailable: boolean
  selectedBank: FiatBank | null
  selectedPaymentInfo: SecuredPaymentInfo | null

  fiatToken?: FiatTokenInfo
  setFiatToken: Dispatch<SetStateAction<FiatTokenInfo | undefined>>

  openPaymentInfoDialog: boolean
  setOpenPaymentInfoDialog: Dispatch<SetStateAction<boolean>>
}

const PaymentInfoContext = createContext<PaymentInfoContextType>({
  fiatSupportedBanks: [],
  isFixedFiatToken: false,
  isPaymentInfoDialogAvailable: false,
  selectedBank: null,
  selectedPaymentInfo: null,
  fiatToken: undefined,
  setFiatToken: () => null,
  openPaymentInfoDialog: false,
  setOpenPaymentInfoDialog: () => null,
})

export const usePaymentInfo = () => useContext(PaymentInfoContext)

export type PaymentInfoProviderConfig = {
  fixedFiatToken?: FiatTokenInfo
  /**
   * @internal only useful for testing.
   */
  keepDialogOpen?: boolean
}

const PaymentInfoProvider = ({
  children,
  fixedFiatToken,
  keepDialogOpen = false,
}: PropsWithChildren<PaymentInfoProviderConfig>) => {
  const { locale } = useRouter()
  const { publicKey } = useWallet()

  const [fiatToken, setFiatToken] = useState(fixedFiatToken)
  const fiatTokenInUse = fixedFiatToken ?? fiatToken
  const [openPaymentInfoDialog, setOpenPaymentInfoDialog] = useState(false)

  const { banks } = useFiatBanks(fiatTokenInUse)
  const { lastSelectedMethod } = useSecuredPaymentInfoOfSeller(fiatTokenInUse?.symbol)
  const selectedBank = useMemo(
    () =>
      lastSelectedMethod &&
      (banks.find((bank) => bank.id === lastSelectedMethod.rawBankName) ??
        toFiatBank(lastSelectedMethod.rawBankName)),
    [banks, lastSelectedMethod],
  )

  const isFixedFiatToken = Boolean(fixedFiatToken)
  const isPaymentInfoDialogAvailable = Boolean(publicKey)

  const { fiatCustomInfoList, isLoading, reVndFiatInfo, reUsdFiatInfo } = useTokens()
  const { fiatTokenAddress } = useDefaultFiatAddressByCountry()

  // Prefill fiat token by country if not yet selected.
  useEffect(() => {
    const foundFiatToken = fiatCustomInfoList.find((token) => token.address === fiatTokenAddress)
    if (!isLoading && foundFiatToken) {
      setFiatToken((fiatToken) => fiatToken ?? foundFiatToken)
    }
  }, [fiatTokenAddress, fiatCustomInfoList, isLoading])

  useEffect(() => {
    // Fiat token by country might NOT be available the moment the dialog is
    // opened, so we use the current locale to prefill the corresponding fiat.
    if (openPaymentInfoDialog) {
      setFiatToken((fiatToken) => fiatToken ?? (locale === 'vi' ? reVndFiatInfo : reUsdFiatInfo))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPaymentInfoDialog])

  const contextValue = useMemo(() => ({
    fiatSupportedBanks: banks,
    isFixedFiatToken,
    isPaymentInfoDialogAvailable,
    selectedBank,
    selectedPaymentInfo: lastSelectedMethod,
    fiatToken: fiatTokenInUse,
    setFiatToken,
    openPaymentInfoDialog: keepDialogOpen || (isPaymentInfoDialogAvailable && openPaymentInfoDialog),
    setOpenPaymentInfoDialog,
  }), [
    banks,
    fiatTokenInUse,
    isFixedFiatToken,
    isPaymentInfoDialogAvailable,
    keepDialogOpen,
    lastSelectedMethod,
    openPaymentInfoDialog,
    selectedBank,
  ])

  return (
    <PaymentInfoContext.Provider value={contextValue}>
      <DialogSelectForm />
      {children}
    </PaymentInfoContext.Provider>
  )
}

export default PaymentInfoProvider
