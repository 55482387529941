import { api } from '.'

type PaymentMethod = {
  name: string,
  type: 'bank_transfer'
  country_code: string
}

export const getPaymentMethods = async (countryCodes: string[]): Promise<PaymentMethod[]> => {
  const { data } = await api.get('nemoswap/payment_methods', {
    params: {
      country_codes: countryCodes,
    },
  })

  return data
}
