import { useMemo } from 'react'

import useFeatureFlag from '@/hooks/use-feature-flag'
import useP2pFiatTokens from '@/hooks/use-p2p-fiat-tokens'
import useTokens from '@/hooks/use-tokens'

const useFiatCustomInfoList = () => {
  const {
    fiatCustomInfoList: oldFiatCustomInfoList,
    fiatCustomInfoMap: oldFiatCustomInfoMap,
    fiatTokensMap: oldFiatTokensMap,
  } = useTokens(true)
  const {
    fiatCustomInfoList: newFiatCustomInfoList,
    fiatCustomInfoMap: newFiatCustomInfoMap,
    fiatTokensMap: newFiatTokensMap,
  } = useP2pFiatTokens()
  const { enabled } = useFeatureFlag('nemo_swap_p2p_market_full_fiats_and_banks')

  const fiatCustomInfoList = enabled ? newFiatCustomInfoList : oldFiatCustomInfoList
  const fiatCustomInfoMap = enabled ? newFiatCustomInfoMap : oldFiatCustomInfoMap
  const fiatTokensMap = enabled ? newFiatTokensMap : oldFiatTokensMap

  return useMemo(() => ({
    fiatCustomInfoList,
    fiatCustomInfoMap,
    fiatTokensMap,
  }), [fiatCustomInfoList, fiatCustomInfoMap, fiatTokensMap])
}

export default useFiatCustomInfoList
