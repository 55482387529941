import * as renecAdapter from '@renec-foundation/wallet-adapter-react'
import * as solanaAdapter from '@solana/wallet-adapter-react'
import {
  useWalletModal as solanaUseWalletModal,
} from '@solana/wallet-adapter-react-ui'

import { isChainSolana } from '@/constants/index'
export {
  DemonWalletAdapter,
  DemonWalletName,
  E2EWalletAdapter,
  E2EWalletName,
} from '@renec-foundation/wallet-adapter-react' // these exports are aimed to be used for renec only
export { type WalletAdapterEvents } from '@solana/wallet-adapter-base'
export { type AnchorWallet } from '@solana/wallet-adapter-react'

export const useWallet = isChainSolana() ? solanaAdapter.useWallet : renecAdapter.useWallet
export const useConnection = isChainSolana() ? solanaAdapter.useConnection : renecAdapter.useConnection
export const useAnchorWallet = isChainSolana() ? solanaAdapter.useAnchorWallet : renecAdapter.useAnchorWallet
export const useWalletModal = isChainSolana() ? solanaUseWalletModal : renecAdapter.useWalletModal
