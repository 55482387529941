import { DecimalUtil } from '@orca-so/common-sdk'
import type { u64 } from '@solana/spl-token'
import Decimal from 'decimal.js'
import isNumber from 'lodash/isNumber'
import isSafeInteger from 'lodash/isSafeInteger'
import isString from 'lodash/isString'
import repeat from 'lodash/repeat'

import { toResult } from './helpers'

const MaxDPOfNumbers = Decimal.log10(Number.EPSILON).abs().trunc().toNumber()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _toDecimal = (any: any) => {
  let decimal = toResult(() => new Decimal(any ?? 0))[1]

  if (!decimal) {
    // eslint-disable-next-line no-console
    console.warn('Could not parse numeric value of', any)

    decimal = new Decimal(NaN)
  }

  if (isNumber(any)) {
    decimal = decimal.toDP(MaxDPOfNumbers, Decimal.ROUND_DOWN)
  }

  return decimal
}

const countDigits = (str: string) => str.replace(/[^0-9]/g, '').length

const addThousandDelimiters = (str: string) => str.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toDecimal = (any: any, config?: NumericFormatterConfig) => {
  if (config) {
    any = format(any, config)
  }

  if (Decimal.isDecimal(any)) {
    return any
  }

  if (isString(any)) {
    const scale = any[any.length - 1]

    any = any
      .replace(/,/g, '') // remove thousand delimiters
      .replace(/[K,M,B]$/, '') // remove units
      .replace(/^([-+]?)\./, '$10.') // prepend 0 if starting with decimal delimiter

    return _toDecimal(any).mul(
      scale === 'K' ? 1e3 : scale === 'M' ? 1e6 : scale === 'B' ? 1e9 : 1,
    )
  }

  return _toDecimal(any)
}

/**
 * Returns the minimum number of decimal places it takes for `num` to be
 * significant after trimming.
 */
export const getMinimumDecimalPlacesWhileSignificant = (
  num: Decimal.Value,
  significantBy: NumericFormatterConfig['significantBy'] = 'whole',
) => {
  num = toDecimal(num)

  const truncated = num.trunc()
  if (significantBy === 'whole' && !truncated.eq(0)) {
    return 0
  }

  for (let dp = 0; dp <= num.decimalPlaces(); ++dp) {
    if (!num.toDP(dp, Decimal.ROUND_DOWN).eq(truncated)) {
      return dp
    }
  }

  return 0
}

export type NumericFormatterConfig = {
  /**
   * The number of decimal places to limit the output. If a negative value is
   * provided, the output will not be trimmed.
   *
   * If this option is set to any value but `0` while `short` is set to `true`,
   * it will be set to `1`.
   *
   * @default 2
   */
  decimals?: number

  /**
   * Sets the length that the formatted number *should* **not exceed**. This
   * length controls the number of digits and **not** thousand delimiters,
   * the decimal separator, or the scale unit (`K`, `M`, `B`). This is done by
   * trying to reduce `decimals` until the number stays within the desired
   * length without breaking the rules of other options.
   *
   * Note that the formatted number may still exceed this length if forcing it
   * to stay within the given limit drops its significance. This is to prevent
   * `1000` from being formatted to `10e2` when `desiredLength` is `3`, or if
   * `preserveSignificance` is true, `10.001` should remain the same, rather
   * than becoming `10`.
   *
   * A negative value means no limit is set.
   *
   * @default -1
   */
  desiredLength?: number

  /**
   * By default, the number will be strictly trimmed to the specified decimals.
   * However, this will result in numbers losing their *significance*. For
   * example, `0.0031` is trimmed to `0` with `decimals=2`. To preserve the
   * significance by only rounding upto the leftmost digit (`0.0031 -> 0.003`),
   * set this option to `true`.
   *
   * For the number to be formatted significantly over another number or
   * numbers, pass them here. The algorithm will try to trim the number down to
   * whatever decimal places it takes for the number to be distinguishable from
   * others. Note that equal numbers are considered *already significant* over
   * each other.
   *
   * Invalid/non-finite values passed to this argument will be skipped.
   *
   * To control significance mode, use `significantBy`.
   *
   * @default false
   */
  preserveSignificance?: boolean | Decimal.Value | Decimal.Value[]

  /**
   * Rounding mode for formatting.
   *
   * @see https://en.wikipedia.org/wiki/Rounding#Rounding_to_integer
   *
   * @default 'half-away'
   */
  rounding?:
    | 'away'
    | 'down'
    | 'half-away'
    | 'half-down'
    | 'half-towards'
    | 'half-up'
    | 'towards'
    | 'up'

  /**
   * Use `K` (thousand), `M` (million), and `B` (billion) to shorten the
   * formatted number.
   *
   * @default false
   */
  short?: boolean

  /**
   * By default, only negative numbers have a minus sign before them. To also
   * add a plus sign before positive numbers, set this to `true`.
   *
   * @default false
   */
  sign?: boolean

  /**
   * This option is intended to be used with `preserveSignificance`.
   *
   * By default, `0.0031` will be preserved to `0.003` with `decimals=2`, while
   * `3.0031` will become `3` (since a number with non-zero integral part is
   * already significant). To format it into `3.003` instead, set this option
   * to `decimal-only`.
   *
   * @see https://xaktly.com/SignificantFigures.html
   *
   * @default "whole"
   */
  significantBy?: 'decimal-only' | 'whole'

  /**
   * By default, a warning will be logged to console if value is *not* finite
   * (`NaN` or `Infinity`). Set this to `true` to throw the error instead.
   *
   * @default false
   */
  strict?: boolean

  /**
   * Whether to add thousand delimiters.
   *
   * @default true
   */
  thousandDelimiters?: boolean

  /**
   * Set this to `true` to append trailing zeros until the number has
   * `decimals` significant decimal places, or until `desiredLength` is met,
   * whichever comes first.
   *
   * @default false
   */
  trailingZeros?: boolean
}

interface Format {
  (value?: Decimal.Value | null | undefined, decimals?: number): string
  (value?: Decimal.Value | null | undefined, config?: NumericFormatterConfig): string
}

export const format: Format = (
  value?: Decimal.Value | null | undefined,
  config: number | NumericFormatterConfig = {},
) => {
  if (typeof config === 'number') {
    config = { decimals: config }
  }

  const {
    decimals = 2,
    desiredLength = -1,
    preserveSignificance = false,
    rounding = 'half-away',
    short = false,
    sign = false,
    significantBy = 'whole',
    strict = false,
    thousandDelimiters = true,
    trailingZeros = false,
  } = config

  if (decimals >= 0 && !isSafeInteger(decimals)) {
    throw new Error('Unless negative, decimals must be an integer.')
  }

  if (desiredLength >= 0 && !isSafeInteger(desiredLength)) {
    throw new Error('Unless negative, desiredLength must be an integer.')
  }

  const decimalValue = toDecimal(value)

  if (!decimalValue.isFinite()) {
    if (strict) {
      throw new Error(`Cannot format ${decimalValue.toString()} values.`)
    } else {
      // eslint-disable-next-line no-console
      console.warn(`Found an invalid numeric value (reading \`${decimalValue.toString()}\`).`)
    }

    return decimalValue.toString()
  }

  const decimalRounding =
    {
      away: Decimal.ROUND_UP,
      down: Decimal.ROUND_FLOOR,
      'half-away': Decimal.ROUND_HALF_UP,
      'half-down': Decimal.ROUND_HALF_FLOOR,
      'half-towards': Decimal.ROUND_HALF_DOWN,
      'half-up': Decimal.ROUND_HALF_CEIL,
      towards: Decimal.ROUND_DOWN,
      up: Decimal.ROUND_CEIL,
    }[rounding] ?? Decimal.ROUND_HALF_UP

  const minimumDecimalPlacesForSignificance =
    preserveSignificance === false
      ? 0
      : Math.max(
          0,
          ...Array<true | Decimal.Value>()
            .concat(preserveSignificance)
            .filter((value) => value === true || toDecimal(value).isFinite())
            .map((value) =>
              getMinimumDecimalPlacesWhileSignificant(
                decimalValue.sub(value === true ? 0 : toDecimal(value)),
                significantBy,
              ),
            ),
        )

  const decimalPlaces = short
    ? decimals && 1
    : preserveSignificance === false
    ? decimals
    : decimals >= 0
    ? Math.max(decimals, minimumDecimalPlacesForSignificance)
    : decimals

  const roundedValue =
    decimalPlaces >= 0 ? decimalValue.toDP(decimalPlaces, decimalRounding) : decimalValue
  const scale = roundedValue.gte(1e9) ? 1e9 : roundedValue.gte(1e6) ? 1e6 : roundedValue.gte(1e3) ? 1e3 : 1
  const unit = scale === 1e9 ? 'B' : scale === 1e6 ? 'M' : scale === 1e3 ? 'K' : ''
  const downScaledValue = roundedValue.div(short ? scale : 1)

  const finalValue =
    decimalPlaces >= 0 ? downScaledValue.toDP(decimalPlaces, decimalRounding) : downScaledValue
  let numberStr = `${sign && finalValue.gte(0) ? '+' : ''}${finalValue.toFixed()}`

  if (desiredLength >= 0) {
    for (
      let dp = finalValue.decimalPlaces();
      dp >= minimumDecimalPlacesForSignificance && countDigits(numberStr) > desiredLength;
      --dp
    ) {
      numberStr = finalValue.toDP(dp, decimalRounding).toFixed()
    }
  }

  if (trailingZeros && (decimalPlaces >= 0 || desiredLength >= 0)) {
    const zerosToAdd = Math.min(
      desiredLength >= 0 ? desiredLength - countDigits(numberStr) : Infinity,
      decimalPlaces >= 0 ? decimalPlaces - finalValue.decimalPlaces() : Infinity,
    )

    if (!numberStr.includes('.')) numberStr += '.'
    numberStr += repeat('0', zerosToAdd)
  }

  if (thousandDelimiters) {
    const [integral, decimal] = numberStr.split('.')
    numberStr = `${addThousandDelimiters(integral)}${decimal ? `.${decimal}` : ''}`
  }

  if (short) {
    numberStr += unit
  }

  return numberStr
}

/**
 * A wrapper of {@link format} for {@link u64}.
 */
export const formatU64 = (amount: u64, config?: NumericFormatterConfig) => {
  return format(DecimalUtil.fromU64(amount, config?.decimals).toString(), config)
}

/**
 * A wrapper of {@link format} that rounds down the number when trimming. This
 * is to display a safe amount that does NOT exceed the unformatted one to
 * avoid `insufficient-funds` errors.
 */
export const formatSafe = (
  amount?: Decimal.Value | null | undefined,
  decimals?: number,
  config: NumericFormatterConfig = {},
) => format(amount, { decimals, rounding: 'down', ...config })

/**
 * A wrapper of {@link toDecimal} that supports trimming the number while
 * rounding it down. This is to get a safe amount that does NOT exceed the
 * unformatted one to avoid `insufficient-funds` errors.
 */
export const toDecimalSafe = (
  amount?: Decimal.Value | null | undefined,
  decimals?: number,
  config: NumericFormatterConfig = {},
) => toDecimal(formatSafe(amount, decimals, config))

export const formatRange = (
  range: [Decimal.Value, Decimal.Value],
  decimals?: number,
  config: NumericFormatterConfig = {},
): [string, string] => {
  return [
    format(range[0], { decimals, preserveSignificance: range[1], rounding: 'down', ...config }),
    format(range[1], { decimals, preserveSignificance: range[0], rounding: 'up', ...config }),
  ]
}

/**
 * Approximately compare two numbers given a definite number of deterministic
 * decimal places.
 *
 * @returns `0` if the numbers are equal, a positive number if `x > y`, or a
 * negative number if `x < y`.
 */
export const compare = (
  x?: Decimal.Value | null | undefined,
  y?: Decimal.Value | null | undefined,
  decimals = MaxDPOfNumbers,
) => {
  let diff = toDecimal(x).sub(toDecimal(y))

  if (diff.isNaN()) {
    throw new Error('Cannot compare NaN values.')
  }

  while (decimals > 0) {
    diff = diff.mul(10)
    --decimals
  }

  const sign = Decimal.sign(diff.trunc())
  return sign === -0 ? 0 : sign
}
