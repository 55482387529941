import { Consumer, Subscription } from '@rails/actioncable'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

export const CABLE_URL = process.env.NEXT_PUBLIC_BACKEND_URL?.replace('http', 'ws')

const CableContext = createContext<Consumer>(new Consumer(''))

const CableProvider = ({ children }: PropsWithChildren) => {
  const consumerRef = useRef(new Consumer((CABLE_URL ?? '') + '/cable'))
  return <CableContext.Provider value={consumerRef.current}>{children}</CableContext.Provider>
}

export default CableProvider

export type ChannelMessage<T = object> = {
  channel: string
  data: T
  params: object
  receivedAt: Date
}

export type ChannelStatus = 'connected' | 'disconnected' | 'initialized' | 'rejected' | null

/**
 * Establishes an [Action Cable](https://guides.rubyonrails.org/action_cable_overview.html)
 * socket with channel and optional params.
 */
export const useActionCableChannel = <T = object,>(channel: string, params: object = {}) => {
  const consumer = useContext(CableContext)

  const [subscription, setSubscription] = useState<Subscription | null>(null)
  const [lastMessage, setLastMessage] = useState<ChannelMessage<T> | null>(null)
  const [status, setStatus] = useState<ChannelStatus>(null)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const subscription = consumer.subscriptions.create(
      { ...params, channel },
      {
        connected() {
          setStatus('connected')
        },
        disconnected() {
          setStatus('disconnected')
        },
        initialized() {
          setStatus('initialized')
        },
        received(data) {
          setLastMessage({ channel, data, params, receivedAt: new Date() })
        },
        rejected() {
          setStatus('rejected')
        },
      },
    )
    setSubscription(subscription)

    return () => {
      subscription.unsubscribe()

      setLastMessage(null)
      setStatus(null)
      setSubscription(null)
    }
  }, [channel, consumer, params])

  const disconnect = useCallback(() => {
    subscription?.unsubscribe()
  }, [subscription])

  const sendMessage = useCallback(
    (message: object) => subscription?.send(message) ?? false,
    [subscription],
  )

  return { disconnect, lastMessage, sendMessage, status }
}
