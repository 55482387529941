import { CircularProgress, Fade, Stack } from '@mui/material'
import { useRouter } from 'next/router'
import { createContext, type FC, type PropsWithChildren, useCallback,useEffect, useState } from 'react'

export type Callback = (url: string, { shallow }: { shallow?: boolean }) => void

export interface IRoutingSpinnerContext {
  isRouting: boolean
  prependCallback: (newCallback: Callback) => void
  removeCallback: (callback: Callback) => void
}

export const RoutingSpinnerContext = createContext<IRoutingSpinnerContext>({
  isRouting: false,
  prependCallback: (_: Callback) => null,
  removeCallback: (_: Callback) => null,
})

export const RoutingSpinnerProvider: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter()
  const [isRouting, setIsRouting] = useState(false)
  const [listCallbacks, setListCallbacks] = useState<Callback[]>([])

  const prependCallback = useCallback((newCallback: Callback) => {
    setListCallbacks((prevCallbacks) => prevCallbacks.includes(newCallback) ? prevCallbacks : [newCallback, ...prevCallbacks])
  }, [])

  const removeCallback = useCallback((callback: Callback) => {
    setListCallbacks((prevCallbacks) => prevCallbacks.filter(x => x !== callback))
  }, [])

  useEffect(() => {
    function handleRouteChangeStart(_: string, { shallow }: { shallow?: boolean }) {
      if (!shallow) setIsRouting(true)
    }
    setListCallbacks((prevCallbacks) => [...prevCallbacks, handleRouteChangeStart])
    return () => {
      removeCallback(handleRouteChangeStart)
    }
  }, [prependCallback, removeCallback])

  useEffect(() => {
    const handleRouteChangeStart = (url: string, { shallow }: { shallow?: boolean }) => {
      for (const callback of listCallbacks)
        callback(url, { shallow })
    }
    const handleRouteChangeEnd = () => {
      setIsRouting(false)
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeEnd)
    router.events.on('routeChangeError', handleRouteChangeEnd)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeEnd)
      router.events.off('routeChangeError', handleRouteChangeEnd)
    }
  }, [router, listCallbacks])

  return (
    <RoutingSpinnerContext.Provider value={{ isRouting, prependCallback, removeCallback }}>
      <Fade in={isRouting} unmountOnExit>
        <Stack
          alignItems="center"
          justifyContent="center"
          position="fixed"
          top={0}
          right={0}
          bottom={0}
          left={0}
          zIndex={999999999}
          bgcolor="rgba(0, 0, 0, 0.6)"
          style={{ userSelect: 'none' }}
          className="routing-spinner-wrap"
        >
          <CircularProgress size="4rem" data-testid="routing-spinner" />
        </Stack>
      </Fade>
      {children}
    </RoutingSpinnerContext.Provider>
  )
}

export default RoutingSpinnerContext
