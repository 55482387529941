import {
  Commitment,
  GetProgramAccountsFilter,
  PublicKey,
  TransactionSignature,
} from '@solana/web3.js'
import isArray from 'lodash/isArray'
import isEqual from 'lodash/isEqual'
import isFunction from 'lodash/isFunction'
import isPlainObject from 'lodash/isPlainObject'
import { useEffect, useMemo, useRef } from 'react'
import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import { useConnection } from '@/wallet/adapter'

import useHook from './use-hook'

const unsubscriberBySubscriberMap = {
  onAccountChange: 'removeAccountChangeListener',
  onProgramAccountChange: 'removeProgramAccountChangeListener',
  onSignature: 'removeSignatureListener',
} as const

type SubscriptionConfigEntity<E, T> = E | E[] | ((data?: T) => E | E[])

type SubscriptionEntryByMethodMap = {
  onAccountChange: PublicKey
  onProgramAccountChange: PublicKey
  onSignature: TransactionSignature
}

type SubscriptionConfigByMethodMap<T> = {
  onAccountChange: SubscriptionConfigEntity<
    PublicKey | { address: PublicKey; commitment?: Commitment },
    T
  >
  onProgramAccountChange: SubscriptionConfigEntity<
    PublicKey | { address: PublicKey; commitment?: Commitment; filters?: GetProgramAccountsFilter[] },
    T
  >
  onSignature: SubscriptionConfigEntity<
    TransactionSignature | { commitment?: Commitment; signature: TransactionSignature },
    T
  >
}

/**
 * A wrapper of {@link useQuery} that supports subscribing to the RPC socket
 * for automatic refetching.
 *
 * Note that this hook respects {@link useQuery}'s `enabled` option, i.e., if
 * `enabled` is set to `false`, this hook will **skip** all RPC subscriptions.
 */
const useQueryWithSubscription = <
  Method extends keyof typeof unsubscriberBySubscriberMap,
  TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey
>(
  method: Method,
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
    /**
     * A function that will be called before refetching the query. Return
     * `false` from this function to prevent the refetch event.
     */
    onBeforeRefetching?: (
      data: TData | undefined,
      entry: SubscriptionEntryByMethodMap[Method],
    ) => unknown

    /**
     * Specify the address and/or options for subscribing. You may also provide
     * a function that will be called with the latest resolved data to return
     * the addresses and/or options for subscribing (in the case that the list
     * of accounts to be subscribed depends on the result of the query).
     */
    subscriptionOptions?: SubscriptionConfigByMethodMap<TData>[Method]
  } = {},
) => {
  const { onBeforeRefetching, subscriptionOptions, ...useQueryOptions } = options
  const { connection } = useConnection()

  // Store onBeforeRefetching hook method in a reference to always call its
  // latest version!
  const [onBeforeRefetchingRef] = useHook(() => onBeforeRefetching)

  const useQueryResult = useQuery(useQueryOptions)
  const [latestDataRef] = useHook(() => useQueryResult.data)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const lastSubscriptionParams = useRef<any[]>()
  const subscriptionParams = useMemo(() => {
    if (!subscriptionOptions) {
      return
    }

    let params = lastSubscriptionParams.current

    if (isFunction(subscriptionOptions)) {
      const returnedParams = subscriptionOptions(useQueryResult.data)
      params = isArray(returnedParams) ? returnedParams : [returnedParams]
    } else {
      params = isArray(subscriptionOptions) ? subscriptionOptions : [subscriptionOptions]
    }

    if (!isEqual(params, lastSubscriptionParams.current)) {
      lastSubscriptionParams.current = params
    }

    return lastSubscriptionParams.current

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionOptions, useQueryResult.data])

  useEffect(() => {
    if (useQueryOptions.enabled === false) {
      return
    }

    const refetchQuery = useQueryResult.refetch

    const params = subscriptionParams ?? []
    const refetcher = (entry: SubscriptionEntryByMethodMap[Method]) => {
      return () => {
        if (onBeforeRefetchingRef.current?.(latestDataRef.current, entry) !== false) {
          refetchQuery()
        }
      }
    }

    const subscriptions = params.map((param) => {
      const paramIsObject = isPlainObject(param)

      switch (method) {
        case 'onAccountChange':
          return paramIsObject
            ? connection.onAccountChange(param.address, refetcher(param.address), param.commitment)
            : connection.onAccountChange(param, refetcher(param))
        case 'onProgramAccountChange':
          return paramIsObject
            ? connection.onProgramAccountChange(
                param.address,
                refetcher(param.address),
                param.commitment,
                param.filters,
              )
            : connection.onProgramAccountChange(param, refetcher(param))
        case 'onSignature':
          return paramIsObject
            ? connection.onSignature(param.signature, refetcher(param.signature), param.commitment)
            : connection.onSignature(param, refetcher(param))
        default:
          throw new Error(`Unsupported subscription method \`${method}\`.`)
      }
    })

    return () => {
      subscriptions.forEach((subscriptionId) => {
        connection[unsubscriberBySubscriberMap[method]](subscriptionId)
      })
    }
  }, [
    connection,
    latestDataRef,
    method,
    onBeforeRefetchingRef,
    subscriptionParams,
    useQueryOptions.enabled,
    useQueryResult.refetch,
  ])

  return useQueryResult
}

export default useQueryWithSubscription
