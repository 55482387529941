import { useMemo } from 'react'

import CURRENCY_TO_COUNTRIES_MAPPER from '@/constants/currency_to_countries'
import { staticImportIcon } from '@/utils/static-import-icon'

import useTokens, { FiatTokenInfo } from './use-tokens'

const DEFAULT_DECIMALS = 2

const useP2pFiatTokens = () => {
  const { fiatCustomInfoList, fiatTokensList } = useTokens()

  const [fullFiatTokensList, fullFiatCustomInfoList]: [FiatTokenInfo[], FiatTokenInfo[]] = useMemo(() => {
    const tokenList = fiatTokensList.map((token) => ({
      ...token, logoURI: staticImportIcon(token?.fiatIcon || '')?.src,
    } as FiatTokenInfo))
    const customInfoList = fiatCustomInfoList

    Object.keys(CURRENCY_TO_COUNTRIES_MAPPER).map(currency => {
      const usedByCountryCodes =
        CURRENCY_TO_COUNTRIES_MAPPER[currency as keyof typeof CURRENCY_TO_COUNTRIES_MAPPER]
      const representativeCountryCode = usedByCountryCodes[0]

      if (!fiatTokensList.map((fiat) => fiat.fiatCurrency).includes(currency)) {
        const token = {
          address: currency,
          name: currency,
          symbol: currency,
          fiatCurrency: currency,
          logoURI: `/country-flags/${representativeCountryCode}.svg`,
          decimals: DEFAULT_DECIMALS,
          displaySymbol: currency.toUpperCase(),
        } as FiatTokenInfo
        tokenList.push(token)
        customInfoList.push(token)
      }
    })

    return [tokenList, fiatCustomInfoList]
  }, [fiatTokensList, fiatCustomInfoList])

  const fiatTokensMap = useMemo(() => {
    const map = new Map<string, FiatTokenInfo>()
    fullFiatTokensList.forEach((fiatTokenInfo) => {
      map.set(fiatTokenInfo.address, fiatTokenInfo)
    })
    return map
  }, [fullFiatTokensList])

  const fiatCustomInfoMap = useMemo(() => {
    const map = new Map<string, FiatTokenInfo>()
    fullFiatCustomInfoList.forEach((fiatTokenInfo) => {
      map.set(fiatTokenInfo.address, fiatTokenInfo)
    })
    return map
  }, [fullFiatCustomInfoList])

  return {
    fiatTokensList: fullFiatTokensList,
    fiatTokensMap,
    fiatCustomInfoList: fullFiatCustomInfoList,
    fiatCustomInfoMap,
  }
}

export default useP2pFiatTokens
