export const BANKS = {
  'BVBank': {
    'bin': '970454',
    'transacting_name': 'BVBank',
    'display_name_vi': 'Ngân hàng TMCP Bản Việt',
    'display_name_en': 'Viet Capital Commercial Joint Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'KienlongBank': {
    'bin': '970452',
    'transacting_name': 'KienlongBank',
    'display_name_vi': 'Ngân hàng TMCP Kiên Long',
    'display_name_en': 'Kien Long Commercial Joint - Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'SaigonBank': {
    'bin': '970400',
    'transacting_name': 'SaigonBank',
    'display_name_vi': 'Ngân hàng TMCP Sài Gòn Công Thương',
    'display_name_en': 'Sai Gon Joint Stock Commercial Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'PGBank': {
    'bin': '970430',
    'transacting_name': 'PGBank',
    'display_name_vi': 'Ngân Hàng TMCP Thịnh vượng và Phát triển',
    'display_name_en': 'Prosperity and Development Joint Stock Commercial Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'IBK HN': {
    'bin': '970455',
    'transacting_name': 'IBK HN',
    'display_name_vi': 'Ngân hàng Công nghiệp Hàn Quốc - CN Hà Nội',
    'display_name_en': 'Industrial Bank of Korea - Hanoi Branch',
    'supported_fiats': [
      'vnd',
    ],
  },
  'IBK HCM': {
    'bin': '970456',
    'transacting_name': 'IBK HCM',
    'display_name_vi': 'Ngân hàng Công nghiệp Hàn Quốc - CN TP.HCM',
    'display_name_en': 'Industrial Bank of Korea - Ho Chi Minh City Branch',
    'supported_fiats': [
      'vnd',
    ],
  },
  'VRB': {
    'bin': '970421',
    'transacting_name': 'VRB',
    'display_name_vi': 'Ngân hàng Liên doanh Việt - Nga',
    'display_name_en': 'Vietnam - Russia Joint Venture Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'Agribank': {
    'bin': '970405',
    'transacting_name': 'Agribank',
    'display_name_vi': 'Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam',
    'display_name_en': 'Vietnam Bank for Agriculture and Rural Development',
    'supported_fiats': [
      'vnd',
    ],
  },
  'GPBank': {
    'bin': '970408',
    'transacting_name': 'GPBank',
    'display_name_vi': 'Ngân hàng Dầu khí Toàn cầu',
    'display_name_en': 'Global Petro Joint Stock Commercial Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'ACB': {
    'bin': '970416',
    'transacting_name': 'ACB',
    'display_name_vi': 'Ngân hàng TMCP Á Châu',
    'display_name_en': 'Asia Commercial Joint Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'ABBANK': {
    'bin': '970425',
    'transacting_name': 'ABBANK',
    'display_name_vi': 'Ngân hàng TMCP An Bình',
    'display_name_en': 'An Binh Commercial Joint Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'BacABank': {
    'bin': '970409',
    'transacting_name': 'BacABank',
    'display_name_vi': 'Ngân hàng TMCP Bắc Á',
    'display_name_en': 'Bac A Commercial Joint Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'BaoVietBank': {
    'bin': '970438',
    'transacting_name': 'BaoVietBank',
    'display_name_vi': 'Ngân hàng TMCP Bảo Việt',
    'display_name_en': 'Bao Viet Joint Stock Commercial Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'LienVietPostBank': {
    'bin': '970449',
    'transacting_name': 'LienVietPostBank',
    'display_name_vi': 'Ngân hàng TMCP Bưu điện Liên Việt',
    'display_name_en': 'LienViet Post Joint Stock Commercial Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'VietinBank': {
    'bin': '970415',
    'transacting_name': 'VietinBank',
    'display_name_vi': 'Ngân hàng TMCP Công thương Việt Nam',
    'display_name_en': 'Vietnam Joint Stock Commercial Bank For Industry And Trade',
    'supported_fiats': [
      'vnd',
    ],
  },
  'PVcombank': {
    'bin': '970412',
    'transacting_name': 'PVcombank',
    'display_name_vi': 'Ngân hàng TMCP Đại Chúng Việt Nam',
    'display_name_en': 'Vietnam Public Joint Stock Commercial Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'OceanBank': {
    'bin': '970414',
    'transacting_name': 'OceanBank',
    'display_name_vi': 'Ngân hàng TNHH MTV Đại Dương',
    'display_name_en': 'Ocean Commercial One Member Limited Liability Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'BIDV': {
    'bin': '970418',
    'transacting_name': 'BIDV',
    'display_name_vi': 'Ngân hàng TMCP Đầu tư và Phát triển Việt Nam',
    'display_name_en': 'Bank for Investment and Development of Vietnam JSC',
    'supported_fiats': [
      'vnd',
    ],
  },
  'DongABank': {
    'bin': '970406',
    'transacting_name': 'DongABank',
    'display_name_vi': 'Ngân hàng TMCP Đông Á',
    'display_name_en': 'Dong A Commercial Joint Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'SeABank': {
    'bin': '970440',
    'transacting_name': 'SeABank',
    'display_name_vi': 'Ngân Hàng TMCP Đông Nam Á',
    'display_name_en': 'Southeast Asia Commercial Joint Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'MSB': {
    'bin': '970426',
    'transacting_name': 'MSB',
    'display_name_vi': 'Ngân hàng TMCP Hàng Hải Việt Nam',
    'display_name_en': 'Vietnam Maritime Commercial Join Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'Techcombank': {
    'bin': '970407',
    'transacting_name': 'Techcombank',
    'display_name_vi': 'Ngân hàng TMCP Kỹ thương Việt Nam',
    'display_name_en': 'Vietnam Technological and Commercial Joint Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'NamABank': {
    'bin': '970428',
    'transacting_name': 'NamABank',
    'display_name_vi': 'Ngân hàng TMCP Nam Á',
    'display_name_en': 'Nam A Comercial Join Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'HDBank': {
    'bin': '970437',
    'transacting_name': 'HDBank',
    'display_name_vi': 'Ngân hàng TMCP Phát triển TP.HCM',
    'display_name_en': 'Ho Chi Minh City Development Joint Stock Commercial Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'OCB': {
    'bin': '970448',
    'transacting_name': 'OCB',
    'display_name_vi': 'Ngân hàng TMCP Phương Đông',
    'display_name_en': 'Orient Commercial Joint Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'MB': {
    'bin': '970422',
    'transacting_name': 'MB',
    'display_name_vi': 'Ngân hàng TMCP Quân Đội',
    'display_name_en': 'Military Commercial Joint Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'NCB': {
    'bin': '970419',
    'transacting_name': 'NCB',
    'display_name_vi': 'Ngân hàng TMCP Quốc Dân',
    'display_name_en': 'National Citizen Commercial Joint Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'VIB': {
    'bin': '970441',
    'transacting_name': 'VIB',
    'display_name_vi': 'Ngân hàng TMCP Quốc tế Việt Nam',
    'display_name_en': 'Vietnam International Commercial Joint Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'SCB': {
    'bin': '970429',
    'transacting_name': 'SCB',
    'display_name_vi': 'Ngân hàng TMCP Sài Gòn',
    'display_name_en': 'Sai Gon Joint Stock Commercial Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'SHB': {
    'bin': '970443',
    'transacting_name': 'SHB',
    'display_name_vi': 'Ngân hàng TMCP Sài Gòn-Hà Nội',
    'display_name_en': 'Saigon Hanoi Commercial Joint Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'Sacombank': {
    'bin': '970403',
    'transacting_name': 'Sacombank',
    'display_name_vi': 'Ngân hàng TMCP Sài Gòn Thương Tín',
    'display_name_en': 'Sai Gon Thuong Tin Commercial Joint Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'TPBank': {
    'bin': '970423',
    'transacting_name': 'TPBank',
    'display_name_vi': 'Ngân hàng TMCP Tiên Phong',
    'display_name_en': 'Tien Phong Commercial Joint Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'VietABank': {
    'bin': '970427',
    'transacting_name': 'VietABank',
    'display_name_vi': 'Ngân hàng TMCP Việt Á',
    'display_name_en': 'Viet A Commercial Joint Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'VPBank': {
    'bin': '970432',
    'transacting_name': 'VPBank',
    'display_name_vi': 'Ngân hàng TMCP Việt Nam Thịnh Vượng',
    'display_name_en': 'Vietnam Prosperity Joint Stock Commercial Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'VietBank': {
    'bin': '970433',
    'transacting_name': 'VietBank',
    'display_name_vi': 'Ngân hàng TMCP Việt Nam Thương Tín',
    'display_name_en': 'Vietnam Thuong Tin Commercial Joint Stock Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'Eximbank': {
    'bin': '970431',
    'transacting_name': 'Eximbank',
    'display_name_vi': 'Ngân hàng TMCP Xuất Nhập Khẩu Việt Nam',
    'display_name_en': 'Vietnam Commercial Joint Stock Export Import Bank',
    'supported_fiats': [
      'vnd',
    ],
  },
  'IVB': {
    'bin': '970434',
    'transacting_name': 'IVB',
    'display_name_vi': 'Ngân hàng TNHH Indovina',
    'display_name_en': 'Indovina Bank Limited',
    'supported_fiats': [
      'vnd',
    ],
  },
  'CIMB': {
    'bin': '422589',
    'transacting_name': 'CIMB',
    'display_name_vi': 'Ngân hàng TNHH MTV CIMB Việt Nam',
    'display_name_en': 'CIMB Bank Vietnam Limited',
    'supported_fiats': [
      'vnd',
    ],
  },
  'PBVN': {
    'bin': '970439',
    'transacting_name': 'PBVN',
    'display_name_vi': 'Ngân hàng TNHH MTV Public Việt Nam',
    'display_name_en': 'Public Bank Vietnam Limited',
    'supported_fiats': [
      'vnd',
    ],
  },
  'SHBVN': {
    'bin': '970424',
    'transacting_name': 'SHBVN',
    'display_name_vi': 'Ngân hàng TNHH MTV Shinhan Việt Nam',
    'display_name_en': 'Shinhan Bank Vietnam Limited',
    'supported_fiats': [
      'vnd',
    ],
  },
  'UOB': {
    'bin': '970458',
    'transacting_name': 'UOB',
    'display_name_vi': 'Ngân hàng TNHH MTV United Overseas Bank Việt Nam',
    'display_name_en': 'United Overseas Bank Vietnam Limited',
    'supported_fiats': [
      'vnd',
    ],
  },
  'Wooribank': {
    'bin': '970457',
    'transacting_name': 'Wooribank',
    'display_name_vi': 'Ngân hàng TNHH MTV Woori Việt Nam',
    'display_name_en': 'Woori Bank Vietnam Limited',
    'supported_fiats': [
      'vnd',
    ],
  },
  'Vietcombank': {
    'bin': '970436',
    'transacting_name': 'Vietcombank',
    'display_name_vi': 'Ngân hàng TMCP Ngoại thương Việt Nam',
    'display_name_en': 'Joint Stock Commercial Bank for Foreign Trade of Vietnam',
    'supported_fiats': [
      'vnd',
    ],
  },
  'KBank': {
    'bin': '668888',
    'transacting_name': 'KBank',
    'display_name_vi': 'Ngân hàng Đại chúng TNHH KASIKORNBANK',
    'display_name_en': 'KASIKORNBANK Public Company Limited',
    'supported_fiats': [
      'vnd',
    ],
  },
  'CBBank': {
    'bin': '970444',
    'transacting_name': 'CBBank',
    'display_name_vi': 'Ngân hàng TM TNHH MTV Xây dựng Việt Nam',
    'display_name_en': 'Vietnam Construction Commercial Bank Limited',
    'supported_fiats': [
      'vnd',
    ],
  },
  'DBS': {
    'bin': '796500',
    'transacting_name': 'DBS',
    'display_name_vi': 'Ngân hàng TNHH MTV Phát triển Singapore - CN TP.HCM',
    'display_name_en': 'DBS Bank Limited - Ho Chi Minh City Branch',
    'supported_fiats': [
      'vnd',
    ],
  },
  'HLBVN': {
    'bin': '970442',
    'transacting_name': 'HLBVN',
    'display_name_vi': 'Ngân hàng TNHH MTV Hong Leong Việt Nam',
    'display_name_en': 'Hong Leong Bank Vietnam Limited',
    'supported_fiats': [
      'vnd',
    ],
  },
  'HSBC': {
    'bin': '458761',
    'transacting_name': 'HSBC',
    'display_name_vi': 'Ngân hàng TNHH MTV HSBC Việt Nam',
    'display_name_en': 'HSBC Bank Vietnam Limited',
    'supported_fiats': [
      'vnd',
    ],
  },
  'NHB VN': {
    'bin': '801011',
    'transacting_name': 'NHB VN',
    'display_name_vi': 'Ngân hàng Nonghyup - CN Hà Nội',
    'display_name_en': 'Nonghyup Bank - Hanoi Branch',
    'supported_fiats': [
      'vnd',
    ],
  },
  'SCVN': {
    'bin': '970410',
    'transacting_name': 'SCVN',
    'display_name_vi': 'Ngân hàng TNHH MTV Standard Chartered Bank Việt Nam',
    'display_name_en': 'Standard Chartered Vietnam Limited',
    'supported_fiats': [
      'vnd',
    ],
  },
  'VBSP': {
    'bin': '999888',
    'transacting_name': 'VBSP',
    'display_name_vi': 'Ngân hàng Chính sách Xã hội',
    'display_name_en': 'Vietnam Bank for Social Policies',
    'supported_fiats': [
      'vnd',
    ],
  },
  'KB HCM': {
    'bin': '970463',
    'transacting_name': 'KB HCM',
    'display_name_vi': 'Ngân hàng Kookmin - CN TP.HCM',
    'display_name_en': 'Kookmin Bank - Ho Chi Minh City Branch',
    'supported_fiats': [
      'vnd',
    ],
  },
  'KB HN': {
    'bin': '970462',
    'transacting_name': 'KB HN',
    'display_name_vi': 'Ngân hàng Kookmin - CN Hà Nội',
    'display_name_en': 'Kookmin Bank - Hanoi Branch',
    'supported_fiats': [
      'vnd',
    ],
  },
  'KEB HANA HCM': {
    'bin': '970466',
    'transacting_name': 'KEB HANA HCM',
    'display_name_vi': 'Ngân hàng KEB Hana - CN TP.HCM',
    'display_name_en': 'KEB Hana Bank - Ho Chi Minh City Branch',
    'supported_fiats': [
      'vnd',
    ],
  },
  'KEB HANA HN': {
    'bin': '970467',
    'transacting_name': 'KEB HANA HN',
    'display_name_vi': 'Ngân hàng KEB Hana - CN Hà Nội',
    'display_name_en': 'KEB Hana Bank - Hanoi Branch',
    'supported_fiats': [
      'vnd',
    ],
  },
  'MAFC': {
    'bin': '977777',
    'transacting_name': 'MAFC',
    'display_name_vi': 'Công ty Cổ phần Chứng khoán Mirae Asset Việt Nam',
    'display_name_en': 'Mirae Asset Finance Company Vietnam Limited',
    'supported_fiats': [
      'vnd',
    ],
  },
  'COOPBANK': {
    'bin': '970446',
    'transacting_name': 'COOPBANK',
    'display_name_vi': 'Ngân hàng Hợp tác xã Việt Nam',
    'display_name_en': 'Co-operative Bank of Vietnam',
    'supported_fiats': [
      'vnd',
    ],
  },
  // 'FairMoney': {
  //   'transacting_name': 'FairMoney',
  //   'display_name_vi': 'Fairmoney Microfinance Bank',
  //   'display_name_en': 'Fairmoney Microfinance Bank',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  'Providus Bank': {
    'transacting_name': 'Providus Bank',
    'display_name_vi': 'ProvidusBank PLC',
    'display_name_en': 'ProvidusBank PLC',
    'supported_fiats': [
      'ngn',
    ],
  },
  // 'Sparkle': {
  //   'transacting_name': 'Sparkle',
  //   'display_name_vi': 'Sparkle Microfinance Bank',
  //   'display_name_en': 'Sparkle Microfinance Bank',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  // 'SunTrust Bank': {
  //   'transacting_name': 'SunTrust Bank',
  //   'display_name_vi': 'SunTrust Bank Ltd',
  //   'display_name_en': 'SunTrust Bank Ltd',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  'Moniepoint': {
    'transacting_name': 'Moniepoint',
    'display_name_vi': 'Moniepoint Inc.',
    'display_name_en': 'Moniepoint Inc.',
    'supported_fiats': [
      'ngn',
    ],
  },
  // 'VBank': {
  //   'transacting_name': 'VBank',
  //   'display_name_vi': 'VFD Microfinance Bank',
  //   'display_name_en': 'VFD Microfinance Bank',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  // 'Palmpay': {
  //   'transacting_name': 'Palmpay',
  //   'display_name_vi': 'PalmPay Limited',
  //   'display_name_en': 'PalmPay Limited',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  'Access Bank': {
    'transacting_name': 'Access Bank',
    'display_name_vi': 'Access Bank PLC',
    'display_name_en': 'Access Bank PLC',
    'supported_fiats': [
      'ngn',
    ],
  },
  // 'Eyowo': {
  //   'transacting_name': 'Eyowo',
  //   'display_name_vi': 'Eyowo Microfinance Bank',
  //   'display_name_en': 'Eyowo Microfinance Bank',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  // 'Globus': {
  //   'transacting_name': 'Globus',
  //   'display_name_vi': 'Globus Bank Ltd',
  //   'display_name_en': 'Globus Bank Ltd',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  // 'Heritage Bank': {
  //   'transacting_name': 'Heritage Bank',
  //   'display_name_vi': 'Heritage Bank PLC',
  //   'display_name_en': 'Heritage Bank PLC',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  // 'Jaiz Bank': {
  //   'transacting_name': 'Jaiz Bank',
  //   'display_name_vi': 'Jaiz Bank PLC',
  //   'display_name_en': 'Jaiz Bank PLC',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  // 'Mintyn': {
  //   'transacting_name': 'Mintyn',
  //   'display_name_vi': 'Finex Microfinance Bank Ltd',
  //   'display_name_en': 'Finex Microfinance Bank Ltd',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  // 'Paga': {
  //   'transacting_name': 'Paga',
  //   'display_name_vi': 'Paga Group Ltd',
  //   'display_name_en': 'Paga Group Ltd',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  // 'StanbicIBTC Bank': {
  //   'transacting_name': 'StanbicIBTC Bank',
  //   'display_name_vi': 'Stanbic IBTC Bank PTC',
  //   'display_name_en': 'Stanbic IBTC Bank PTC',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  // 'ALAT': {
  //   'transacting_name': 'ALAT',
  //   'display_name_vi': 'ALAT By Wema Bank',
  //   'display_name_en': 'ALAT By Wema Bank',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  'GTBank': {
    'transacting_name': 'GTBank',
    'display_name_vi': 'Guaranty Trust Bank PLC',
    'display_name_en': 'Guaranty Trust Bank PLC',
    'supported_fiats': [
      'ngn',
    ],
  },
  'FCMB': {
    'transacting_name': 'FCMB',
    'display_name_vi': 'First City Monument Bank Ltd',
    'display_name_en': 'First City Monument Bank',
    'supported_fiats': [
      'ngn',
    ],
  },
  // 'Carbon': {
  //   'transacting_name': 'Carbon',
  //   'display_name_vi': 'Carbon Microfinance Bank',
  //   'display_name_en': 'Carbon Microfinance Bank',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  // 'Ecobank': {
  //   'transacting_name': 'Ecobank',
  //   'display_name_vi': 'Ecobank Nigeria Ltd',
  //   'display_name_en': 'Ecobank Nigeria Ltd',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  'First Bank': {
    'transacting_name': 'First Bank',
    'display_name_vi': 'First Bank of Nigeria Ltd',
    'display_name_en': 'First Bank of Nigeria Ltd',
    'supported_fiats': [
      'ngn',
    ],
  },
  // 'Keystone Bank': {
  //   'transacting_name': 'Keystone Bank',
  //   'display_name_vi': 'Keystone Bank Ltd',
  //   'display_name_en': 'Keystone Bank Ltd',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  'OPay': {
    'transacting_name': 'OPay',
    'display_name_vi': 'OPay Digital Services Ltd',
    'display_name_en': 'OPay Digital Services Ltd',
    'supported_fiats': [
      'ngn',
    ],
  },
  // 'Rubies': {
  //   'transacting_name': 'Rubies',
  //   'display_name_vi': 'Rubies Microfinance Bank',
  //   'display_name_en': 'Rubies Microfinance Bank',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  // 'Union Bank': {
  //   'transacting_name': 'Union Bank',
  //   'display_name_vi': 'Union Bank of Nigeria PLC',
  //   'display_name_en': 'Union Bank of Nigeria PLC',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  'UBA': {
    'transacting_name': 'UBA',
    'display_name_vi': 'United Bank for Africa PLC',
    'display_name_en': 'United Bank for Africa PLC',
    'supported_fiats': [
      'ngn',
    ],
  },
  'Zenith Bank': {
    'transacting_name': 'Zenith Bank',
    'display_name_vi': 'Zenith Bank PLC',
    'display_name_en': 'Zenith Bank PLC',
    'supported_fiats': [
      'ngn',
    ],
  },
  // 'Fidelity Bank': {
  //   'transacting_name': 'Fidelity Bank',
  //   'display_name_vi': 'Fidelity Bank PLC',
  //   'display_name_en': 'Fidelity Bank PLC',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  // 'Polaris Bank': {
  //   'transacting_name': 'Polaris Bank',
  //   'display_name_vi': 'Polaris Bank Ltd',
  //   'display_name_en': 'Polaris Bank Ltd',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  // 'Wema Bank': {
  //   'transacting_name': 'Wema Bank',
  //   'display_name_vi': 'Wema Bank PLC',
  //   'display_name_en': 'Wema Bank PLC',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  // 'Sterling Bank': {
  //   'transacting_name': 'Sterling Bank',
  //   'display_name_vi': 'Sterling Bank Ltd',
  //   'display_name_en': 'Sterling Bank Ltd',
  //   'short_name': 'Sterling Bank',
  //   'supported_fiats': [
  //     'ngn',
  //   ],
  // },
  'Kuda': {
    'transacting_name': 'Kuda',
    'display_name_vi': 'Kuda Microfinance Bank',
    'display_name_en': 'Kuda Microfinance Bank',
    'supported_fiats': [
      'ngn',
    ],
  },
}
