import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import cls from 'classnames'
import Image from 'next/image'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'

import BaseDialog from '@/components/base/BaseDialog'
import Button from '@/components/base/Button'
import PencilOutlined from '@/components/base/icons/PencilOutlined'
import SelectedTopRightRounded from '@/components/base/icons/SelectedTopRightRounded'
import TokenPicker from '@/components/base/TokenPicker'
import { isInstantWireMode } from '@/constants/index'
import useFiatCustomInfoList from '@/hooks/use-fiat-custom-info-list'
import useTrans from '@/hooks/useTrans'

import { usePaymentInfo } from '..'
import { DialogSelectFormViewProps } from './types'

const View = ({
  methods,
  isOpened,
  handleAddNewMethod,
  handleClose,
  handleEditMethod,
  handleSelectMethod,
}: DialogSelectFormViewProps) => {
  const { isFixedFiatToken, fiatToken, setFiatToken } = usePaymentInfo()
  const { fiatCustomInfoList } = useFiatCustomInfoList()
  const trans = useTrans()

  const [selectedIndex, setSelectedIndex] = useState<number>()

  const selectedMethodIndex = useMemo(() => {
    let selectedIndex: number | undefined
    methods.forEach((method, idx) => {
      if (method.selected) {
        selectedIndex = idx
      }
    })
    return selectedIndex
  }, [methods])

  useEffect(() => {
    setSelectedIndex(selectedMethodIndex)
  }, [selectedMethodIndex])

  const handleSubmitMethod = useCallback(() => {
    if (selectedIndex !== undefined) {
      handleSelectMethod(selectedIndex)
      handleClose()
    }
  }, [handleClose, handleSelectMethod, selectedIndex])

  const isSelected = useCallback(
    (index: number) => {
      return index === selectedIndex
    },
    [selectedIndex],
  )

  return (
    <BaseDialog
      useDrawerOnMobile
      isOpened={isOpened}
      title={trans.sell.choose_a_method}
      handleClose={handleClose}
      body={
        <Stack spacing={2} data-testid="payment-method-dialog">
          {!isFixedFiatToken && (
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
              <span className="text-base text-white iw:text-[#000E0F]">{trans.common.select_fiat}</span>
              <TokenPicker
                tokens={fiatCustomInfoList}
                selectedToken={fiatToken}
                isShowSearch={true}
                isDisplayAddress={false}
                setSelectedToken={(token) => setFiatToken(token)}
                nickName="fiat"
                variant="primary"
                data-testid="payment-info-fiat"
                emptySelectionText={trans.common.select_a_fiat}
                isDisplayIcon={!!fiatToken}
                isShowDialog
                boxProps={{
                  className: 'cursor-pointer bg-[#2E3846] iw:border iw:border-solid iw:border-[#DFF1FF] iw:bg-[#F6FBFF] iw:text-[#000E0F]',
                }}
              />
            </Stack>
          )}

          {!methods.length && (
            <>
              <Stack className="pt-4 pb-2" data-testid="no-payment-method" alignItems="center">
                <Image src="/icon-transaction.png" alt="transaction" height={100} width={100} />
              </Stack>
              <span className="text-sm text-[#9CA3AE] iw:text-[#666] text-center">
                {trans.sell.you_dont_have_any_payment_methods}
              </span>
            </>
          )}

          {methods.map((method, idx) => (
            <Stack
              data-testid={`select-method-${idx}`}
              data-selected={isSelected(idx)}
              key={idx}
              spacing={1.25}
              className={cls(
                'relative text-sm rounded-lg px-4 py-5 cursor-pointer',
                'bg-[#2E3846] iw:bg-[#F6FBFF]',
                'text-white iw:text-[#000E0F]',
              )}
              onClick={() => setSelectedIndex(idx)}
            >
              <Stack direction="row" justifyContent="space-between" spacing={1} alignItems="center">
                <span className="font-semibold text-sm" data-testid={`method-${idx}-payment-type`}>
                  {method.isZelle ? 'Zelle' : trans.sell.bank_account}
                </span>
                <Button
                  data-testid={`btn-edit-method-${idx}`}
                  size="sm"
                  variant="outlined"
                  onClick={(event) => {
                    event.stopPropagation()
                    handleEditMethod(idx)
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <PencilOutlined
                      height={16}
                      width={16}
                      fill={isInstantWireMode() ? '#1F5F8D' : '#C2CBD9'}
                    />
                    <span className="text-[#C2CBD9] iw:text-[#1F5F8D] text-xs">
                      {trans.common.update}
                    </span>
                  </Stack>
                </Button>
              </Stack>

              <div className="border-t border-solid border-[#61667E] iw:border-[#C6C6C6]" />

              <Stack spacing={1.5}>
                {!method.isZelle && (
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <span className="text-[#9CA3AE] iw:text-[#666]">{trans.sell.bank_name}</span>
                    <span className="font-semibold text-right" data-testid={`method-${idx}-name`}>
                      {method.bankName}
                    </span>
                  </Stack>
                )}
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <span className="text-[#9CA3AE] iw:text-[#666]">
                    {method.isZelle ? trans.sell.phone_number : trans.sell.bank_account_number}
                  </span>
                  <span className="font-semibold text-right" data-testid={`method-${idx}-account-number`}>
                    {method.bankAccountNumber}
                  </span>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <span className="text-[#9CA3AE] iw:text-[#666]">
                    {method.isZelle ? trans.sell.email : trans.sell.bank_account_name}
                  </span>
                  <span className="font-semibold text-right" data-testid={`method-${idx}-account-name`}>
                    {method.bankAccountName}
                  </span>
                </Stack>
              </Stack>

              <Box
                className={cls(
                  'absolute inset-0 !m-0 rounded-lg pointer-events-none',
                  isSelected(idx) && 'border-[2px] border-solid border-[#00BD90] iw:border-[#55B964]',
                  !isSelected(idx) && 'iw:border iw:border-solid iw:border-[#DFF1FF]',
                )}
              >
                {isSelected(idx) && (
                  <Box className="absolute -top-[2px] -right-[2px]">
                    <SelectedTopRightRounded />
                  </Box>
                )}
              </Box>
            </Stack>
          ))}
        </Stack>
      }
      buttonDirection="row"
      buttons={
        methods.length
          ? [
              <Button
                key="add"
                variant="outlined"
                className="w-2/5"
                data-testid="btn-add-new-method"
                onClick={handleAddNewMethod}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <AddIcon fontSize="small" />
                  <span>{trans.common.add_new}</span>
                </Stack>
              </Button>,
              <Button
                key="close"
                variant="primary"
                className="w-3/5"
                data-testid="btn-save-payment-info"
                disabled={selectedIndex === undefined}
                onClick={handleSubmitMethod}
              >
                {trans.common.save}
              </Button>,
            ]
          : [
              <Button
                key="add"
                variant="outlined"
                className="w-2/5"
                data-testid="btn-close-select-dialog"
                onClick={handleClose}
              >
                {trans.common.close}
              </Button>,
              <Button
                key="close"
                variant="primary"
                className="w-3/5"
                data-testid="btn-add-new-method"
                onClick={handleAddNewMethod}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <AddIcon fontSize="small" />
                  <span>{trans.common.add_new}</span>
                </Stack>
              </Button>,
            ]
      }
    />
  )
}

const memoView = memo(View)
memoView.displayName = 'DialogSelectForm'
export default memoView
