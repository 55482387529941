import { isInstantWireMode } from '@/constants/index'

export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || ''

const EVENT_NAMES_PREFIX = isInstantWireMode() ? 'instantwire_' : 'nemo_swap_'

export const EVENT_NAMES = {
  // sell form events
  sell_select_from_token: `${EVENT_NAMES_PREFIX}sell_select_from_token`, // args: token_name
  sell_connect_wallet_on_form: `${EVENT_NAMES_PREFIX}sell_connect_wallet_on_form`, // inside dialog
  sell_submit: `${EVENT_NAMES_PREFIX}sell_submit`, // args: from_amount, to_amount, from_token, to_token
  sell_update_bank: `${EVENT_NAMES_PREFIX}sell_update_bank`, // inside dialog
  sell_update_contact_email: `${EVENT_NAMES_PREFIX}sell_update_contact_email`, // inside dialog
  sell_update_contact_phone: `${EVENT_NAMES_PREFIX}sell_update_contact_phone`, // inside dialog
  sell_update_contact_telegram: `${EVENT_NAMES_PREFIX}sell_update_contact_telegram`, // inside dialog
  sell_update_contact_whatsapp: `${EVENT_NAMES_PREFIX}sell_update_contact_whatsapp`, // inside dialog

  // page events
  page_select_header_menu: `${EVENT_NAMES_PREFIX}page_select_header_menu`, // args: menu_name
  page_connect_wallet_on_header: `${EVENT_NAMES_PREFIX}page_connect_wallet_on_header`,
  page_disconnect_wallet: `${EVENT_NAMES_PREFIX}page_disconnect_wallet`,
  page_select_language: `${EVENT_NAMES_PREFIX}page_select_language`, // args: lang

  // swap form events
  swap_select_max_amount: `${EVENT_NAMES_PREFIX}swap_select_max_amount`,
  swap_select_from_token: `${EVENT_NAMES_PREFIX}swap_select_from_token`, // args: token_name
  swap_select_to_token: `${EVENT_NAMES_PREFIX}swap_select_to_token`, // args: token_name
  swap_switch_token: `${EVENT_NAMES_PREFIX}swap_switch_token`, // args: from_token, to_token
  swap_submit: `${EVENT_NAMES_PREFIX}swap_submit`, // args: from_amount, to_amount, from_token, to_token
  swap_connect_wallet_on_form: `${EVENT_NAMES_PREFIX}swap_connect_wallet_on_form`,

  // buy events
  buy_select_to_token: `${EVENT_NAMES_PREFIX}buy_select_to_token`, // args: token_name
  buy_select_trade: `${EVENT_NAMES_PREFIX}buy_select_trade`, // args: trade_id
  buy_connect_wallet_on_list: `${EVENT_NAMES_PREFIX}buy_connect_wallet_on_list`, // args: trade_id
  buy_trade_show_bank_info: `${EVENT_NAMES_PREFIX}buy_trade_show_bank_info`, // args: trade_id
  buy_trade_hide_bank_info: `${EVENT_NAMES_PREFIX}buy_trade_hide_bank_info`, // args: trade_id
  buy_trade_add_evidence: `${EVENT_NAMES_PREFIX}buy_trade_add_evidence`, // args: trade_id, file_names
  buy_trade_request_arbitrator: `${EVENT_NAMES_PREFIX}buy_trade_request_arbitrator`, // args: trade_id
  buy_trade_contact_support: `${EVENT_NAMES_PREFIX}buy_trade_contact_support`, // args: trade_id
  buy_search_input_amount: `${EVENT_NAMES_PREFIX}buy_search_input_amount`, // args: input_amount, token_symbol
  buy_offer_submit: `${EVENT_NAMES_PREFIX}buy_offer_submit`, // args: from_amount, to_amount, from_token, to_token

  // deposit / liquidity
  liquidity_create_open: `${EVENT_NAMES_PREFIX}liquidity_create_open`,
  liquidity_create_submit: `${EVENT_NAMES_PREFIX}liquidity_create_submit`, // args: from_token, to_token, tick_index
  liquidity_create_connect_wallet_on_form: `${EVENT_NAMES_PREFIX}liquidity_create_connect_wallet_on_form`, // button disabled when wallet not connected
  liquidity_list_select: `${EVENT_NAMES_PREFIX}liquidity_list_select`, // args: from_token, to_token, pool_address
  liquidity_deposit_revert_price: `${EVENT_NAMES_PREFIX}liquidity_deposit_revert_price`, // args: from_token, to_token
  liquidity_deposit_submit: `${EVENT_NAMES_PREFIX}liquidity_deposit_submit`, //!btn-confirm-deposit args: from_token, to_token, min_price, max_price, from_amount, to_amount
  liquidity_deposit_connect_wallet_on_form: `${EVENT_NAMES_PREFIX}sell_connect_wallet_on_form`, //!btn-confirm-deposit
  liquidity_portfolio_stop: `${EVENT_NAMES_PREFIX}liquidity_portfolio_stop`, //!btn-stop-position args: position_address

  // p2p / buy
  p2p_buy_connect_wallet_on_detail: `${EVENT_NAMES_PREFIX}p2p_buy_connect_wallet_on_detail`,
  p2p_dummy_offer_user_buying_click: `${EVENT_NAMES_PREFIX}p2p_dummy_offer_user_buying_click`,
  p2p_dummy_offer_user_selling_click: `${EVENT_NAMES_PREFIX}p2p_dummy_offer_user_selling_click`,
  leader_board_top_banner: `${EVENT_NAMES_PREFIX}leader_board_top_banner`,
}
