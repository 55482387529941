import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useCallback, useContext } from 'react'

import { type CustomTokenInfo } from '@/utils/helpers'

import TokenListContext from '../context'

const RecentTokenItem = ({ token }: { token: CustomTokenInfo }) => {
  const { handleClose, handleTokenSelect, selectedToken } = useContext(TokenListContext)

  const handleClick = useCallback(() => {
    selectedToken?.address === token.address ? handleClose() : handleTokenSelect(token)
  }, [selectedToken, token, handleClose, handleTokenSelect])

  return (
    <Stack
      direction="row"
      alignItems="center"
      className="bg-[#141A23] px-3 py-2 rounded-lg cursor-pointer hover:bg-white/10 iw:bg-[#DFF1FF] iw:hover:bg-[#F6FBFF]"
      gap={1}
      onClick={handleClick}
      data-testid={`recent-token-item-${token.symbol}`}
    >
      <Box>
        <img src={token.logoURI || ''} alt={token.name} className="max-w-full h-[24px] w-[24px]" />
      </Box>
      <Typography className="font-semibold text-sm">{token.displaySymbol}</Typography>
    </Stack>
  )
}

export default RecentTokenItem
