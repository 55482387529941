'use client'

import useLocalStorage from '@/hooks/useLocalStorage'

import { useCountry } from './use-country'

const useDefaultFiatAddressByCountry = () => {
  const [storedValue, setValueToStorage] = useLocalStorage('default_fiat_address', '')

  const  { fiatTokenAddress: defaultFiatCurrencyAddress  } = useCountry(!storedValue)

  const fiatTokenAddress = storedValue || defaultFiatCurrencyAddress

  return {
    fiatTokenAddress,
    fiatCurrencyAddress: defaultFiatCurrencyAddress,
    setFiatTokenAddress: setValueToStorage,
  }
}

export default useDefaultFiatAddressByCountry
