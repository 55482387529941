import { DecimalUtil } from '@orca-so/common-sdk'
import {
  IOfferDataInfo,
  ITradeDataInfo,
  OfferDataInfo,
  OfferState,
  TradeSide,
  TradeState,
} from '@renec-foundation/escrow-sdk'
import { Decimal } from 'decimal.js'
import isEqual from 'lodash/isEqual'
import moment from 'moment'

import { A_HUNDRED_PERCENT, TRADE_ORDER_STATUS } from '@/constants/index'

export type OfferStatusBadge =
  | 'fulfilled'
  | 'canceled'
  | 'in_progress'
  | 'buyer_awaiting'
  | 'seller_awaiting'
  | 'not_applicable'

export const OfferStatusBadgeColor: Record<OfferStatusBadge, Record<'text' | 'bg', string>> = {
  fulfilled: { text: 'text-[#21D969]', bg: 'bg-[#21D969]/10' },
  canceled: { text: 'text-[#EE441F]', bg: 'bg-[#FF6701]/[.18]' },
  in_progress: { text: 'text-[#FF6701]', bg: 'bg-[#FF6701]/10' },
  buyer_awaiting: { text: 'text-[#FF6701]', bg: 'bg-[#FF6701]/10' },
  seller_awaiting: { text: 'text-[#FF6701]', bg: 'bg-[#FF6701]/10' },
  not_applicable: { text: 'text-[#F41915]', bg: 'bg-[#F41915]/[0.08]' },
}

export const isSellingOfferBySide = (side: OfferDataInfo['side']) => {
  return isEqual(side, TradeSide.Sell)
}

export const isOfferCanceled = (offerState: OfferDataInfo['state']) => {
  return isEqual(offerState, OfferState.Canceled)
}

export const isOfferOutOfFund = (offerState: OfferDataInfo['state']) => {
  return isEqual(offerState, OfferState.OutOfFund)
}

export const isOfferAwaiting = (transformedOfferState: string) => {
  return transformedOfferState === TRADE_ORDER_STATUS.awaiting
}

export const isOfferAvailableToJoin = (offerData: OfferDataInfo) => {
  const isAwaiting = isEqual(offerData.state, OfferState.Awaiting)
  const isNotFullyFilledTotalAmount = offerData.filledAmount.lt(offerData.totalAmount)
  const isAmountRemaining = offerData.availableAmount.gtn(0)
  return isAwaiting && isNotFullyFilledTotalAmount && isAmountRemaining
}

export const isOfferOngoing = (offerData: OfferDataInfo) => {
  return offerData.pendingAmount.gtn(0)
}

export const isOfferActive = (offerData: OfferDataInfo) => {
  return isOfferAvailableToJoin(offerData) || isOfferOngoing(offerData)
}

export const isOfferEmpty = (offerData: OfferDataInfo) => {
  return offerData.availableAmount.eqn(0)
}

export const isOfferFulfilled = (offerData: OfferDataInfo) => {
  const isStateFulfilled = isEqual(offerData.state, OfferState.Fulfilled)
  const isAmountFulfilled = offerData.pendingAmount.eqn(0) && isOfferEmpty(offerData)
  return isStateFulfilled || isAmountFulfilled
}

export const isOfferNotApplicable = (offerData: OfferDataInfo) => {
  const availableAmount = DecimalUtil.fromU64(offerData.availableAmount)
  const minTradeSizeInRate = DecimalUtil.fromU64(offerData.minTradeSize).div(A_HUNDRED_PERCENT)
  const minimumOfferAmount = DecimalUtil.fromU64(offerData.totalAmount).mul(minTradeSizeInRate)

  return offerData.pendingAmount.eqn(0) && !isOfferEmpty(offerData) && availableAmount.lt(minimumOfferAmount)
}

export const isOfferCancellable = (offerData: OfferDataInfo) => {
  const hasNoActiveTrade = offerData.availableAmount.eq(offerData.totalAmount)
  return isOfferAvailableToJoin(offerData) && !isOfferOngoing(offerData) && hasNoActiveTrade
}

export const getOfferActiveTrade = (trades: ITradeDataInfo[]) => {
  return trades.find(trade => !isEqual(trade.state, TradeState.Canceled))
}

export const getOfferActiveTradeList = (trades: ITradeDataInfo[]) => {
  return trades.filter(trade => !isEqual(trade.state, TradeState.Canceled))
}

export const isAllTradesReleased = (trades: ITradeDataInfo[]) => {
  if (!trades.length) return false
  return trades.every(trade => isEqual(trade.state, TradeState.Released))
}

export const hasActiveTrades = (offerData?: OfferDataInfo | null) => {
  if (!offerData) return false

  return offerData.availableAmount.lt(offerData.totalAmount)
}

export const isTradeExpiredTimeUploadProof = (createdAt: number, durationInMin = 15) => {
  const durationInMs = Decimal.mul(durationInMin, 60).mul(1000).toNumber()
  const expiredAt = moment(createdAt).add(durationInMs, 'milliseconds')

  return moment().isAfter(moment(expiredAt))
}

export const computeOfferStatusBadge = (offerData: OfferDataInfo): OfferStatusBadge => {
  if (isOfferCanceled(offerData.state)) {
    return 'canceled'
  }

  if (isOfferFulfilled(offerData)) {
    return 'fulfilled'
  }

  if (isOfferNotApplicable(offerData)) {
    return 'not_applicable'
  }

  if (isOfferOngoing(offerData)) {
    return 'in_progress'
  }

  return isSellingOfferBySide(offerData.side) ? 'buyer_awaiting' : 'seller_awaiting'
}

export const computeOfferStatus = (
  offerData: OfferDataInfo | null | undefined,
  offerTrades: ITradeDataInfo[] | undefined,
  isFetchingOfferTrades: boolean,
) => {
  if (!offerData) return ''

  const isCanceled =  isOfferCanceled(offerData.state)
  if (isCanceled) return TRADE_ORDER_STATUS.canceled

  const isOutOfFund = isOfferOutOfFund(offerData.state)
  const isFulFilledByAmount = (
    isOutOfFund
    && offerTrades?.length
    && !isFetchingOfferTrades
    && (
      isAllTradesReleased(offerTrades)
      || offerData.filledAmount.eq(offerData.totalAmount)
    )
  )
  const isFulfilledByState = isOfferFulfilled(offerData)

  if (isFulFilledByAmount || isFulfilledByState) {
    return TRADE_ORDER_STATUS.released
  }

  if (offerTrades?.length) {
    return TRADE_ORDER_STATUS.deposited
  }

  return TRADE_ORDER_STATUS.awaiting
}

export const getOfferDetailUrl = (offerData: IOfferDataInfo | OfferDataInfo) => {
  if ('version' in offerData) {
    return `/p2p/offer/${offerData.offerId}?v=${offerData.version}`
  }

  return `/p2p/offer/${offerData.offerId}`
}

export const isOfferData = (entity: unknown): entity is IOfferDataInfo => {
  return typeof entity === 'object' &&
    entity !== null &&
    'offerId' in entity &&
    typeof entity.offerId === 'object'
}

export const isEntityActive = (entity: ITradeDataInfo | IOfferDataInfo) => {
  if ('tradeId' in entity) {
    return !isEqual(entity.state, TradeState.Released) && !isEqual(entity.state, TradeState.Canceled)
  }

  return isOfferActive(entity)
}
