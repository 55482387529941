import cloneDeep from 'lodash/cloneDeep'
import flatten from 'lodash/flatten'

class DateRange {
  private constructor(private sequences: [Date, Date][] = []) {}

  static emptyRange() {
    return new DateRange()
  }

  static fromDates(from: string | number | Date, to: string | number | Date) {
    from = new Date(from)
    to = new Date(to)
    return new DateRange(from.getTime() <= to.getTime() ? [[from, to]] : [])
  }

  get empty() {
    return !this.sequences.length
  }

  get continuous() {
    return this.sequences.length <= 1
  }

  get subRanges() {
    return this.sequences.map((sequence) => new DateRange([sequence]))
  }

  get intermediateRanges() {
    const ranges: DateRange[] = []
    this.sequences.slice(1).forEach((sequence, idx) => {
      ranges.push(
        DateRange.fromDates(this.sequences[idx][1].getTime() + 1, sequence[0].getTime() - 1),
      )
    })
    return ranges
  }

  get from() {
    return this.empty ? null : this.sequences[0][0]
  }

  get to() {
    return this.empty ? null : this.sequences[this.sequences.length - 1][1]
  }

  /**
   * Adds a range to the current range and returns a new united range.
   */
  include(range: DateRange) {
    const allSequences = cloneDeep([...this.sequences, ...range.sequences])
    allSequences.sort(
      (pairA, pairB) =>
        pairA[0].getTime() - pairB[0].getTime() || pairB[1].getTime() - pairB[0].getTime(),
    )

    if (!allSequences.length) {
      return this
    }

    const mergedSequences = [allSequences[0]]
    allSequences.forEach((range) => {
      const lastSequence = mergedSequences[mergedSequences.length - 1]
      if (
        lastSequence[0].getTime() - 1 <= range[0].getTime() &&
        range[0].getTime() <= lastSequence[1].getTime() + 1
      ) {
        if (lastSequence[1].getTime() < range[1].getTime()) {
          lastSequence[1] = range[1]
        }
      } else {
        mergedSequences.push(range)
      }
    })

    return new DateRange(mergedSequences)
  }

  /**
   * Same as {@link include}, but mutates the current range.
   */
  iinclude(range: DateRange) {
    this.sequences = this.include(range).sequences
    return this
  }

  /**
   * Subtracts a range from the current range and returns a new excluded range.
   */
  exclude(range: DateRange) {
    const excludedRange = DateRange.emptyRange()
    let idx = 0

    for (const sequence of this.sequences) {
      // Skip excluding sequences that do not cut through the current sequence.
      while (
        idx < range.sequences.length &&
        range.sequences[idx][1].getTime() < sequence[0].getTime()
      ) {
        ++idx
      }

      // Add the whole sequence if nothing cuts through it.
      if (idx === range.sequences.length) {
        excludedRange.iinclude(new DateRange([sequence]))
        continue
      }

      for (
        let jdx = idx;
        jdx < range.sequences.length && range.sequences[jdx][0].getTime() <= sequence[1].getTime();
        ++jdx
      ) {
        // Include the part to the left of the excluded range.
        // [L     [l---r]    R]
        //  ^^^^^^
        const leftEnd = jdx > idx ? range.sequences[jdx - 1][1].getTime() + 1 : sequence[0]
        excludedRange.iinclude(DateRange.fromDates(leftEnd, range.sequences[jdx][0].getTime() - 1))

        // Include the part to the right of the excluded range.
        // Case 1: The next excluding range overlaps the right end
        // [L     [l---r]    [l'--R]---r']
        //               ^^^^
        // Case 2: The next excluding range does not overlap
        // [L     [l---r]    R] [l'----r']
        //               ^^^^
        const rightEnd =
          jdx + 1 < range.sequences.length
            ? Math.min(range.sequences[jdx + 1][0].getTime() - 1, sequence[1].getTime())
            : sequence[1]
        excludedRange.iinclude(DateRange.fromDates(range.sequences[jdx][1].getTime() + 1, rightEnd))
      }
    }

    return excludedRange
  }

  /**
   * Same as {@link exclude}, but mutates the current range.
   */
  iexclude(range: DateRange) {
    this.sequences = this.exclude(range).sequences
    return this
  }

  /**
   * Inverts the current range so that the sub-ranges become intermediate and
   * vice versa.
   */
  invert() {
    return new DateRange(flatten(this.intermediateRanges.map((range) => range.sequences)))
  }

  /**
   * Same as {@link invert}, but mutates the current range.
   */
  iinvert() {
    this.sequences = this.invert().sequences
    return this
  }
}

export default DateRange
