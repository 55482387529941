import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import cls from 'classnames'
import { memo } from 'react'

import { isInstantWireMode } from '@/constants/index'
import useTrans from '@/hooks/useTrans'

import { type TokenPickerViewProps } from './types'

export const View = ({
  boxProps,
  'data-testid': testId,
  handleClick,
  hasDropdown,
  token,
  emptySelectionText,
  isDisplayIcon,
}: TokenPickerViewProps) => {
  const trans = useTrans()
  const isInstantwire = isInstantWireMode()
  const isNemo = !isInstantwire

  return (
    <Box className={cls('p-[1px] rounded-lg', {
      'bg-gradient-btn': !token?.displaySymbol && isNemo,
      'bg-[#141A23]': token?.displaySymbol && isNemo,
      'bg-gradient-blue-btn': !token?.displaySymbol && isInstantwire,
      'iw:border iw:border-solid iw:border-[#EAEAEA]': token?.displaySymbol && isInstantwire,
    })}>
      <Box
        data-testid={testId}
        onClick={hasDropdown ? handleClick : () => null}
        className={cls('bg-[#141A23] iw:bg-white', {
          'cursor-pointer': hasDropdown,
        })}
        borderRadius={2}
        paddingX="11px"
        paddingY="7px"
        {...boxProps}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="shrink-0 grow-0"
        >
          <Stack direction="row" alignItems="center" gap="6px" className="py-[10px]">
            {token && isDisplayIcon && (
              <Box className="w-[20px]">
                <img
                  src={token.logoURI}
                  alt={token.name}
                  className="max-w-full rounded-full w-[20px] h-[20px] object-cover"
                />
              </Box>
            )}
            <Typography
              data-testid="selected-token-symbol"
              className={`text-sm font-semibold ${token ? '' : 'italic opacity-60'} select-none whitespace-nowrap`}
            >
              {token ? token.displaySymbol || emptySelectionText : trans.common.token_picker_please_select}
            </Typography>
          </Stack>
          {hasDropdown && <ExpandMoreIcon className="w-[28px] h-[28px] iw:text-[#000E0F]" />}
        </Stack>
      </Box>
    </Box>
  )
}

const memoView = memo(View)
memoView.displayName = 'TokenPickerViewSecondary'
export default memoView
