export const getInputExamplePlaceholder = (
  input: 'bankAccountName' | 'bankAccountNumber',
  isZelle: boolean,
  isVndFiat: boolean,
): string => {
  if (isZelle) {
    switch (input) {
      case 'bankAccountName' /* email */:
        return 'john.doe@gmail.com'
      case 'bankAccountNumber' /* phone */:
        return '1-844-428-8542'
    }
  }

  switch (input) {
    case 'bankAccountName':
      return isVndFiat ? 'Nguyen Van A' : 'John Doe'
    case 'bankAccountNumber':
      return '10221324'
  }
}
