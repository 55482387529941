import { useRouter } from 'next/router'

import en from '@/locales/en'
import vi from '@/locales/vi'

export const getTrans = (locale?: string) => locale === 'vi' ? vi : en

const useTrans = () => {
  const { locale } = useRouter()

  return getTrans(locale)
}

export default useTrans
