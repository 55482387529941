import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { FiatBank, toFiatBank } from '@/hooks/use-fiat-banks'
import { REUSD_MINT_ADDRESS, REVND_MINT_ADDRESS } from '@/hooks/use-tokens'
import useTrans from '@/hooks/useTrans'
import { ZELLE } from '@/utils/helpers'
import { isRunningInCypress } from '@/utils/helpers'
import { useEventCallback } from '@/utils/use-event-callback'

import { DialogEditFormProps } from './types'
import View from './view'

const Container = ({
  editingPaymentInfo,
  fiatToken,
  handleClose,
  handleDelete,
  handleSave,
  isAddingNew,
  isDialogOpened,
  fiatSupportedBanks,
}: DialogEditFormProps) => {
  const trans = useTrans()

  const [bank, setBank] = useState<FiatBank>(toFiatBank())
  const [errorBankName, setErrorBankName] = useState('')
  const [bankAccountNumber, setBankAccountNumber] = useState('')
  const [errorBankAccountNumber, setErrorBankAccountNumber] = useState('')
  const [bankAccountName, setBankAccountName] = useState('')
  const [errorBankAccountName, setErrorBankAccountName] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('bankAccount')

  const isZelle = useMemo(() => {
    return paymentMethod === ZELLE
  }, [paymentMethod])

  const onSelectPaymentMethod = useCallback((isZelle: boolean) => {
    if (isZelle) {
      setBank(toFiatBank(ZELLE))
      setPaymentMethod(ZELLE)
    } else {
      setBank(toFiatBank())
      setPaymentMethod('bankAccount')
    }
  }, [])

  // TODO: apply this https://codevoweb.com/form-validation-react-hook-form-material-ui-react/
  const validateBeforeSubmit = useCallback(() => {
    let isValid = true

    if (/\*/.test(bank.id ?? '')) {
      setErrorBankName(trans.sell.form_validation.should_not_contain_special_characters(trans.sell.bank_name))
      isValid = false
    }
    if (!bank.id) {
      setErrorBankName(trans.sell.form_validation.required(trans.sell.bank_name))
      isValid = false
    }

    if (/\*/.test(bankAccountNumber)) {
      setErrorBankAccountNumber(trans.sell.form_validation.should_not_contain_special_characters(trans.sell.bank_account_number))
      isValid = false
    }
    if (!(bankAccountNumber.length > 0)) {
      setErrorBankAccountNumber(trans.sell.form_validation.required(trans.sell.bank_account_number))
      isValid = false
    }

    if (/\*/.test(bankAccountName)) {
      setErrorBankAccountName(trans.sell.form_validation.should_not_contain_special_characters(trans.sell.bank_account_name))
      isValid = false
    }
    if (!(bankAccountName.length > 0)) {
      setErrorBankAccountName(trans.sell.form_validation.required(trans.sell.bank_account_name))
      isValid = false
    }

    if (isValid) {
      setErrorBankName('')
      setErrorBankAccountNumber('')
      setErrorBankAccountName('')
    }

    return isValid
  }, [
    bank,
    bankAccountName,
    bankAccountNumber,
    trans,
  ])

  const handleSubmitForm = useCallback(async () => {
    const validData = validateBeforeSubmit()
    if (!validData) return

    try {
      if (isRunningInCypress) {
        const errorMessage = window.Cypress.env('THROW_ERROR_SIGN_MESSAGE')

        if (errorMessage) {
          throw new Error(errorMessage)
        }
      }

      handleSave({
        rawBankName: bank.id,
        rawBankAccountNumber: bankAccountNumber,
        rawBankAccountName: bankAccountName,
        nonce: 'nonce',
      })
    } catch (error) {
      console.log(error)
    }
  }, [
    bank,
    bankAccountName,
    bankAccountNumber,
    handleSave,
    validateBeforeSubmit,
  ])

  const onInputBlur = useEventCallback(() => {
    setBank(
      fiatSupportedBanks.find(
        (supportedBank) => supportedBank.id.toLowerCase() === bank.id.toLowerCase(),
      ) ?? bank,
    )
  })

  const onInputFocus = useEventCallback((event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select()
  })

  const hasZelleTransfer = useMemo(
    () => fiatToken?.address === REUSD_MINT_ADDRESS,
    [fiatToken?.address],
  )

  const isVndFiat = useMemo(
    () => fiatToken?.address === REVND_MINT_ADDRESS,
    [fiatToken?.address],
  )

  useEffect(() => {
    if (isDialogOpened) {
      const rawBankName = editingPaymentInfo?.rawBankName || ''
      setBank((preVal) => {
        const defaultFiatBank = fiatSupportedBanks.find((bank) => bank.id === rawBankName) ?? toFiatBank(rawBankName)
        const defaultValue = !preVal.id && defaultFiatBank
        return defaultValue || preVal
      })
      setBankAccountNumber((preVal) => {
        const defaultValue = !preVal && editingPaymentInfo?.rawBankAccountNumber
        return defaultValue || preVal
      })
      setBankAccountName((preVal) => {
        const defaultValue = !preVal && editingPaymentInfo?.rawBankAccountName
        return defaultValue || preVal
      })
    }
  }, [editingPaymentInfo, fiatSupportedBanks, isDialogOpened])

  useEffect(() => {
    setPaymentMethod(hasZelleTransfer && bank.id === ZELLE ? ZELLE : 'bankAccount')
  }, [bank, hasZelleTransfer])

  const composedProps = {
    isDialogOpened,
    bank,
    setBank,
    errorBankName,
    bankAccountNumber,
    setBankAccountNumber,
    errorBankAccountNumber,
    bankAccountName,
    setBankAccountName,
    errorBankAccountName,
    handleSubmitForm,
    handleClose,
    onInputBlur,
    onInputFocus,
    dialogTitle: trans.sell[`${isAddingNew ? 'add' : 'edit'}_your_payment_method`],
    isZelle,
    isBankTransferring: !isZelle,
    onSelectZelle: () => onSelectPaymentMethod(true),
    onSelectBankToPayment: () => onSelectPaymentMethod(false),
    hasZelleTransfer,
    handleDelete,
    handleSave,
    isAddingNew,
    isVndFiat,
  }

  return (
    <View {...composedProps} />
  )
}

Container.displayName = 'InfoDialogContainer'
export default memo(Container)
