import { DecimalUtil } from '@orca-so/common-sdk'
import type { IOfferDataInfo, ITradeDataInfo } from '@renec-foundation/escrow-sdk'
import {
  TradeState,
} from '@renec-foundation/escrow-sdk'
import { TokenInfo } from '@renec-foundation/rpl-token-registry'
import {
  PublicKey,
  TransactionInstruction,
} from '@solana/web3.js'
import Decimal from 'decimal.js'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import type Rollbar from 'rollbar'

import {
  CHAIN_CONSTANTS,
  isMainnet,
} from '@/constants/index'
import developmentSettings from '@/root/cypress/fixtures/settings.json'
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cypress: any;
    rollbar: Rollbar;
    _rollbarInitialized: boolean;
  }
}

export const isRunningInCypress = typeof window !== 'undefined' && window.Cypress !== undefined

export const getTransactionDetailUrl = (signature: string) =>
  `${CHAIN_CONSTANTS.explorer}/tx/${signature}${CHAIN_CONSTANTS.cluster}`

export const getAddressUrl = (address: string) =>
  `${CHAIN_CONSTANTS.explorer}/address/${address}${CHAIN_CONSTANTS.cluster}`

export const isDemonWalletDetected = () => window.demon?.sol

export const removeIntercomActiveClass = () => {
  document.querySelectorAll('.intercom-mobile-messenger-active').forEach(element => {
    element.classList.remove('intercom-mobile-messenger-active')
  })
}

export const onIntercomWidgetShowed = () => {
  document.body.classList.add('intercom-h-0')
}

export const onIntercomWidgetHidden = () => {
  document.body.classList.remove('intercom-h-0')
}

export const detectElementMutated = (element: any, callback: () => void) => {
  const observer = new MutationObserver(callback)

  observer.observe(element, {
    attributes: true,
    attributeFilter: ['class'],
    childList: false,
    characterData: false,
  })
}

export const DEFAULT_POOL_ADDRESSES =
  isMainnet ? [
    'BQ2sH6LqkhnNZofKXtApHz12frTv1wfbihMg6osMnHx8', // RENEC/reUSD
    '7uBREo1HRKmqQvWHahmAU92E3eZNFQBSKffwLx5jGBV7', // reVND/reUSD
    '6tN6HhmsY8HphhAznyMDeHHAc9qrAzqgEaYDpYbMmitN', // reVND/reETH
    '7Xramhpt53EhHbwzZaQsCTx4pNeFaTiLULozJpFBvJqf', // reVND/reBTC
  ] : [
    '57Cr19Gn3Qq1oc8nJs63yrDsoDn1ZYFwgqaR2WqLCfJy',
    'CYY6QDsFYqmJ2MtiS6EWbjnVR61EGWx89h9NfU7oK4Cw',
    '7p8gez6GpcxUJBf6h31mmZvVe4E49LfmkHeiwmBx5YXP',
    '65y1HDCeiBiVjniAL76tc7t5H9oSiRNBuvo9d2feAzp2',
    // 'GfpVCCzsJKGSKSUsGh9c1XdSdGfx97yWvuLa4fFpZaFn',
  ]

export const DEFAULT_PROGRAM_ID = isMainnet
  ? '7rh7ZtPzHqdY82RWjHf1Q8NaQiWnyNqkC48vSixcBvad'
  : developmentSettings.pool_program_id

export const DEFAULT_CONFIG_KEY = isMainnet
  ? '5rM9W3oj9VD4y2oj9iNp4GpddCFobRxP8wmvsVKm5Xur'
  : developmentSettings.config_key

export const DEFAULT_ESCROW_PROGRAM_ID = CHAIN_CONSTANTS.defaultProgramId

export const DEFAULT_ESCROW_CONFIG_ID = CHAIN_CONSTANTS.defaultConfigId

export const DEFAULT_ORDERBOOK_PROGRAM_ID = isMainnet
  ? developmentSettings.orderbook_program_id // TODO: @trathailoi to update this value when it's available
  : developmentSettings.orderbook_program_id

export const DEFAULT_ORDERBOOK_SEAT_MANAGER_PROGRAM_ID = isMainnet
  ? developmentSettings.orderbook_program_id // TODO: @trathailoi to update this value when it's available
  : developmentSettings.orderbook_seat_manager_program_id

export const MEMO_PROGRAM_ID = 'MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr'

export const nextIsRunningCypress = process.env.NEXT_PUBLIC_IS_RUNNING_CYPRESS === 'true'

export const minifyAddress = (address: string, length = 4) => {
  if (address.length <= length * 2) {
    return address
  }

  const firstPart = address.slice(0, length)
  const lastPart = address.slice(-length)
  return `${firstPart}...${lastPart}`
}

export const NOT_AVAILABLE_LOGO_URL = 'https://icotar.com/initials/Not%20Available.svg'

export type CustomTokenInfo = TokenInfo & {
  displaySymbol?: string
  type?: 'core' | 'user'
}

export type Result<E, T> = [E] | [undefined, T]

export function toResult<E extends Error, T>(executable: () => T): Result<E, T> {
  try {
    const result = executable()
    return [undefined, result as T]
  } catch (err) {
    if (window._rollbarInitialized) {
      window.rollbar.error('toResult', { err })
    }
    return [err as E]
  }
}

export async function toResultAsync<E extends Error, T>(p: Promise<T>): Promise<Result<E, T>> {
  try {
    const result = await p
    return [undefined, result as T]
  } catch (err) {
    if (window._rollbarInitialized) {
      window.rollbar.error('toResultAsync', { err })
    }
    return [err as E]
  }
}

export const ZELLE = 'zelle'

export const getProgramErrorCode = (error: unknown) => {
  return String(error).split(' ').find(str => str.startsWith('0x'))
}

export const LINK_EXPANDED_MENU_ITEMS = ['/limit', '/history']
export const isActiveMenuLink = (currentPath: string, activePaths: string[]) => {
  // example / or /deposit
  const isExactMatch = activePaths.includes(currentPath)

  // example /deposit/1245
  const isPartialMatch = activePaths.some(path => currentPath.startsWith(`${path}/`))

  return isExactMatch || isPartialMatch
}

export const createMemoInstruction = (publicKey: PublicKey, message: string) => {
  const transactionIx = new TransactionInstruction({
    keys: [{ pubkey: publicKey, isSigner: true, isWritable: true }],
    data: Buffer.from(message, 'utf-8'),
    programId: new PublicKey(MEMO_PROGRAM_ID),
  })
  return {
    instructions: [transactionIx],
    cleanupInstructions: [],
    signers: [],
  }
}

export const detectSource = () => {
  const userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test( userAgent ),
    ios = /iphone|ipod|ipad/.test( userAgent )

  if (ios) {
    return safari ? 'nemo_web' : 'nemo_webview'
  } else {
    return userAgent.includes('wv') ? 'nemo_webview' : 'nemo_web'
  };
}

export const isTradeDisputed = (trade: ITradeDataInfo) => {
  return isEqual(trade.state, TradeState.Disputed)
}

export const isTimeUp = (tradeData: ITradeDataInfo, durationInMs: number) => {
  const createdAtTimestamp = tradeData.createdAt.muln(1000).toNumber()
  const expiredAt = moment(createdAtTimestamp).add(durationInMs, 'milliseconds')

  return moment().isAfter(moment(expiredAt))
}

export const getCurrencySymbolByTokenSymbol = (tokenSymbol: string) => {
  const symbolMap: { [key: string]: string } = {
    'reVND': '₫',
    'reNGN': '₦',
  }

  if (symbolMap[tokenSymbol])
    return symbolMap[tokenSymbol]

  return '$'
}

export const getFiatCurrencyInfo = (fiatCurrency: string) => {
  return {
    'USD': {
      symbol: '$',
      decimals: 2,
    },
    'VND': {
      symbol: '₫',
      decimals: 0,
    },
    'NGN': {
      symbol: '₦',
      decimals: 0,
    },
  }[fiatCurrency.toUpperCase()]
}

export const getOfferFiatAmountLimitRange = (
  offer: IOfferDataInfo,
  cryptoDecimals: number,
  marketMinTradeSize: Decimal,
): [Decimal, Decimal] => {
  const minTradeSize = DecimalUtil.fromU64(offer.minTradeSize, 4)
  const totalFiatAmount = DecimalUtil.fromU64(offer.totalAmount, cryptoDecimals).mul(offer.rate)
  const availableFiatAmount = DecimalUtil.fromU64(offer.availableAmount, cryptoDecimals).mul(offer.rate)

  const max = availableFiatAmount
  const min = Decimal.max(totalFiatAmount.mul(minTradeSize), new Decimal(offer.rate).mul(marketMinTradeSize))

  return min.gt(max)
    ? [availableFiatAmount, availableFiatAmount]
    : [min, max]
}
