import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import {
  GetProgramAccountsFilter,
  LAMPORTS_PER_SOL,
  ParsedAccountData,
} from '@solana/web3.js'
import { useMemo } from 'react'

import { MINIMUM_NATIVE_FUNDS } from '@/constants/native-funds'
import { useTokens } from '@/hooks/use-tokens'
import {
  useConnection,
  useWallet,
} from '@/wallet/adapter'

import useQueryWithSubscription from './use-query-with-subscription'

export type RplTokenBalances = {
  [tokenSymbol: string]: number
}

export const useBalances = () => {
  const { getTokenInfo } = useTokens()
  const { publicKey } = useWallet()
  const { connection } = useConnection()

  const filters = useMemo<GetProgramAccountsFilter[] | undefined>(() => {
    if (publicKey) {
      return [
        {
          dataSize: 165, //size of account (bytes)
        },
        {
          memcmp: {
            offset: 32, //location of our query in the account (bytes)
            bytes: publicKey.toBase58(), //our search criteria, a base58 encoded string
          },
        },
      ]
    }
    return undefined
  }, [publicKey])

  const {
    data: rawBalances,
    isLoading,
  } = useQueryWithSubscription('onProgramAccountChange', {
    queryKey: ['fetchBalances', publicKey?.toBase58()],
    queryFn: async () => {
      if (!connection || !publicKey || !filters) return {}

      const accounts = await connection.getParsedProgramAccounts(TOKEN_PROGRAM_ID, { filters })
      const result: RplTokenBalances = {}

      accounts.forEach((account) => {
        const parsedAccountInfo = account.account.data as ParsedAccountData
        // TODO Add typing here
        const tokenAddress: string = parsedAccountInfo.parsed.info.mint
        const tokenBalance: number = parsedAccountInfo.parsed.info.tokenAmount.uiAmount

        result[tokenAddress] = tokenBalance
      })

      const bal = await connection.getBalance(publicKey)
      result['RENEC'] = bal / LAMPORTS_PER_SOL

      return result
    },
    refetchOnWindowFocus: false,
    enabled: Boolean(publicKey && filters),
    subscriptionOptions: {
      address: TOKEN_PROGRAM_ID,
      filters,
    },
  })

  const balances: RplTokenBalances = useMemo(() => {
    const balances = Object.fromEntries(
      Object.entries(rawBalances ?? {}).map(([address, amount]) => [
        (address !== 'RENEC' && getTokenInfo(address).symbol) || address,
        amount,
      ]),
    )

    return new Proxy(balances, {
      get(target, p, receiver) {
        return Reflect.get(target, p, receiver) || 0
      },
    })
  }, [getTokenInfo, rawBalances])

  const isRenecInsufficientFunds = useMemo(() => {
    return publicKey && (balances?.['RENEC'] || 0) < MINIMUM_NATIVE_FUNDS.amount
  }, [balances, publicKey])

  return { balances, isLoading, isRenecInsufficientFunds }
}
