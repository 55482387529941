import toArray from 'lodash/toArray'
import { useCallback, useMemo } from 'react'

import useNemoWallet from '@/hooks/use-nemo-wallet'
import useLocalStorage from '@/hooks/useLocalStorage'
import { type SecuredPaymentInfo } from '@/model/bank-info'

import useAmplitude, { EVENT_NAMES } from './use-amplitude'

interface PaymentInfoData {
  methods: (SecuredPaymentInfo & { selected: boolean })[]
}

const normalizePaymentInfoData = (value: unknown): PaymentInfoData => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isSecuredPaymentInfo = (value: any): value is SecuredPaymentInfo =>
    value && typeof value === 'object' &&
    'rawBankName' in value &&
    'rawBankAccountNumber' in value &&
    'rawBankAccountName' in value

  const normalizeSecuredPaymentInfo = (value: SecuredPaymentInfo) => ({
    ...value,
    rawBankAccountName: String(value.rawBankAccountName),
    rawBankAccountNumber: String(value.rawBankAccountNumber),
    rawBankName: String(value.rawBankName),
    selected: 'selected' in value && Boolean(value.selected),
  })

  if (value && typeof value === 'object') {
    // Support backward compatibility with SecuredPaymentInfo type.
    if (isSecuredPaymentInfo(value)) {
      return {
        methods: [
          {
            ...normalizeSecuredPaymentInfo(value),
            selected: true,
          },
        ],
      }
    }

    if ('methods' in value) {
      const methods = toArray(value.methods as SecuredPaymentInfo[])
        .filter((method) => isSecuredPaymentInfo(method))
        .map(normalizeSecuredPaymentInfo)
      return { methods }
    }
  }

  return { methods: [] }
}

export const securedPaymentInfoStorageKey = (address?: string, tokenSymbol?: string) => {
  let defaultKey = `${address}_seller_secured_payment_info`
  if (tokenSymbol && tokenSymbol?.toLowerCase() !== 'revnd') {
    defaultKey += `_${tokenSymbol?.toLowerCase()}`
  }
  return defaultKey
}

const useSecuredPaymentInfoOfSeller = (tokenSymbol?: string) => {
  const amplitude = useAmplitude()
  const { publicKey } = useNemoWallet()
  const [paymentInfo, setPaymentInfo] = useLocalStorage<PaymentInfoData>(
    securedPaymentInfoStorageKey(publicKey?.toBase58(), tokenSymbol),
  )

  const { methods } = useMemo(
    () => normalizePaymentInfoData(paymentInfo),
    [paymentInfo],
  )

  const lastSelectedMethodIndex = useMemo(() => {
    const selectedIndex = methods.findIndex(({ selected }) => selected)
    return selectedIndex < 0 ? null : selectedIndex
  }, [methods])

  const selectPaymentInfoByIndex = useCallback((index: number) => {
    if (!publicKey) {
      return false
    }

    const isValidIndex = Boolean(methods[index])
    const isUnchangedIndex = index === lastSelectedMethodIndex

    if (isUnchangedIndex || !isValidIndex) {
      return false
    }

    setPaymentInfo({
      methods: methods.map((method, idx) => ({
        ...method,
        selected: idx === index,
      })),
    })

    amplitude.track(EVENT_NAMES.sell_update_bank)

    return true
  }, [amplitude, lastSelectedMethodIndex, methods, publicKey, setPaymentInfo])

  const addPaymentInfo = useCallback(
    (newPaymentInfo: SecuredPaymentInfo) => {
      if (!publicKey) {
        return false
      }

      const newPaymentInfoAlreadyExists = methods.some(
        (method) =>
          method.rawBankAccountName === newPaymentInfo.rawBankAccountName &&
          method.rawBankAccountNumber === newPaymentInfo.rawBankAccountNumber &&
          method.rawBankName === newPaymentInfo.rawBankName,
      )

      if (newPaymentInfoAlreadyExists) {
        return false
      }

      setPaymentInfo({
        methods: [
          ...methods,
          // Auto-select the added payment info if no method is currently selected.
          { ...newPaymentInfo, selected: lastSelectedMethodIndex === null },
        ],
      })

      if (lastSelectedMethodIndex === null) {
        amplitude.track(EVENT_NAMES.sell_update_bank)
      }

      return true
    },
    [amplitude, lastSelectedMethodIndex, methods, publicKey, setPaymentInfo],
  )

  const removePaymentInfoByIndex = useCallback((index: number) => {
    if (!publicKey) {
      return false
    }

    if (index < 0 || index >= methods.length) {
      return false
    }

    setPaymentInfo({
      methods: methods.filter((_, idx) => idx !== index),
    })

    if (lastSelectedMethodIndex === index) {
      amplitude.track(EVENT_NAMES.sell_update_bank)
    }

    return true
  }, [amplitude, lastSelectedMethodIndex, methods, publicKey, setPaymentInfo])

  const replacePaymentInfoByIndex = useCallback((index: number, paymentInfo: SecuredPaymentInfo) => {
    if (!publicKey) {
      return false
    }

    const isValidIndex = Boolean(methods[index])
    const isUnchangedPaymentInfo =
      methods[index]?.rawBankAccountName === paymentInfo.rawBankAccountName &&
      methods[index]?.rawBankAccountNumber === paymentInfo.rawBankAccountNumber &&
      methods[index]?.rawBankName === paymentInfo.rawBankName

    if (!isValidIndex || isUnchangedPaymentInfo) {
      return false
    }

    setPaymentInfo({
      methods: methods.map((method, idx) =>
        idx === index ? { ...paymentInfo, selected: method.selected } : method,
      ),
    })

    if (lastSelectedMethodIndex === index) {
      amplitude.track(EVENT_NAMES.sell_update_bank)
    }

    return true
  }, [amplitude, lastSelectedMethodIndex, methods, publicKey, setPaymentInfo])

  return {
    addPaymentInfo,
    allMethods: methods,
    lastSelectedMethod: lastSelectedMethodIndex === null ? null : methods[lastSelectedMethodIndex],
    lastSelectedMethodIndex,
    removePaymentInfoByIndex,
    replacePaymentInfoByIndex,
    selectPaymentInfoByIndex,
  }
}

export default useSecuredPaymentInfoOfSeller
