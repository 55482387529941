import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { memo } from 'react'

import useTrans from '@/hooks/useTrans'

import { type TokenPickerViewProps } from './types'

export const View = ({
  boxProps,
  'data-testid': testId,
  handleClick,
  hasDropdown,
  token,
  emptySelectionText,
  isDisplayIcon,
}: TokenPickerViewProps) => {
  const trans = useTrans()

  return (
    <Box
      data-testid={testId}
      onClick={hasDropdown ? handleClick : () => null}
      className={hasDropdown ? 'cursor-pointer' : ''}
      bgcolor="#4B586A"
      borderRadius="8px"
      paddingX={1}
      paddingY={.75}
      {...boxProps}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="shrink-0 grow-0"
      >
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, md: 1 }}>
          {token && isDisplayIcon && (
            <Box className="w-[24px]">
              <img
                src={token.logoURI}
                alt={token.name}
                className="max-w-full rounded-full w-[24px] h-[24px] object-cover"
              />
            </Box>
          )}
          <Typography
            data-testid="selected-token-symbol"
            color="inherit"
            className={`text-sm font-semibold ${token ? '' : 'italic opacity-60'} select-none whitespace-nowrap`}
          >
            {token ? token.displaySymbol || emptySelectionText : trans.common.token_picker_please_select}
          </Typography>
        </Stack>
        {hasDropdown && <ExpandMoreIcon color="inherit" />}
      </Stack>
    </Box>
  )
}

const memoView = memo(View)
memoView.displayName = 'TokenPickerView'
export default memoView
