import ArrowRight from '@/assets/svgs/arrow-right.svg'
import ArrowRightBlue from '@/assets/svgs/arrow-right-blue.svg'
import Back from '@/assets/svgs/back.svg'
import BackGray from '@/assets/svgs/back-gray.svg'
import Copy from '@/assets/svgs/copy.svg'
import CopyOutlined from '@/assets/svgs/copy-outlined.svg'
import DiscordBlack from '@/assets/svgs/discord-black.svg'
import Download from '@/assets/svgs/download.svg'
import DownloadBlue from '@/assets/svgs/download-blue.svg'
import Email from '@/assets/svgs/email.svg'
import ExportOutlined from '@/assets/svgs/export-outlined.svg'
import FacebookWhite from '@/assets/svgs/facebook-white.svg'
import FiatNgn from '@/assets/svgs/fiat-ngn.svg'
import FiatUsd from '@/assets/svgs/fiat-usd.svg'
import FiatVnd from '@/assets/svgs/fiat-vnd.svg'
import Fullscreen from '@/assets/svgs/fullscreen.svg'
import Graph from '@/assets/svgs/graph.svg'
import HelpOutlined from '@/assets/svgs/help-outlined.svg'
import Hourglass from '@/assets/svgs/hourglass.svg'
import InfoCircle from '@/assets/svgs/info-circle.svg'
import KycFilled from '@/assets/svgs/kyc-filled.svg'
import Menu from '@/assets/svgs/menu.svg'
import MoreContactInfo from '@/assets/svgs/more-contact-info.svg'
import NoGraph from '@/assets/svgs/no-graph.svg'
import NoticeMessage from '@/assets/svgs/notice-message-illustrator.svg'
import Notification from '@/assets/svgs/notification.svg'
import Pencil from '@/assets/svgs/pencil.svg'
import PencilOutlined from '@/assets/svgs/pencil-outlined.svg'
import Phone from '@/assets/svgs/phone.svg'
import RedditBlack from '@/assets/svgs/reddit-black.svg'
import Renec from '@/assets/svgs/renec.svg'
import Repeat from '@/assets/svgs/repeat.svg'
import ReUsd from '@/assets/svgs/reusd.svg'
import RushHour from '@/assets/svgs/rush-hour.svg'
import Search from '@/assets/svgs/search.svg'
import Settings from '@/assets/svgs/settings.svg'
import Switch from '@/assets/svgs/switch.svg'
import TelegramBlack from '@/assets/svgs/telegram-black.svg'
import TelegramWhite from '@/assets/svgs/telegram-white.svg'
import TwitterBlack from '@/assets/svgs/twitter-black.svg'
import TwitterWhite from '@/assets/svgs/twitter-white.svg'
import Vnd from '@/assets/svgs/vnd.svg'

export const IconName = {
  NOTIFICATION: 'notification',
  RENEC: 'renec',
  REUSD: 'reusd',
  SWITCH: 'switch',
  SETTINGS: 'settings',
  DISCORD_BLACK: 'discord-black',
  REDDIT_BLACK: 'reddit-black',
  TWITTER_BLACK: 'twitter-black',
  TWITTER_WHITE: 'twitter-white',
  TELEGRAM_BLACK: 'telegram-black',
  TELEGRAM_WHITE: 'telegram-white',
  FACEBOOK_WHITE: 'facebook-white',
  NOTICE_MESSAGE: 'notice-message',
  ARROW_RIGHT: 'arrow-right',
  ARROW_RIGHT_BLUE: 'arrow-right-blue',
  EMAIL: 'email',
  PHONE: 'phone',
  INFO_CIRCLE: 'info-circle',
  MORE_CONTACT_INFO: 'more-contact-info',
  PENCIL: 'pencil',
  PENCIL_OUTLINED: 'pencil-outlined',
  VND: 'vnd',
  COPY: 'copy',
  COPY_OUTLINED: 'copy-outlined',
  EXPORT_OUTLINED: 'export-outlined',
  KYC_FILLED: 'kyc-filled',
  FIAT_VND: 'fiat-vnd',
  FIAT_USD: 'fiat-usd',
  FIAT_NGN: 'fiat-ngn',
  HELP_OUTLINED: 'help',
  SEARCH: 'search',
  RUSH_HOUR: 'rush-hour',
  REPEAT: 'repeat',
  MENU: 'menu',
  HOURGLASS: 'hourglass',
  GRAPH: 'graph',
  NO_GRAPH: 'no-graph',
  REFRESH_CIRCLE: 'refresh-circle',
  REFRESH_CIRCLE_BLUE: 'refresh-circle-blue',
  BACK: 'back',
  DOWNLOAD: 'download',
  DOWNLOAD_BLUE: 'download-blue',
  FULLSCREEN: 'fullscreen',
  BACK_GRAY: 'back-gray',
}

export const staticImportIcon = (iconName: string) => {
  switch (iconName) {
    case IconName.SWITCH:
      return Switch
    case IconName.SETTINGS:
      return Settings
    case IconName.REUSD:
      return ReUsd
    case IconName.DISCORD_BLACK:
      return DiscordBlack
    case IconName.REDDIT_BLACK:
      return RedditBlack
    case IconName.TWITTER_BLACK:
      return TwitterBlack
    case IconName.TWITTER_WHITE:
      return TwitterWhite
    case IconName.TELEGRAM_BLACK:
      return TelegramBlack
    case IconName.TELEGRAM_WHITE:
      return TelegramWhite
    case IconName.FACEBOOK_WHITE:
      return FacebookWhite
    case IconName.NOTICE_MESSAGE:
      return NoticeMessage
    case IconName.ARROW_RIGHT:
      return ArrowRight
    case IconName.ARROW_RIGHT_BLUE:
      return ArrowRightBlue
    case IconName.EMAIL:
      return Email
    case IconName.PHONE:
      return Phone
    case IconName.INFO_CIRCLE:
      return InfoCircle
    case IconName.MORE_CONTACT_INFO:
      return MoreContactInfo
    case IconName.PENCIL:
      return Pencil
    case IconName.PENCIL_OUTLINED:
      return PencilOutlined
    case IconName.VND:
      return Vnd
    case IconName.COPY:
      return Copy
    case IconName.COPY_OUTLINED:
      return CopyOutlined
    case IconName.EXPORT_OUTLINED:
      return ExportOutlined
    case IconName.KYC_FILLED:
      return KycFilled
    case IconName.FIAT_VND:
      return FiatVnd
    case IconName.FIAT_USD:
      return FiatUsd
    case IconName.FIAT_NGN:
      return FiatNgn
    case IconName.HELP_OUTLINED:
      return HelpOutlined
    case IconName.SEARCH:
      return Search
    case IconName.RUSH_HOUR:
      return RushHour
    case IconName.REPEAT:
      return Repeat
    case IconName.MENU:
      return Menu
    case IconName.HOURGLASS:
      return Hourglass
    case IconName.GRAPH:
      return Graph
    case IconName.NO_GRAPH:
      return NoGraph
    case IconName.BACK:
      return Back
    case IconName.DOWNLOAD:
      return Download
    case IconName.DOWNLOAD_BLUE:
      return DownloadBlue
    case IconName.FULLSCREEN:
      return Fullscreen
    case IconName.BACK_GRAY:
      return BackGray
    case IconName.NOTIFICATION:
      return Notification
    case IconName.RENEC:
    default:
      return Renec
  }
}
