/* eslint-disable no-unused-vars */
import developmentSettings from '@/root/cypress/fixtures/settings.json'
export const REMITANO_URL = 'https://remitano.com'
export const SITE_URL =
  process.env.NEXT_PUBLIC_P2P_SITE_URL || 'https://develop.d3epdwmmn1npz9.amplifyapp.com'

export const TIME_ZONE = process.env.NEXT_PUBLIC_TIMEZONE

export const A_HUNDRED_PERCENT = 10000
export const TICK_SPACING = 32
export const SLIPPAGE = 1

export const SETTINGS_CACHE_EXPIRES_AFTER_SECONDS = 3600 // 1 hour

export const TRADE_DATE_FORMAT = 'MMM D, YYYY, hh:mm A'
export const TRADE_HOUR_FORMAT = 'HH:mm'
export const DEMON_WALLET_URL = 'https://demon.renec.org/'
export const TRADE_ORDER_STATUS = {
  awaiting: 'awaiting',
  released: 'released',
  disputed: 'disputed',
  deposited: 'deposited',
  canceled: 'canceled',
}
export const OFFER_STATUS = {
  canceled: 'canceled',
  awaiting: 'awaiting',
  outOfFund: 'outOfFund',
}

export const BEST_SELLING_OFFER_COOKIE_PREFIX = 'best-selling-offer-'
export const BLACKLISTED_SELLING_OFFERS_COOKIE_PREFIX = 'blacklisted-selling-offers-of-buying-offer-'

export const isMainnet = process.env.NEXT_PUBLIC_IS_MAINNET === 'true'
export const isInstantWireMode = () => process.env.NEXT_PUBLIC_PRODUCT_NAME === 'instantwire'

export const PRODUCT_NAME = isInstantWireMode() ? 'InstantWire' : 'Nemo'

export enum Chain {
  RENEC = 'renec',
  SOLANA = 'solana',
}

export enum NetworkEnv {
  MAINNET = 'mainnet',
  TESTNET = 'testnet',
}

type EnvironmentConfig = {
  rpc: string
  wss: string
  defaultProgramId: string
  defaultConfigId: string
  whitelistedTokenMints: string[]
  usdtMintAddress: string
  explorer: string
  cluster: string
}

type NetworkOptions = {
  [key in Chain]: {
    mainnet: EnvironmentConfig
    testnet: EnvironmentConfig
  }
}

export const NETWORK_OPTIONS: NetworkOptions = {
  [Chain.RENEC]: {
    [NetworkEnv.MAINNET]: {
      rpc: 'https://api-mainnet.renec.org',
      wss: 'wss://api-mainnet-beta.renec.foundation:8900',
      defaultProgramId: 'DutYDgefAn4ZoeACkou7sjCja8u9o5PxP8PKyBYSagX',
      defaultConfigId: 'escrow09',
      whitelistedTokenMints: [
        '4Q89182juiadeFgGw3fupnrwnnDmBhf7e7fHWxnUP3S3', // reUSD
        '2kNzm2v6KR5dpzgavS2nssLV9RxogVP6py2S6doJEfuZ', // reVND
        'BfSYryW6Q93iUKE4uNsUtAdxQT9uU4GSVg2si3outLk1', // reNGN
      ],
      usdtMintAddress: '4Q89182juiadeFgGw3fupnrwnnDmBhf7e7fHWxnUP3S3',
      explorer: 'https://explorer.renec.org',
      cluster: '',
    },
    [NetworkEnv.TESTNET]: {
      rpc: 'https://api-testnet.renec.foundation:8899',
      wss: 'wss://api-testnet.renec.foundation:8900',
      defaultProgramId: 'JAw49AneTNg21REKddZQX59s9oDwHsQWScrSqJ6wA4po',
      defaultConfigId: developmentSettings.off_ramp_config_id || 'escrow05', // for CI to replace dynamically
      whitelistedTokenMints: [
        'Afy8qEgeJykFziRwiCk6tnBbd3uzxMoEqn2GTNCyGN7P', // reUSD
        'DSodi59U9ZWRnVgP94VNnKamFybYpsqYj2iKL1jQF7Ag', // reVND
        'CHe7TGhNzxpqiW6pdVJ2H2Mw5t7yHXTNyCfzJ1WFR5Dw', // reNGN
      ],
      usdtMintAddress: 'Afy8qEgeJykFziRwiCk6tnBbd3uzxMoEqn2GTNCyGN7P',
      explorer: 'https://explorer.renec.org',
      cluster: '?cluster=testnet',
    },
  },
  [Chain.SOLANA]: {
    [NetworkEnv.MAINNET]: {
      rpc: 'https://still-boldest-pond.solana-mainnet.quiknode.pro/76b2c0a14b5e14e8cc430a88aaebec4e7d6e509b', // 'https://api.mainnet-beta.solana.com',
      wss: 'wss://still-boldest-pond.solana-mainnet.quiknode.pro/76b2c0a14b5e14e8cc430a88aaebec4e7d6e509b', // 'wss://api.mainnet-beta.solana.com',
      defaultProgramId: 'p2pBPEUUE6L7wNPGmD2tFAUKQyVUvcvn19bRoJtXrX5',
      defaultConfigId: 'p2p01',
      whitelistedTokenMints: [
        'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB', // USDT
        '2kNzm2v6KR5dpzgavS2nssLV9RxogVP6py2S6doJEfuZ', // reVND --> for marketPrice querying
        'BfSYryW6Q93iUKE4uNsUtAdxQT9uU4GSVg2si3outLk1', // reNGN --> for marketPrice querying
      ],
      usdtMintAddress: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
      explorer: 'https://solscan.io/', // 'https://explorer.solana.com',
      cluster: '',
    },
    [NetworkEnv.TESTNET]: {
      rpc: 'https://thrumming-alien-fire.solana-devnet.quiknode.pro/3e006bfd0f273c4f294d1484d9234e4a52f4b080',
      wss: 'wss://thrumming-alien-fire.solana-devnet.quiknode.pro/3e006bfd0f273c4f294d1484d9234e4a52f4b080',
      defaultProgramId: 'CH6LKDP7kCHC7Vva6JBfeuA7cimSMwjoPwGH5J15cvHt',
      defaultConfigId: 'escrow05',
      whitelistedTokenMints: [
        '32tLsZft3xUqZmY4pLz92GRDiENhUWe8HdvqxZ3uwgxM', // USDT
        '2kNzm2v6KR5dpzgavS2nssLV9RxogVP6py2S6doJEfuZ', // reVND --> for marketPrice querying
        'BfSYryW6Q93iUKE4uNsUtAdxQT9uU4GSVg2si3outLk1', // reNGN --> for marketPrice querying
      ],
      usdtMintAddress: '32tLsZft3xUqZmY4pLz92GRDiENhUWe8HdvqxZ3uwgxM',
      explorer: 'https://solscan.io/', // 'https://explorer.solana.com',
      cluster: '?cluster=devnet',
    },
  },
}

const chain = isInstantWireMode() ? Chain.SOLANA : Chain.RENEC
// enforceChain may be used in case of instantwire mode, but with the RENEC chain (probably for e2e tests)
const enforceChain = String(process.env.NEXT_PUBLIC_CHAIN || '') as Chain

export const NETWORK_CHAIN = enforceChain || chain
export const isChainSolana = () => NETWORK_CHAIN === Chain.SOLANA
export const NETWORK_ENV = isMainnet ? NetworkEnv.MAINNET : NetworkEnv.TESTNET
export const CHAIN_CONSTANTS = NETWORK_OPTIONS[NETWORK_CHAIN][NETWORK_ENV]

export const SWAP_RENEC_GAS_FEE = isChainSolana() ? 0.001 : 0.01 // RENEC
