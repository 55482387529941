import Decimal from 'decimal.js'
import remove from 'lodash/remove'
import union from 'lodash/union'
import moment from 'moment'

interface OHLC {
  open: number
  high: number
  low: number
  close: number
  volume: number
}

export interface Candle extends OHLC {
  span: number
  startTime: Date
}

export type OHLCMapUpdateListener =
  | ((candles: Candle[]) => void)
  | ((this: CandleSequence, candles: Candle[]) => void)

/**
 * Bidirectional token pair utility by symbol.
 */
export class TokenPair {
  private static cache = new Map<string, TokenPair>()

  private constructor(public readonly symbolA: string, public readonly symbolB: string) {
    if (this.symbolA.toLowerCase() === this.symbolB.toLowerCase()) {
      throw new Error('A token pair must consist of two different tokens.')
    }
  }

  static of(symbolA: string, symbolB: string) {
    const pair = new TokenPair(symbolA, symbolB)
    const pairRef = TokenPair.cache.get(pair.toString()) ?? pair
    TokenPair.cache.set(pair.toString(), pairRef)
    return pairRef
  }

  toString() {
    return [this.symbolA, this.symbolB].sort().join('/').toLowerCase()
  }
}

type StartTimeMap = Map<number, OHLC>
type SpanMap = Map<number, StartTimeMap>
type TokenMap = Map<string, SpanMap>

export class CandleSequence {
  private tokenMap: TokenMap = new Map()
  private listeners: OHLCMapUpdateListener[] = []

  get(tokenPair: TokenPair, span: number) {
    CandleSequence.assertValidSpan(span)

    const candles: Candle[] = []

    const startTimeMap: StartTimeMap =
      this.tokenMap.get(tokenPair.toString())?.get(span) ?? new Map()
    for (const [startTime, ohlcData] of startTimeMap.entries()) {
      candles.push({
        ...ohlcData,
        startTime: new Date(startTime * 1000),
        span,
      })
    }

    return CandleSequence.fillGapsBetweenCandles(candles)
  }

  /**
   * This method will **ignore** already added candles of the same token pair,
   * start time, and span.
   */
  add(tokenPair: TokenPair, candles: Candle[]) {
    const spanMap = this.tokenMap.get(tokenPair.toString())

    candles = candles.filter(({ span, startTime: startDate }) => {
      const startTime = new Decimal(startDate.getTime()).div(1000).floor().toNumber()
      return !spanMap?.get(span)?.has(startTime)
    })

    this.update(tokenPair, candles)
  }

  /**
   * Unlike {@link add}, this method will **overwrite** all existing candles,
   * regardless of whether they have already been added or not.
   */
  update(tokenPair: TokenPair, candles: Candle[]) {
    if (!candles.length) return

    if (!this.tokenMap.has(tokenPair.toString())) {
      this.tokenMap.set(tokenPair.toString(), new Map())
    }

    const spanMap = this.tokenMap.get(tokenPair.toString())

    candles.forEach(({ span, startTime: startDate, ...ohlc }) => {
      CandleSequence.assertValidSpan(span)

      if (!spanMap?.has(span)) {
        spanMap?.set(span, new Map())
      }

      const startTime = Decimal.div(startDate.getTime(), 1000).floor().toNumber()
      spanMap?.get(span)?.set(startTime, ohlc)
    })

    this.listeners.forEach((cb) => setTimeout(() => cb.call(this, candles)))
  }

  /**
   * Like {@link update}, but this method only picks from provided candles
   * the ones that have `startTime` equal or later than that of the last candle
   * in the sequence. Note that the last candle in the sequence is subject to
   * change as each candle gets updated.
   */
  append(tokenPair: TokenPair, candles: Candle[]) {
    const latestStartTimeBySpan = new Map<number, number>()
    const updatingCandles: Candle[] = []

    const spanMap = this.tokenMap.get(tokenPair.toString())

    candles.forEach((candle) => {
      CandleSequence.assertValidSpan(candle.span)

      if (!latestStartTimeBySpan.has(candle.span)) {
        latestStartTimeBySpan.set(
          candle.span,
          Math.max(...(spanMap?.get(candle.span)?.keys() ?? [0])) * 1000,
        )
      }

      if (candle.startTime.getTime() >= (latestStartTimeBySpan.get(candle.span) ?? 0)) {
        latestStartTimeBySpan.set(candle.span, candle.startTime.getTime())
        updatingCandles.push(candle)
      }
    })

    this.update(tokenPair, updatingCandles)
  }

  addUpdateListener(listener: OHLCMapUpdateListener) {
    this.listeners = union(this.listeners, [listener])
    return () => {
      this.removeUpdateListener(listener)
    }
  }

  removeUpdateListener(listener: OHLCMapUpdateListener) {
    remove(this.listeners, (cb) => cb === listener)
  }

  /**
   * A *valid* `span` is a number that can divide a day into equal parts, or be
   * the length of multiple whole days.
   */
  static isValidSpan(span: number) {
    const SECONDS_PER_DAY = 86400
    return span % SECONDS_PER_DAY === 0 || SECONDS_PER_DAY % span === 0
  }

  /**
   * Sorts a list of candles increasingly by startTime.
   */
  static sortCandles(candles: Candle[]) {
    return candles.sort(
      (candleA, candleB) => candleA.startTime.getTime() - candleB.startTime.getTime(),
    )
  }

  static assertValidSpan(span: number) {
    if (!CandleSequence.isValidSpan(span)) {
      throw new Error(
        `Span (${span}) must cover multiple whole days or divide a day into equal parts.`,
      )
    }
  }

  /**
   * Finds the start time of the window that contains the given time.
   */
  static clampStartTimeBySpan(time: Date, span: number) {
    const clampedStartTime = Decimal.div(time.getTime(), span * 1000)
      .floor()
      .mul(span * 1000)
      .toNumber()
    return new Date(clampedStartTime)
  }

  /**
   * Normalizes a list of candles by sorting it and filling the gaps between
   * the candles with zero-volume ones.
   */
  static fillGapsBetweenCandles(candles: Candle[]) {
    return this.sortCandles(candles).reduce<Candle[]>((arr, candle) => {
      // Make sure candles have no time gap in between.
      while (
        arr.length &&
        arr[arr.length - 1].startTime.getTime() + arr[arr.length - 1].span * 1000 <
          candle.startTime.getTime()
      ) {
        arr.push({
          open: arr[arr.length - 1].close,
          high: arr[arr.length - 1].close,
          low: arr[arr.length - 1].close,
          close: arr[arr.length - 1].close,
          volume: 0,
          span: arr[arr.length - 1].span,
          startTime: new Date(
            arr[arr.length - 1].startTime.getTime() + arr[arr.length - 1].span * 1000,
          ),
        })
      }

      arr.push(candle)

      return arr
    }, [])
  }

  static toTz(candles: Candle[], tz = 0) {
    return candles.map<Candle>((candle) => ({
      ...candle,
      startTime: moment(candle.startTime).add(tz, 'hour').toDate(),
    }))
  }
}
