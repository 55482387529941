import { Stack, Typography } from '@mui/material'
import Image from 'next/image'

import BaseDialog from '@/components/base/BaseDialog'
import Button from '@/components/base/Button'
import useTrans from '@/hooks/useTrans'

import { DialogConfirmDeletePaymentInfoFormProps } from './types'

const DialogConfirmDeletePaymentInfoForm = (props: DialogConfirmDeletePaymentInfoFormProps) => {
  const { handleCancel, handleConfirm, isOpened } = props
  const trans = useTrans()

  return (
    <BaseDialog
      isSmallPopup
      isOpened={isOpened}
      title={trans.common.confirm}
      body={
        <Stack spacing={2.5} alignItems="center" paddingBottom={1.5}>
          <Image alt="warning" src="/icon-warning.png" height={100} width={100} />
          <Stack spacing={2}>
            <Typography className="text-xl font-bold text-center text-white iw:text-[#2D2F38]">
              {trans.sell.delete_payment_method_title}
            </Typography>
            <Typography className="text-base text-center text-[#C2CBD9] iw:text-[#666]">
              {trans.sell.delete_payment_method_confirmation}
            </Typography>
          </Stack>
        </Stack>
      }
      handleClose={handleCancel}
      buttonDirection="column"
      buttons={[
        <Button
          key="confirm"
          data-testid="btn-confirm-delete-method"
          variant="primary"
          onClick={handleConfirm}
        >
          {trans.common.delete}
        </Button>,
        <Button
          key="cancel"
          data-testid="btn-cancel-delete-method"
          variant="outlined"
          onClick={handleCancel}
        >
          {trans.common.cancel}
        </Button>,
      ]}
    />
  )
}

export default DialogConfirmDeletePaymentInfoForm
