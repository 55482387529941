import { useRouter } from 'next/router'
import { useEffect,useMemo, useRef } from 'react'

import { useSettings } from '@/hooks/use-settings'
import useToastMessage from '@/hooks/use-toast-message'

export default function SnackBar() {
  const { data: settingsData } = useSettings()
  const noticeMessages = useMemo(() => settingsData?.notice_messages || [], [settingsData])
  const { locale } = useRouter()
  const messages = useMemo(
    () =>
      Object.values(noticeMessages)
        .map((v) => v[locale || 'en'])
        .filter((v) => v),
    [noticeMessages, locale],
  )
  const { showSecondaryMessage } = useToastMessage()
  const shownMessages = useRef<string[]>([])

  useEffect(() => {
    if (Array.isArray(messages)) {
      messages.forEach((message) => {
        if (!shownMessages.current.includes(message)) {
          showSecondaryMessage(message)
          shownMessages.current.push(message)
        }
      })
    }
  }, [messages, showSecondaryMessage])

  return null
}
