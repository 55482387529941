import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Image from 'next/image'

import Button from '@/components/base/Button'
import useTrans from '@/hooks/useTrans'

import { ContainerProps } from './types'

const Container = ({ onClose, onContinue, count }: ContainerProps) => {
  const trans = useTrans()

  return (
    <Stack className="bg-[#1E2735] iw:bg-white p-6 rounded-t-[16px] sm:rounded-b-[16px] text-center" gap="16px">
      <Image src="/history-transactions.png" alt="Compass" className="mx-auto" width={94} height={94} />
      <Typography className="text-xl font-bold ml-[-8px] mr-[-8px] iw-text-[#000E0F]">{trans.history.reminder_title(count)}</Typography>
      <Typography className="text-base text-[#A7ABB1] iw:text-[#666666]">
        {trans.history.reminder_description}
      </Typography>

      <Button
        onClick={onContinue}
        variant="primary"
        size="xl"
      >
        {trans.history.reminder_continue_btn}
      </Button>
      <Button
        onClick={onClose}
        variant="outlined"
        size="xl"
      >
        {trans.history.reminder_later_btn}
      </Button>
    </Stack>
  )
}

export default Container
