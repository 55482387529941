import useMediaQuery from '@mui/material/useMediaQuery'
import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

export const IsSsrMobileContext = createContext(false)

export const useIsMobile = () => {
  const isSsrMobile = useContext(IsSsrMobileContext)
  const isBrowserMobile = useMediaQuery('(max-width:767px)')
  const [isCaraxMobile, setIsCaraxMobile] = useState(false)

  useEffect(() => {
    //@ts-ignore
    if (!window.demon?.platform) return
    //@ts-ignore
    const isMobile = ['ios', 'android'].includes(window.demon.platform.toLowerCase())
    setIsCaraxMobile(isMobile)
  }, [])

  return isSsrMobile || isBrowserMobile || isCaraxMobile
}
