import Box from '@mui/material/Box'
import classNames from 'classnames'
import { memo, useContext } from 'react'

import AddressSpanWithCopyButton from '@/components/base/AddressSpanWithCopyButton'
import { isInstantWireMode } from '@/constants/index'

import TokenListContext from '../context'
import { type TokenListItemProps } from './types'

const TokenListItem = ({ option, ...props }: TokenListItemProps) => {
  const { handleClose, handleTokenSelect, nickName, selectedToken, isDisplayAddress, isShowDialog } = useContext(TokenListContext)

  return (
    <li
      {...props}
      className={classNames(
        props.className,
        'items-start px-4 iw:bg-white iw:border iw:border-solid iw:border-[#EAEAEA] iw:h-full',
        isShowDialog ? 'bg-[#141A23] rounded-xl py-3' : 'bg-[#1A2330] py-[0.625rem] rounded-lg',
        selectedToken?.address === option.address
          ? 'bg-transparent'
          : 'cursor-pointer hover:bg-white/10 iw:hover:bg-[#DFF1FF] iw:hover:border-[#DFF1FF]',
      )}
      data-testid={`token-picker${nickName ? `-${nickName}` : ''}-${option.symbol}-${
        option.address
      }`}
      onClick={() =>
        selectedToken?.address === option.address ? handleClose() : handleTokenSelect(option)
      }
    >
      <Box className="flex items-center max-w-full gap-x-2">
        <Box className="flex flex-shrink-0 items-center justify-center rounded-full overflow-hidden">
          <img
            src={option.logoURI || ''}
            alt={option.name}
            className={classNames(
              'max-w-full',
              isDisplayAddress ? 'h-[30px] w-[30px]' : 'h-[24px] w-[24px]',
            )}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            minWidth: 0,
            '& .token-symbol': {
              color: isInstantWireMode() ? '#000E0F' : '#ffffff',
            },
            '& .token-name': {
              color: isInstantWireMode() ? '#000E0F' : '#D0D1D8',
            },
            '& .token-address': {
              color: isInstantWireMode() ? '#000E0F' : '#D0D1D8',
            },
          }}
        >
          <span className="flex items-center gap-x-[5px]">
            <span className="min-w-0 text-sm truncate font-semibold token-symbol" title={option.name}>
              {option.displaySymbol}
            </span>
          </span>
          { isDisplayAddress && <AddressSpanWithCopyButton address={option.address} />}
        </Box>
      </Box>
    </li>
  )
}

const memoTokenListItem = memo(TokenListItem)
memoTokenListItem.displayName = 'TokenListItem'
export default memoTokenListItem
