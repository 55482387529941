import { autocompleteClasses } from '@mui/material/Autocomplete'
import { type PopperProps } from '@mui/material/Popper'
import { styled } from '@mui/material/styles'
import { type JSXElementConstructor } from 'react'

const AutocompletePopperComponent = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    background: 'transparent',
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0A1320',
    padding: '0 16px 16px',
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
  [`& .${autocompleteClasses.noOptions}`]: {
    textAlign: 'center',
  },
}))

AutocompletePopperComponent.displayName = 'AutocompletePopperComponent'
export default AutocompletePopperComponent as JSXElementConstructor<PopperProps>
