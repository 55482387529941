import { type TokenInfo } from '@renec-foundation/rpl-token-registry'
import { type MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'

import usePagination, { type UsePaginationParams } from '@/hooks/use-pagination'
import { DefaultTokenInfo, type SwapTokenInfo } from '@/hooks/use-tokens'

import TokenList from './TokenList'
import TokenListContext from './TokenList/context'
import { type TokenPickerProps } from './types'
import { searchTokenFromList, sortTokensByOrder } from './utils'
import ViewPrimary from './view'
import ViewSecondary from './view.secondary'

const TokenPicker = (props: TokenPickerProps) => {
  const {
    nickName,
    disableDropdown,
    allowCustomMintAddress,
    selectedToken = DefaultTokenInfo,
    setSelectedToken,
    blackList,
    tokens,
    filterToken,
    isShowSearch,
    variant,
    isDisplayAddress = true,
    isDisplayIcon = true,
    emptySelectionText,
    isShowDialog = false,
    recentTokens,
  } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [filterKeyword, setFilterKeyword] = useState('')

  const paginationParams = useMemo<UsePaginationParams<TokenInfo>>(
    () => ({
      queryKey: `tokens?keyword=${filterKeyword.trim().toLowerCase()}`,
      loader:
        typeof tokens === 'function'
          ? (page) => tokens(filterKeyword, page)
          : () => Promise.resolve([]),
      comparator: (x, y) => x.address === y.address,
      initialData: Array.isArray(tokens) ? tokens : [],
      itemsPerPage: 6,
    }),
    [filterKeyword, tokens],
  )
  const {
    hasMoreItems: hasMoreTokens,
    isInitialLoading,
    items: allTokens,
    loadMore: loadMoreTokens,
    setItems: setAllTokens,
  } = usePagination(paginationParams)

  useEffect(() => {
    if (Array.isArray(tokens) && tokens.length > 0) {
      setAllTokens(tokens)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens])

  const filterOutAddresses = useMemo(
    () => [...(blackList || []), selectedToken.address],
    [blackList, selectedToken],
  )

  const availableTokens = useMemo(
    () =>
      allTokens.filter(
        (t) => !filterOutAddresses.includes(t.address) && (filterToken?.(t) ?? true),
      ),
    [allTokens, filterOutAddresses, filterToken],
  )

  const availableRecentTokens = useMemo(() => {
    if (!recentTokens) return []

    const recentTokensMap = availableTokens.filter((t) => recentTokens.includes(t.symbol))

    if (selectedToken.address)
      recentTokensMap.unshift(selectedToken)

    return [...recentTokensMap]
  }, [availableTokens, recentTokens, selectedToken])

  const hasDropdown = useMemo(
    () => allowCustomMintAddress || (availableTokens.length > 0 && !disableDropdown),
    [allowCustomMintAddress, availableTokens, disableDropdown],
  )

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
    setFilterKeyword('')
  }, [])

  const handleTokenSelect = useCallback(
    (value: SwapTokenInfo) => {
      if (setSelectedToken) {
        setSelectedToken(value)
      }

      handleClose()
    },
    [setSelectedToken, handleClose],
  )

  const displayTokens = useMemo(
    () =>
      isInitialLoading
        ? []
        : searchTokenFromList(
            filterKeyword,
            sortTokensByOrder([...availableTokens]),
            allowCustomMintAddress,
          ),
    [allowCustomMintAddress, availableTokens, filterKeyword, isInitialLoading],
  )

  const tokenListContextValue = useMemo(
    () => ({
      filterKeyword,
      setFilterKeyword,
      selectedToken,
      tokens: displayTokens,
      handleClose,
      handleTokenSelect,
      nickName,
      hasMoreTokens,
      loadMoreTokens,
      isInitialLoading,
      isDisplayAddress,
      isShowDialog,
      availableRecentTokens,
    }),
    [
      displayTokens,
      filterKeyword,
      handleClose,
      handleTokenSelect,
      hasMoreTokens,
      isInitialLoading,
      loadMoreTokens,
      nickName,
      selectedToken,
      isDisplayAddress,
      isShowDialog,
      availableRecentTokens,
    ],
  )

  const View = variant === 'secondary' ? ViewSecondary : ViewPrimary

  return (
    <>
      <TokenListContext.Provider value={tokenListContextValue}>
        <TokenList anchorEl={anchorEl} isShowSearch={isShowSearch} />
      </TokenListContext.Provider>

      <View
        boxProps={props.boxProps}
        data-testid={props['data-testid']}
        handleClick={handleClick}
        hasDropdown={hasDropdown}
        token={selectedToken}
        emptySelectionText={emptySelectionText}
        isDisplayIcon={isDisplayIcon}
      />
    </>
  )
}

export default TokenPicker
