import Decimal from 'decimal.js'
import {
  CandlestickData,
  createChart,
  CrosshairMode,
  HistogramData,
  IChartApi,
  ISeriesApi,
  Logical,
  LogicalRange,
  UTCTimestamp,
} from 'lightweight-charts'
import last from 'lodash/last'
import remove from 'lodash/remove'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import useResizeObserver from '@/hooks/use-resize-observer'
import { format, NumericFormatterConfig } from '@/utils/numeric'

import { Candle, CandleSequence, TokenPair } from './candle-utils'
import DateRange from './date-range'

export type ChartSubscription = {
  chart: IChartApi
  candleSeries: ISeriesApi<'Candlestick'>
  volumeSeries: ISeriesApi<'Histogram'>

  /**
   * Checks whether the subscription is still alive or has been unsubscribed.
   */
  isAlive(): boolean
}

export type ChartLoader = (
  tokenPair: TokenPair,
  span: number,
  range: DateRange,
) => Candle[] | Promise<Candle[]>

export type ChartThemeOptions = {
  /**
   * @default '#2E3846'
   */
  axisColor?: string

  /**
   * @default 'transparent'
   */
  backgroundColor?: string

  /**
   * @default '#FF6701'
   */
  candleFallingColor?: string

  /**
   * @default '#55F9C7'
   */
  candleRisingColor?: string

  /**
   * @default 'rgba(255, 103, 1, 0.2)'
   */
  volumeFallingColor?: string

  /**
   * @default 'rgba(85, 249, 199, 0.2)'
   */
  volumeRisingColor?: string

  /**
   * @default '#9CA3AE'
   */
  textColor?: string
}

export type ChartOptions = {
  decimals?: number
  formatterConfig?: NumericFormatterConfig
  load?: ChartLoader
  span: number
  theme?: ChartThemeOptions
  tokenPair: TokenPair
  tz?: number
}

type LiquidityChartType = {
  candleSeq: CandleSequence
  subscribe: (element: HTMLElement, options: ChartOptions) => ChartSubscription | undefined
  unsubscribe: (element: HTMLElement) => void
}

const Context = createContext<LiquidityChartType>({
  candleSeq: new CandleSequence(),
  subscribe: () => undefined,
  unsubscribe: () => null,
})

export const isPromise = (p: unknown): p is Promise<unknown> =>
  p instanceof Object && 'then' in p && typeof p.then === 'function'

export const resizeChart = (chart: IChartApi) =>
  chart.resize(
    chart.chartElement().parentElement?.offsetWidth ?? 0,
    chart.chartElement().parentElement?.offsetHeight ?? 0,
  )

export const toCandlestickData = (candles: Candle[]) =>
  candles.map<CandlestickData>(({ span, startTime, ...ohlc }) => ({
    ...ohlc,
    time: (startTime.getTime() / 1000) as UTCTimestamp,
  }))

export const toHistogramData = (
  candles: Candle[],
  field: Exclude<keyof Candle, 'span' | 'startTime'>,
) =>
  candles.map<HistogramData>(({ span, startTime, ...ohlc }) => ({
    time: (startTime.getTime() / 1000) as UTCTimestamp,
    value: ohlc[field],
  }))

const getCandleReadyData = (candles: Candle[], theme: ChartThemeOptions) =>
  toCandlestickData(candles).map<CandlestickData>((candle) => {
    const isRising = new Decimal(candle.close).gte(candle.open)
    const color = isRising
      ? theme.candleRisingColor || '#55F9C7'
      : theme.candleFallingColor || '#FF6701'

    return {
      ...candle,
      borderColor: color,
      color,
      wickColor: color,
    }
  })

const getVolumeReadyData = (candles: Candle[], theme: ChartThemeOptions) =>
  candles.map<HistogramData>((candle) => {
    const isRising = new Decimal(candle.close).gte(candle.open)
    const color = isRising
      ? theme.volumeRisingColor || 'rgba(85, 249, 199, 0.2)'
      : theme.volumeFallingColor || 'rgba(255, 103, 1, 0.2)'

    return {
      ...toHistogramData([candle], 'volume')[0],
      color: color.toString(),
    }
  })

const LiquidityChartProvider = ({ children }: PropsWithChildren) => {
  const charts = useRef<IChartApi[]>([])
  const candleSeq = useRef<CandleSequence>(new CandleSequence())

  const rangeToLoad = useRef<Record<string, DateRange>>({})

  const unsubscribe = useCallback((element: HTMLElement) => {
    remove(charts.current, (chart) => {
      if (chart.chartElement().parentElement?.isEqualNode(element)) {
        chart.remove()
        Object.assign(chart, { __removed: true })
        return true
      }
    })
  }, [])

  const subscribe = useCallback<LiquidityChartType['subscribe']>(
    (element, { decimals = 2, load, span, theme = {}, tokenPair, tz = 0 }) => {
      if (!CandleSequence.isValidSpan(span)) return

      // If the element is subscribing a token pair, unsubscribe from it first.
      unsubscribe(element)

      // Get/init the date range to load the token pair.
      const loadingRange = (rangeToLoad.current[tokenPair.toString() + span] =
        rangeToLoad.current[tokenPair.toString() + span] ?? DateRange.fromDates(0, new Date()))

      const chart = createChart(element, {
        crosshair: {
          mode: CrosshairMode.Normal,
        },
        grid: {
          horzLines: {
            visible: false,
          },
          vertLines: {
            visible: false,
          },
        },
        layout: {
          background: {
            color: theme.backgroundColor || 'transparent',
          },
          textColor: theme.textColor || '#9CA3AE',
        },
        leftPriceScale: {
          scaleMargins: {
            bottom: 0,
            top: 0.67, // volumes should only appear in the bottom 1/3 of the chart
          },
        },
        rightPriceScale: {
          borderColor: theme.axisColor || '#2E3846',
          textColor: theme.textColor || '#9CA3AE',
        },
        timeScale: {
          borderColor: theme.axisColor || '#2E3846',
          fixLeftEdge: loadingRange.empty,
          fixRightEdge: true,
          lockVisibleTimeRangeOnResize: true,
          secondsVisible: false,
          tickMarkFormatter: (time: number, format: number, locale: string) => {
            const check = (from: number, to = from) => format >= from && format <= to
            const dtf = new Intl.DateTimeFormat(locale, {
              year: check(0) ? 'numeric' : check(1) ? '2-digit' : undefined,
              month: check(1, 2) ? 'short' : undefined,
              day: check(2) ? 'numeric' : undefined,
              hour: check(3, 4) ? '2-digit' : undefined,
              hourCycle: 'h23',
              minute: check(3, 4) ? '2-digit' : undefined,
              second: check(4) ? '2-digit' : undefined,
              timeZone: 'UTC',
            })
            return dtf.format(time * 1000)
          },
          ticksVisible: true,
          timeVisible: true,
          visible: true,
        },
      })

      const isAlive = () => !('__removed' in chart)

      const candleSeries = chart.addCandlestickSeries({
        priceFormat: {
          formatter: (price: number) => format(price, { decimals }),
          minMove: Decimal.pow(10, -decimals).toNumber(),
          type: 'custom',
        },
      })

      const volumeSeries = chart.addHistogramSeries({
        priceScaleId: 'left',
      })

      const destroyEffect = candleSeq.current.addUpdateListener((candles) => {
        if (!isAlive()) {
          destroyEffect()
          return
        }

        CandleSequence.sortCandles(candles)

        const isUpdatingLast =
          candles.length === 1 &&
          // the updating candle >= all current candles
          candles[0].startTime.getTime() >=
            ((last(candleSeries.data())?.time as number) ?? 0) * 1000

        if (isUpdatingLast) {
          candles = CandleSequence.toTz(candles, tz)
          candleSeries.update(getCandleReadyData(candles, theme)[0])
          volumeSeries.update(getVolumeReadyData(candles, theme)[0])
        } else {
          const allCandles = CandleSequence.toTz(candleSeq.current.get(tokenPair, span), tz)
          candleSeries.setData(getCandleReadyData(allCandles, theme))
          volumeSeries.setData(getVolumeReadyData(allCandles, theme))
        }

        chart.timeScale().resetTimeScale()
      })

      const candles = CandleSequence.toTz(candleSeq.current.get(tokenPair, span), tz)
      candleSeries.setData(getCandleReadyData(candles, theme))
      volumeSeries.setData(getVolumeReadyData(candles, theme))

      const onVisibleLogicalRangeChange = async (range: LogicalRange | null) => {
        if (!loadingRange.empty && range !== null && load) {
          const fromTime =
            new Date().getTime() - (candleSeries.data().length - range.from) * span * 1000
          const toTime =
            new Date().getTime() - (candleSeries.data().length - range.to) * span * 1000

          const periodToLoad = DateRange.fromDates(fromTime, toTime).exclude(loadingRange.invert())
          loadingRange.iexclude(periodToLoad)

          const newCandles: Candle[] = []
          for (const { from, to } of periodToLoad.subRanges) {
            if (from && to) {
              newCandles.push(...(await load(tokenPair, span, DateRange.fromDates(from, to))))
            }
          }
          candleSeq.current.add(tokenPair, newCandles)

          // Instead of directly comparing startTime of the earliest candle
          // with fromTime, we allow an offset of span to account for such load
          // methods that return the candle that starts RIGHT AFTER the given
          // fromTime, so data may still exist for the candle RIGHT BEFORE
          // fromTime.
          CandleSequence.sortCandles(newCandles)
          if (newCandles.length && newCandles[0].startTime.getTime() > fromTime + span * 1000) {
            loadingRange.iexclude(DateRange.fromDates(0, newCandles[0].startTime))
          }

          if (loadingRange.empty && isAlive()) {
            chart.applyOptions({ timeScale: { fixLeftEdge: true } })
          }
        }
      }

      chart.timeScale().subscribeVisibleLogicalRangeChange(onVisibleLogicalRangeChange)

      charts.current.push(chart)
      resizeChart(chart)

      onVisibleLogicalRangeChange({ from: 0 as Logical, to: 0 as Logical })

      return {
        chart,
        candleSeries,
        volumeSeries,
        isAlive,
      }
    },
    [unsubscribe],
  )

  return (
    <Context.Provider
      value={{
        candleSeq: candleSeq.current,
        subscribe,
        unsubscribe,
      }}
    >
      {children}
    </Context.Provider>
  )
}

/**
 * Subscribes an element to have a candlestick chart drawn inside of it, or
 * `null` if you only need to work with the chart data.
 */
export const useLiquidityChart = (
  element: HTMLElement | null,
  { decimals, formatterConfig = {}, load, span, theme, tokenPair, tz = 0 }: ChartOptions,
) => {
  CandleSequence.assertValidSpan(span)

  const { candleSeq, subscribe, unsubscribe } = useContext(Context)

  const [candles, setCandles] = useState(CandleSequence.toTz(candleSeq.get(tokenPair, span), tz))
  const [subscription, setSubscription] = useState<ChartSubscription>()

  const formatter = useCallback(
    (price: number) =>
      format(price, {
        decimals,
        preserveSignificance: true,
        trailingZeros: true,
        ...formatterConfig,
      }),
    [decimals, formatterConfig],
  )

  useEffect(() => {
    if (subscription?.isAlive()) {
      subscription.candleSeries.applyOptions({
        priceFormat: { formatter },
      })
    }
  }, [formatter, subscription])

  useEffect(() => {
    const listener = () => {
      setCandles(CandleSequence.toTz(candleSeq.get(tokenPair, span), tz))
    }
    listener()
    candleSeq.addUpdateListener(listener)
    return () => {
      candleSeq.removeUpdateListener(listener)
    }
  }, [candleSeq, span, tokenPair, tz])

  useEffect(() => {
    if (!element) return

    const subscription = subscribe(element, { decimals, load, span, theme, tokenPair, tz })
    setSubscription(subscription)

    return () => {
      unsubscribe(element)
      setSubscription(undefined)
    }
  }, [decimals, element, load, span, subscribe, theme, tokenPair, tz, unsubscribe])

  const updateAll = useCallback(
    (candles: Candle[]) => {
      candleSeq.update(
        tokenPair,
        candles.filter(({ span }) => CandleSequence.isValidSpan(span)),
      )
    },
    [candleSeq, tokenPair],
  )

  const updateLast = useCallback(
    (candle: Candle) => {
      candleSeq.append(
        tokenPair,
        [candle].filter(({ span }) => CandleSequence.isValidSpan(span)),
      )
    },
    [candleSeq, tokenPair],
  )

  useResizeObserver({
    ref: element,
    onResize: () => {
      if (subscription?.chart && subscription.isAlive()) {
        resizeChart(subscription.chart)
      }
    },
  })

  useEffect(() => {
    if (candles.length) {
      element?.setAttribute('data-active-price', formatter(candles[candles.length - 1].close))
    } else {
      element?.setAttribute('data-active-price', 'N/A')
    }
  }, [candles, element, formatter])

  return {
    candles,
    subscription,
    updateAll,
    updateLast,
  }
}

export default LiquidityChartProvider
