import isEqual from 'lodash/isEqual'
import isFunction from 'lodash/isFunction'
import { useCallback, useMemo, useRef, useState } from 'react'

interface UseCachedObject {
  <T extends object, K extends keyof T>(params: {
    initialData?: T
    key: K
    defaultValue: T[K]
    comparator?: (x: T[K], y: T[K]) => boolean
  }): {
    setValue: (value: T[K] | ((prevValue: T[K]) => T[K])) => void
    value: T[K]
  }

  <T extends object, K extends keyof T>(params: {
    initialData?: T
    key: K
    defaultValue?: T[K]
    comparator?: (x: T[K], y: T[K]) => boolean
  }): {
    setValue: (value: T[K] | ((prevValue: T[K] | null) => T[K])) => void
    value: T[K] | null
  }
}

type UseCachedObjectParams<T extends object, K extends keyof T> = {
  initialData?: T
  key: K
  defaultValue?: T[K]
  comparator?: (x: T[K], y: T[K]) => boolean
}

const useCachedObject: UseCachedObject = <T extends object, K extends keyof T>({
  initialData = {} as T,
  key,
  defaultValue,
  comparator = isEqual,
}: UseCachedObjectParams<T, K>) => {
  const object = useRef(initialData)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date())

  const setValue = useCallback(
    (value: T[K] | ((prevValue: T[K] | null) => T[K])) => {
      value = isFunction(value)
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (value as any)(object.current[key] ?? defaultValue ?? null)
        : value

      if (!comparator(object.current[key], value as T[K])) {
        object.current[key] = value as T[K]
        setLastUpdatedAt(new Date())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [comparator, key],
  )
  const value = useMemo(
    () => object.current[key] ?? defaultValue ?? null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key, lastUpdatedAt],
  )

  return { setValue, value }
}

export default useCachedObject
