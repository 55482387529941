import { CHAIN_CONSTANTS, isChainSolana } from '@/constants/index'
import { APITokenInfo } from '@/utils/apis/helper'

export const mapApiTokenInfo = (token: APITokenInfo) => {
  // if the chain is SOLANA
  if (isChainSolana()) {
    // then we need to change the decimals and logo_url of USDT
    if (token.token_symbol === 'reUSD') {
      return {
        ...token,
        coin_address: CHAIN_CONSTANTS.usdtMintAddress,
        decimals: 6,
        logo_url: 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg',
      }
    }
    // and correct the token_name of Native token to display on UI
    if (token.token_symbol === 'RENEC') {
      return {
        ...token,
        token_name: 'SOL',
      }
    }
  }
  return token
}
